import React from 'react';
import './KnifeExample1.css';
import {Button, Cascader, List, Modal, Popover, Select} from "antd";
import {Row, Col} from 'antd';
import example1 from "./img/example1.png";
import example2 from "./img/example2.png";
import example3 from "./img/example3.png";
import example4 from "./img/example4.png";
import example5 from "./img/example5.png";
import example6 from "./img/example6.png";
import support_img from "./img/support_img.png";
import alert from "./img/alert.png";
import alert21 from "./img/alert21.png";
import alert22 from "./img/alert22.png";
import alert23 from "./img/alert23.png";
import alert32 from "./img/alert31.png";
import alert31 from "./img/alert32.png";
import alert33 from "./img/alert33.png";
import section1 from "./img/section1.png";
import section12 from "./img/section12.png";
import section13 from "./img/section13.png";
import biochemistry_btn1 from "./img/biochemistry_btn1.png";
import biochemistry_btn2 from "./img/biochemistry_btn2.png";
import biochemistry_btn3 from "./img/biochemistry_btn3.png";
import ct_btn1 from "./img/ct_btn1.png";
import ct_btn2 from "./img/ct_btn2.png";
import ct_btn3 from "./img/ct_btn3.png";
import vr2 from "./img/vr2.jpg";
import btn_ok from "./img/btn_ok.png";

const {Option} = Select;

let q1map = [
    {
        question: "请问您叫什么名字？",
        answer: "张某。"
    },
    {
        question: "请问您多大年龄？",
        answer: "61岁。"
    },
    {
        question: "请问您结婚了吗？",
        answer: "已婚。"
    },
    {
        question: "请问您是做什么工作的？",
        answer: "以前是教师，现在已经退休了。"
    },
    {
        question: "请问您身高体重多少？您这段时间体重有变化吗？",
        answer: "身高：170cm，体重：60kg，体重一直没有明显的变化。"
    }];
let q2map = [
    {
        question: "请问您哪里不舒服？",
        answer: "我膝盖痛。"
    },
    {
        question: "哪一边膝关节痛？",
        answer: "两边都痛，主要是左边。"
    },
    {
        question: "这种情况有多长时间了？",
        answer: "膝关节疼痛有七年了，这3个月感觉左边加重了。"
    }];
let q3map = [
    {
        question: "您是由于什么原因诱发的？受过外伤没有？",
        answer: "不知道什么原因，也没有受过外伤。"
    },
    {
        question: "疼痛的部位在哪里？膝关节内侧还是外侧？",
        answer: "膝盖内侧和前面。"
    },
    {
        question: "疼痛的感觉是什么样的？",
        answer: "原来是胀痛，这一次感觉冷痛。"
    },
    {
        question: "您感觉疼痛什么时候轻一些，什么时候重一些？白天和夜晚有差异吗？天气变化会不会有影响？",
        answer: "走的时间长了就痛的厉害，感觉白天和晚上痛的差不多吧。每次天气转凉都会更痛。"
    },
    {
        question: "上下楼梯后会加重吗？",
        answer: "会，我不能上楼梯，只能乘电梯。"
    },
    {
        question: "休息后会缓解吗？",
        answer: "休息之后会好一些。"
    },
    {
        question: "除了膝关节，您还有手脚其他关节疼痛吗？",
        answer: "没有。"
    },
    {
        question: "您还有没有其他不舒服？",
        answer: "没有。"
    },
    {
        question: "您之前有在其他医院治疗过吗？",
        answer: "我1年前疼痛加重在我们社区医院治疗，诊断是膝关节骨质增生，医师给我做了膝关节穿刺，抽出了许多关节积液。"
    },
    {
        question: "您在社区医院还采取过其他什么治疗措施吗？",
        answer: "口服了尼美舒利胶囊、氨基葡萄糖、中药，还做过针灸和理疗。"
    },
    {
        question: "您治疗之后好些了没？",
        answer: "缓解了一段时间，但疼痛还是反复发作。"
    },
    {
        question: "您这3 个月病情加重了，有没有采取什么措施呢？",
        answer: "还是继续服用了尼美舒利胶囊，但是还是很疼。"
    },
    {
        question: "您这期间有做过什么检查吗？",
        answer: "我在门诊做了 X 片，报告单上显示“双侧膝关节骨质增生”。"
    },
    {
        question: "您饮食还好吗？大小便如何？",
        answer: "我饮食和大小便都比较正常。"
    },
    {
        question: "您晚上睡眠怎么样？",
        answer: "每天可以睡5个小时。"
    }];

let q4map = [
    {
        question: "您有高血压、糖尿病、心脏病吗？有没有脑血管疾病？",
        answer: "都没有，我一直身体挺好的。"
    },
    {
        question: "该接种的疫苗都接种过吗？",
        answer: "是的。"
    },
    {
        question: "以前得过传染病吗？比如结核病、肝炎？",
        answer: "没有。"
    },
    {
        question: "以前受过外伤或做过手术吗？",
        answer: "没有受过伤，但是做过1次手术。24 年前因急性化脓性阑尾炎做了阑尾切除，手术还挺顺利的。"
    },
    {
        question: "您凝血情况怎么样？有没有在吃抗凝药？",
        answer: "正常，没有吃抗凝药。"
    },
    {
        question: "对药物、食物有无过敏情况？",
        answer: "没有。"
    },
    {
        question: "以前输过血吗？",
        answer: "没有。"
    }];
let q5map = [
    {
        question: "您一直住在武汉市武昌区这边吗？有没有去过疫区、接触疫水、疫源呀？",
        answer: "是的，一直生活在这边。没有去过这些地方。"
    },
    {
        question: "您抽烟喝酒吗？",
        answer: "我不抽烟喝酒。"
    }];

let q6map = [
    {
        question: "患者神清，面色红润，形体偏瘦。",
        answer: ""
    },
    {
        question: "主症：四肢不温，冬日畏寒，关节遇冷加剧，得温痛减。",
        answer: ""
    },
    {
        question: "次症：关节重着。",
        answer: ""
    },
    {
        question: "舌脉：舌质淡，苔白腻；脉弦沉。",
        answer: ""
    }];

const options = [
    {
        value: 'physique',
        label: '体格检查',
        children: [
            {
                value: 'general',
                label: '一般检查',
            },
            {
                value: 'head',
                label: '头部',
            },
            {
                value: 'neck',
                label: '颈部',
            },
            {
                value: 'chest',
                label: '胸部',
            },
            {
                value: 'abdomen',
                label: '腹部',
            },
            {
                value: 'spine',
                label: '脊柱',
            },
            {
                value: 'nervous',
                label: '神经系统',
            },
        ],
    },
    {
        value: 'special',
        label: '专项检查',
        children: [
            {
                value: 'vision',
                label: '视诊',
            },
            {
                value: 'touch',
                label: '触诊',
            },
            {
                value: 'listen',
                label: '听诊',
            },
            {
                value: 'special',
                label: '特殊检查',
            },
        ],
    },
    {
        value: 'sport',
        label: '运动与功能检查'
    }
];
let m = new Map([['physique',
    new Map(
        [["general", "体温：36.5℃ ， 脉搏：80次/分钟，" +
        "呼吸19次/分 ，血压：130/85mmHg\r\n" +
        "      发育正常，无急慢性面容，表情痛苦，自主体位，步态正常、神志清楚，查体合作。皮肤黏膜色泽正常，无皮疹及出血点，皮肤干燥，弹性良好，无皮下结节，无蜘蛛痣、肝掌，毛发分布正常。"],
            ["head", "头颅无畸形，无压痛及包块，头发分布均匀。"],
            ["neck", "颈软对称，无抵抗。未见颈静脉怒张及颈动脉异常搏动。肝颈静脉回流征（-）。双侧甲状腺未触及肿大，气管居中。未闻及颈部血管杂音。"],
            ["chest", "双侧胸廓对称，无畸形。双肺呼吸音淸晰，未闻及异常呼吸音及干湿啰音，语音共振正常，无胸膜摩擦音。心率80次/分，律齐。心音有力，未闻及心音分裂及额外心音，各瓣膜听诊区未闻及病理性杂音，未闻及心包摩檫音。"],
            ["abdomen", "全腹柔软，无压痛、反跳痛，无液波震颤和振水音，未触及包块。肝脾肋下未触及。胆囊未触及，无压痛，墨菲征（-）。麦氏点无压痛。双肾区无叩痛。肝浊音界存在，移动性浊音（-）。"],
            ["spine", "活动度正常，无畸形，无压痛及叩击痛。"],
            ["nervous", "四肢肌张力正常，四肢肌力v级。双侧膝腱反射正常。无病理反射。"]]
    )],
    ['special',
        new Map(
            [["vision", "膝关节粗大，关节肿胀，局部皮肤未见明显发红，股四头肌萎缩，局部无色素沉着及瘀斑瘀点，双下肢未见明显水肿及静脉曲张。双侧大腿、小腿周径无明显异常。"],
                ["touch", "髌骨下缘疼痛，无放射痛，髌骨深面及膝关节胫侧副韧带、髌内侧支持带、髌韧带、髌外侧支持带、腓侧副韧带及髂胫束、四头肌腱及髌上囊、鹅足压痛；局部皮温无明显升高，双下肢未触及明显肿块、皮下结节，髌骨对位良好，滑膜未及明显肿胀，双下肢足背动脉搏动正常，感觉正常。"],
                ["listen", "可闻及骨磨擦音，无关节弹响，无血管杂音。"],
                ["special", "磨髌试验（+），膝关节研磨试验（+）。浮髌试验（－），半月板挤压试验（－），前后抽屉试验、侧方应力试验（－），直腿抬高试验（－）。"],
            ])],
    ['sport', "膝关节屈伸活动受限，步伐距离缩小，伸直活动时疼痛加重，下蹲困难。"]]);

class KnifeUsage extends React.Component {
    constructor(props) {
        super(props);
        this.audio = true;
        this.state = {
            index: 0,
            loading: false,
            visible: true,
            alert: alert,
            questionData: q1map,
            physiqueData: m.get("physique").get("general"),
            answer: "",
            audioUrl: "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&per=2&text=%E4%BD%A0%E5%A5%BD",
            select1: "1",
            select2: "1",
            select3: "1",
            select1_color: "black",
            select2_color: "black",
            select3_color: "black",
        };
    }

    componentDidMount() {
        if (this.audio) {
            this.audio.autoplay = true;
            this.audio.addEventListener('ended', () => {
                this.setState({
                    audioUrl: "https://tsn.baidu.com/text2audio?lan=zh&cuid=123&ctp=1&tok=24.726911a2efc0b1ad2170b497a472ef6f.2592000.1569405591.282335-16613661&per=1&tex=" + this.state.answer,
                });
            }, false)
        }
    }

    changeContent = index => {
        this.setState({
            index: index
        })
    };

    handleOk = () => {
        this.setState({
            alert: {},
            visible: false
        });
    };

    handleSelectOk() {
        console.log(this.state.select1 + " " + this.state.select2 + " " + this.state.select3);
        this.setState({
            select1_color: this.state.select1 === "2" ? "black" : "red",
            select2_color: this.state.select2 === "3" ? "black" : "red",
            select3_color: this.state.select3 === "1" ? "black" : "red",
        });
        if (this.state.select1 === "2" && this.state.select2 === "3" && this.state.select3 === "1") {
            window.location.href="/knife3d"
        }
    };


    onClickItem = item => {
        this.setState({
            answer: item.answer,
            audioUrl: "https://tsn.baidu.com/text2audio?lan=zh&cuid=123&ctp=1&tok=24.20f9c8f8759f6491e1e4dd9a3b4083f1.2592000.1563934481.282335-16613661&tex=" + item.question,

        });
    };

    onChange2(value) {
        let data = m.get(value[0]);
        if (value.length > 1) {
            data = data.get(value[1])
        }
        this.setState({
            physiqueData: data
        });
    }

    onChange(value) {
        switch (value) {
            case "base":
                this.setState({
                    questionData: q1map
                });
                break;
            case "main":
                this.setState({
                    questionData: q2map
                });
                break;
            case "now":
                this.setState({
                    questionData: q3map
                });
                break;
            case "before":
                this.setState({
                    questionData: q4map
                });
                break;
            case "history":
                this.setState({
                    questionData: q5map
                });
                break;
            case "chinese":
                this.setState({
                    questionData: q6map
                });
                break;
            default:
                this.setState({
                    questionData: q1map
                });
                break
        }
    }

    handleChange1(value) {
        this.setState({
            select1: value
        });
    }

    handleChange2(value) {
        this.setState({
            select2: value
        });
    }

    handleChange3(value) {
        this.setState({
            select3: value
        });
    }

    onBiochemistry = index => {
        let a = alert21;
        switch (index) {
            case 2:
                a = alert22;
                break;
            case 3:
                a = alert23;
                break;
            default:
                break;
        }
        this.setState({
            alert: a,
        }, () => {
            this.setState({
                visible: true
            })
        });
    };

    onCT=index=> {
        let a = alert31;
        switch (index) {
            case 2:
                a = alert32;
                break;
            case 3:
                a = alert33;
                break;
            default:
                break;
        }
        this.setState({
            alert: a,
        }, () => {
            this.setState({
                visible: true
            })
        });
    }

    render() {
        const {visible} = this.state;
        let section1Arr = [<div className="section1">
            <Select defaultValue="base"
                    style={{marginTop: "20px", width: "250px", marginLeft: "100px",fontSize:"20px"}}
                    onChange={this.onChange.bind(this)}>
                <Option value="base">基本信息</Option>
                <Option value="main">主诉问题</Option>
                <Option value="now">现病史问题</Option>
                <Option value="before">既往史问题</Option>
                <Option value="history">个人家族史问题</Option>
                <Option value="chinese">中医四诊</Option>
            </Select>
            <List
                style={{marginLeft: "40px", width: "400px"}}
                itemLayout="horizontal"
                dataSource={this.state.questionData}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={<a onClick={() => this.onClickItem(item)}>{item.question}</a>}
                        />
                    </List.Item>
                )}
            />
        </div>, <div className="section1" style={{backgroundImage: `url(${section1})`}}>
            <Cascader
                size="large"
                style={{marginTop: "50px", width: "300px", marginLeft: "100px", fontSize: "20px"}}
                defaultValue={['physique', 'general']}
                options={options}
                onChange={this.onChange2.bind(this)}
            />
            <div className="text1">{this.state.physiqueData}</div>
        </div>, <div className="section1" style={{backgroundImage: `url(${section12})`}}>
            <Row gutter={24} style={{paddingTop: "80px", marginLeft: "50px", fontStyle: "bold"}}>
                <Col span={6}>
                    <img src={support_img} alt="support_img"/>
                </Col>
                <Col span={12}>生化检查</Col>

            </Row>
            <Row gutter={6} style={{paddingTop: "20px", marginLeft: "50px", fontStyle: "bold"}}>
                <Col span={6}>
                    <Button type="link"
                            onClick={() => this.onBiochemistry(1)}
                            ghost={true}><img
                        height="80" width="160"
                        src={biochemistry_btn1}
                        alt={"login"}/></Button>
                </Col>
            </Row>
            <Row gutter={6} style={{paddingTop: "20px", marginLeft: "50px", fontStyle: "bold"}}>
                <Col span={6}>
                    <Button type="link"
                            onClick={() => this.onBiochemistry(2)}
                            ghost={true}><img
                        height="80" width="160"
                        src={biochemistry_btn2}
                        alt={"login"}/></Button>
                </Col>
            </Row>
            <Row gutter={6} style={{marginTop: "20px", marginLeft: "50px"}}>
                <Col span={6}>
                    <Button type="link"
                            onClick={() => this.onBiochemistry(3)}
                            ghost={true}><img
                        height="80" width="160"
                        src={biochemistry_btn3}
                        alt={"login"}/></Button>
                </Col>
            </Row>
            <Row gutter={12} style={{paddingTop: "100px", marginLeft: "50px", fontStyle: "bold"}}>
                <Col span={6}>
                    <img src={support_img} alt="support_img"/>
                </Col>
                <Col span={12}>影像诊断</Col>
            </Row>
            <Row gutter={6} style={{marginTop: "20px", marginLeft: "50px"}}>
                <Col span={6}>
                    <Button type="link"
                            onClick={() => this.onCT(1)}
                            ghost={true}><img
                        height="80" width="160"
                        src={ct_btn1}
                        alt={"login"}/></Button>
                </Col>
            </Row>
            <Row gutter={6} style={{marginTop: "20px", marginLeft: "50px"}}>
                <Col span={6}>
                    <Button type="link"
                            onClick={() => this.onCT(2)}
                            ghost={true}><img
                        height="80" width="160"
                        src={ct_btn2}
                        alt={"login"}/></Button>
                </Col>
            </Row>
            <Row gutter={6} style={{marginTop: "20px", marginLeft: "50px"}}>
                <Col span={6}>
                    <Button type="link"
                            onClick={() => this.onCT(3)}
                            ghost={true}><img
                        height="80" width="160"
                        src={ct_btn3}
                        alt={"login"}/></Button>
                </Col>
            </Row>
        </div>, <div className="section1" style={{backgroundImage: `url(${section13})`}}>
            <Row gutter={12} style={{paddingTop: "100px", marginLeft: "50px", fontStyle: "bold",fontSize:"30px"}}>
                <Col span={6}>
                    <img src={support_img} alt="support_img"/>
                </Col>
                <Col span={12}>中医诊断</Col>
            </Row>
            <Row gutter={6} style={{marginTop: "50px", marginLeft: "50px"}}>
                <Col span={8}>
                    中医病名
                </Col>
                <Col span={6}>
                    <Select defaultValue="1" style={{width: 120, color: `${this.state.select1_color}`}}
                            onChange={this.handleChange1.bind(this)}>
                        <Option value="1">尪痹</Option>
                        <Option value="2">膝痹</Option>
                        <Option value="3">皮痹</Option>
                        <Option value="4">骨痹</Option>
                    </Select>
                </Col>
            </Row>
            <Row gutter={6} style={{marginTop: "50px", marginLeft: "50px"}}>
                <Col span={8}>
                    中医证型
                </Col>
                <Col span={6}>
                    <Select defaultValue="1" style={{width: 150, color: `${this.state.select2_color}`}}
                            onChange={this.handleChange2.bind(this)}>
                        <Option value="1">肝肾亏虚证</Option>
                        <Option value="2">气滞血瘀证</Option>
                        <Option value="3">寒湿痹阻证</Option>
                        <Option value="4">气血虚弱证</Option>
                    </Select>
                </Col>
            </Row>
            <Row gutter={12} style={{paddingTop: "100px", marginLeft: "50px", fontStyle: "bold",fontSize:"30px"}}>
                <Col span={6}>
                    <img src={support_img} alt="support_img"/>
                </Col>
                <Col span={12}>西医诊断</Col>
            </Row>
            <Row gutter={6} style={{marginTop: "50px", marginLeft: "50px"}}>
                <Col span={8}>
                    西医病名
                </Col>
                <Col span={6}>
                    <Select defaultValue="1" style={{width: 220, color: `${this.state.select3_color}`}}
                            onChange={this.handleChange3.bind(this)}>
                        <Option value="1">膝关节骨性关节炎</Option>
                        <Option value="2">类风湿性关节炎</Option>
                        <Option value="3">膝关节侧副韧带损伤</Option>
                        <Option value="4">膝关节半月板损伤</Option>
                    </Select>
                </Col>
            </Row>
            <Button type="link" style={{marginTop: '130px', marginLeft: "200px"}} onClick={() => this.handleSelectOk()}
                    ghost={true}><img
                height="80" width="160"
                src={btn_ok}
                alt={"login"}/></Button>
        </div>];
        let section2Arr = [<div className="KnifeExample1-section2">
            <div style={{paddingTop: "200px", marginLeft: "500px", whiteSpace: 'nowrap'}}>
                <Popover
                    content={this.state.answer}
                    placement="top"
                    visible={this.state.answer !== ""}
                >
                    <a
                    >&nbsp;</a>
                </Popover>
            </div>
        </div>, <div className="KnifeExample1-section2" style={{backgroundImage: `url(${vr2})`}}>

        </div>,
            <div className="KnifeExample1-section2" style={{backgroundImage: `url(${vr2})`}}>

            </div>,
            <div className="KnifeExample1-section2" style={{backgroundImage: `url(${vr2})`}}>

            </div>];
        return (
            <div className="KnifeExample1">
                <Button style={{marginLeft: "90%", marginTop: "50px"}} type="default" href="/main">返回</Button>
                <audio ref={e => this.audio = e} src={this.state.audioUrl} type="audio/mpeg"/>
                }
                <Modal
                    visible={visible}
                    onOk={this.handleOk}
                    width="0px"
                    style={{paddingRight: "1345px"}}
                    centered={true}
                    closable={false}
                    mask={false}
                    footer={null}
                >
                    <img src={this.state.alert} alt="mo" onClick={this.handleOk}/>
                </Modal>
                <Row>
                    <Col span={6}>
                        <header className="Example1-header">
                            <Button type="link" onClick={() => this.changeContent(0)} ghost={true}><img height="150"
                                                                                                        width="200"
                                                                                                        src={example1}
                                                                                                        alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '110px'}} onClick={() => this.changeContent(1)}
                                    ghost={true}><img
                                height="150" width="200"
                                src={example2}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '110px'}} onClick={() => this.changeContent(2)}
                                    ghost={true}><img
                                height="150" width="200"
                                src={example3}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '110px'}} onClick={() => this.changeContent(3)}
                                    ghost={true}><img
                                height="150" width="200"
                                src={example4}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '110px'}} href="/knife3d"
                                    ghost={true}><img
                                height="150" width="200"
                                src={example5}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '110px'}} href="/KnifeAfter"
                                    ghost={true}><img
                                height="150" width="200"
                                src={example6}
                                alt={"login"}/></Button>
                        </header>
                    </Col>
                    <Col span={4} pull={3}>
                        {section1Arr[this.state.index]}
                    </Col>
                    <Col span={7}>
                        {section2Arr[this.state.index]}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default KnifeUsage;
