import * as OLD_THREE from 'three';
import {BufferGeometryUtils as NewBufferGeometryUtils} from "three/examples/jsm/utils/BufferGeometryUtils.js"
import {GLTFLoader as NewGLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js"
import {MTLLoader as NewMTLLoader} from "three/examples/jsm/loaders/MTLLoader.js"
import {OBJLoader as NewOBJLoader} from "three/examples/jsm/loaders/OBJLoader.js"
import {ColladaLoader as NewColladaLoader} from "three/examples/jsm/loaders/ColladaLoader.js"

var THREE = OLD_THREE;

THREE.BufferGeometryUtils = NewBufferGeometryUtils;
THREE.GLTFLoader = NewGLTFLoader;
THREE.MTLLoader = NewMTLLoader;
THREE.OBJLoader = NewOBJLoader;
THREE.ColladaLoader = NewColladaLoader;
/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 */

// This set of controls performs orbiting, dollying (zooming), and panning.
// Unlike TrackballControls, it maintains the "up" direction object.up (+Y by default).
//
//    Orbit - left mouse / touch: one finger move
//    Zoom - middle mouse, or mousewheel / touch: two finger spread or squish
//    Pan - right mouse, or arrow keys / touch: three finger swipe

THREE.OrbitControls = function (object, domElement) {

    this.object = object;

    this.domElement = (domElement !== undefined) ? domElement : document;

    // Set to false to disable this control
    this.enabled = true;

    // "target" sets the location of focus, where the object orbits around
    this.target = new THREE.Vector3();

    // How far you can dolly in and out ( PerspectiveCamera only )
    this.minDistance = 0;
    this.maxDistance = Infinity;

    // How far you can zoom in and out ( OrthographicCamera only )
    this.minZoom = 0;
    this.maxZoom = Infinity;

    // How far you can orbit vertically, upper and lower limits.
    // Range is 0 to Math.PI radians.
    this.minPolarAngle = 0; // radians
    this.maxPolarAngle = Math.PI; // radians

    // How far you can orbit horizontally, upper and lower limits.
    // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
    this.minAzimuthAngle = -Infinity; // radians
    this.maxAzimuthAngle = Infinity; // radians

    // Set to true to enable damping (inertia)
    // If damping is enabled, you must call controls.update() in your animation loop
    this.enableDamping = false;
    this.dampingFactor = 0.25;

    // This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
    // Set to false to disable zooming
    this.enableZoom = true;
    this.zoomSpeed = 1.0;

    // Set to false to disable rotating
    this.enableRotate = true;
    this.rotateSpeed = 1.0;

    // Set to false to disable panning
    this.enablePan = true;
    this.keyPanSpeed = 7.0;	// pixels moved per arrow key push

    // Set to true to automatically rotate around the target
    // If auto-rotate is enabled, you must call controls.update() in your animation loop
    this.autoRotate = false;
    this.autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60

    // Set to false to disable use of the keys
    this.enableKeys = true;

    // The four arrow keys
    this.keys = {LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40};

    // Mouse buttons
    this.mouseButtons = {ORBIT: THREE.MOUSE.LEFT, ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT};

    // for reset
    this.target0 = this.target.clone();
    this.position0 = this.object.position.clone();
    this.zoom0 = this.object.zoom;

    //
    // public methods
    //

    //debugger

    this.getPolarAngle = function () {

        return spherical.phi;

    };

    this.getAzimuthalAngle = function () {

        return spherical.theta;

    };

    this.reset = function () {

        scope.target.copy(scope.target0);
        scope.object.position.copy(scope.position0);
        scope.object.zoom = scope.zoom0;

        scope.object.updateProjectionMatrix();
        scope.dispatchEvent(changeEvent);

        scope.update();

        state = STATE.NONE;

    };

    // this method is exposed, but perhaps it would be better if we can make it private...
    this.update = function () {

        var offset = new THREE.Vector3();

        // so camera.up is the orbit axis
        var quat = new THREE.Quaternion().setFromUnitVectors(object.up, new THREE.Vector3(0, 1, 0));
        var quatInverse = quat.clone().inverse();

        var lastPosition = new THREE.Vector3();
        var lastQuaternion = new THREE.Quaternion();

        return function update() {

            var position = scope.object.position;

            offset.copy(position).sub(scope.target);

            // rotate offset to "y-axis-is-up" space
            offset.applyQuaternion(quat);

            // angle from z-axis around y-axis
            spherical.setFromVector3(offset);

            if (scope.autoRotate && state === STATE.NONE) {

                rotateLeft(getAutoRotationAngle());

            }

            spherical.theta += sphericalDelta.theta;
            spherical.phi += sphericalDelta.phi;

            // restrict theta to be between desired limits
            spherical.theta = Math.max(scope.minAzimuthAngle, Math.min(scope.maxAzimuthAngle, spherical.theta));

            // restrict phi to be between desired limits
            spherical.phi = Math.max(scope.minPolarAngle, Math.min(scope.maxPolarAngle, spherical.phi));

            spherical.makeSafe();


            spherical.radius *= scale;

            // restrict radius to be between desired limits
            spherical.radius = Math.max(scope.minDistance, Math.min(scope.maxDistance, spherical.radius));

            // move target to panned location
            scope.target.add(panOffset);

            offset.setFromSpherical(spherical);

            // rotate offset back to "camera-up-vector-is-up" space
            offset.applyQuaternion(quatInverse);

            position.copy(scope.target).add(offset);

            scope.object.lookAt(scope.target);

            if (scope.enableDamping === true) {

                sphericalDelta.theta *= (1 - scope.dampingFactor);
                sphericalDelta.phi *= (1 - scope.dampingFactor);

            } else {

                sphericalDelta.set(0, 0, 0);

            }

            scale = 1;
            panOffset.set(0, 0, 0);

            // update condition is:
            // min(camera displacement, camera rotation in radians)^2 > EPS
            // using small-angle approximation cos(x/2) = 1 - x^2 / 8
            // console.log('1234')
            // console.log(
            //  lastPosition,scope.object.position,
            // EPS);

            if (zoomChanged ||
                lastPosition.distanceToSquared(scope.object.position) > EPS ||
                8 * (1 - lastQuaternion.dot(scope.object.quaternion)) > EPS) {

                //debugger;

                scope.dispatchEvent(changeEvent);

                lastPosition.copy(scope.object.position);
                lastQuaternion.copy(scope.object.quaternion);
                zoomChanged = false;

                return true;

            }

            return false;

        };

    }();

    this.dispose = function () {

        scope.domElement.removeEventListener('contextmenu', onContextMenu, false);
        scope.domElement.removeEventListener('mousedown', onMouseDown, false);
        scope.domElement.removeEventListener('wheel', onMouseWheel, false);

        scope.domElement.removeEventListener('touchstart', onTouchStart, false);
        scope.domElement.removeEventListener('touchend', onTouchEnd, false);
        scope.domElement.removeEventListener('touchmove', onTouchMove, false);

        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseup', onMouseUp, false);

        window.removeEventListener('keydown', onKeyDown, false);

        //scope.dispatchEvent( { type: 'dispose' } ); // should this be added here?

    };

    //
    // internals
    //

    var scope = this;

    var changeEvent = {type: 'change'};
    var startEvent = {type: 'start'};
    var endEvent = {type: 'end'};

    var STATE = {NONE: -1, ROTATE: 0, DOLLY: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_DOLLY: 4, TOUCH_PAN: 5};

    var state = STATE.NONE;

    var EPS = 0.000001;

    // current position in spherical coordinates
    var spherical = new THREE.Spherical();
    var sphericalDelta = new THREE.Spherical();

    var scale = 1;
    var panOffset = new THREE.Vector3();
    var zoomChanged = false;

    var rotateStart = new THREE.Vector2();
    var rotateEnd = new THREE.Vector2();
    var rotateDelta = new THREE.Vector2();

    var panStart = new THREE.Vector2();
    var panEnd = new THREE.Vector2();
    var panDelta = new THREE.Vector2();

    var dollyStart = new THREE.Vector2();
    var dollyEnd = new THREE.Vector2();
    var dollyDelta = new THREE.Vector2();

    function getAutoRotationAngle() {

        return 2 * Math.PI / 60 / 60 * scope.autoRotateSpeed;

    }

    function getZoomScale() {

        return Math.pow(0.95, scope.zoomSpeed);

    }

    function rotateLeft(angle) {

        sphericalDelta.theta -= angle;

    }

    function rotateUp(angle) {

        sphericalDelta.phi -= angle;

    }

    var panLeft = function () {

        var v = new THREE.Vector3();

        return function panLeft(distance, objectMatrix) {

            v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
            v.multiplyScalar(-distance);

            panOffset.add(v);

        };

    }();

    var panUp = function () {

        var v = new THREE.Vector3();

        return function panUp(distance, objectMatrix) {

            v.setFromMatrixColumn(objectMatrix, 1); // get Y column of objectMatrix
            v.multiplyScalar(distance);

            panOffset.add(v);

        };

    }();

    // deltaX and deltaY are in pixels; right and down are positive
    var pan = function () {

        var offset = new THREE.Vector3();

        return function pan(deltaX, deltaY) {

            var element = scope.domElement === document ? scope.domElement.body : scope.domElement;

            if (scope.object instanceof THREE.PerspectiveCamera) {

                // perspective
                var position = scope.object.position;
                offset.copy(position).sub(scope.target);
                var targetDistance = offset.length();

                // half of the fov is center to top of screen
                targetDistance *= Math.tan((scope.object.fov / 2) * Math.PI / 180.0);

                // we actually don't use screenWidth, since perspective camera is fixed to screen height
                panLeft(2 * deltaX * targetDistance / element.clientHeight, scope.object.matrix);
                panUp(2 * deltaY * targetDistance / element.clientHeight, scope.object.matrix);

            } else if (scope.object instanceof THREE.OrthographicCamera) {

                // orthographic
                panLeft(deltaX * (scope.object.right - scope.object.left) / scope.object.zoom / element.clientWidth, scope.object.matrix);
                panUp(deltaY * (scope.object.top - scope.object.bottom) / scope.object.zoom / element.clientHeight, scope.object.matrix);

            } else {

                // camera neither orthographic nor perspective
                console.warn('WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.');
                scope.enablePan = false;

            }

        };

    }();

    function dollyIn(dollyScale) {

        if (scope.object instanceof THREE.PerspectiveCamera) {

            scale /= dollyScale;

        } else if (scope.object instanceof THREE.OrthographicCamera) {

            scope.object.zoom = Math.max(scope.minZoom, Math.min(scope.maxZoom, scope.object.zoom * dollyScale));
            scope.object.updateProjectionMatrix();
            zoomChanged = true;

        } else {

            console.warn('WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.');
            scope.enableZoom = false;

        }

    }

    function dollyOut(dollyScale) {

        if (scope.object instanceof THREE.PerspectiveCamera) {

            scale *= dollyScale;

        } else if (scope.object instanceof THREE.OrthographicCamera) {

            scope.object.zoom = Math.max(scope.minZoom, Math.min(scope.maxZoom, scope.object.zoom / dollyScale));
            scope.object.updateProjectionMatrix();
            zoomChanged = true;

        } else {

            console.warn('WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.');
            scope.enableZoom = false;

        }

    }

    //
    // event callbacks - update the object state
    //

    function handleMouseDownRotate(event) {

        //console.log( 'handleMouseDownRotate' );

        rotateStart.set(event.clientX, event.clientY);

    }

    function handleMouseDownDolly(event) {

        //console.log( 'handleMouseDownDolly' );

        dollyStart.set(event.clientX, event.clientY);

    }

    function handleMouseDownPan(event) {

        //console.log( 'handleMouseDownPan' );

        panStart.set(event.clientX, event.clientY);

    }

    function handleMouseMoveRotate(event) {

        //console.log( 'handleMouseMoveRotate' );

        rotateEnd.set(event.clientX, event.clientY);
        rotateDelta.subVectors(rotateEnd, rotateStart);

        var element = scope.domElement === document ? scope.domElement.body : scope.domElement;

        // rotating across whole screen goes 360 degrees around
        rotateLeft(2 * Math.PI * rotateDelta.x / element.clientWidth * scope.rotateSpeed);

        // rotating up and down along whole screen attempts to go 360, but limited to 180
        rotateUp(2 * Math.PI * rotateDelta.y / element.clientHeight * scope.rotateSpeed);

        rotateStart.copy(rotateEnd);

        scope.update();

    }

    function handleMouseMoveDolly(event) {

        //console.log( 'handleMouseMoveDolly' );

        dollyEnd.set(event.clientX, event.clientY);

        dollyDelta.subVectors(dollyEnd, dollyStart);

        if (dollyDelta.y > 0) {

            dollyIn(getZoomScale());

        } else if (dollyDelta.y < 0) {

            dollyOut(getZoomScale());

        }

        dollyStart.copy(dollyEnd);

        scope.update();

    }

    function handleMouseMovePan(event) {

        //console.log( 'handleMouseMovePan' );

        panEnd.set(event.clientX, event.clientY);

        panDelta.subVectors(panEnd, panStart);

        pan(panDelta.x, panDelta.y);

        panStart.copy(panEnd);

        scope.update();

    }

    function handleMouseUp(event) {

        // console.log( 'handleMouseUp' );

    }

    function handleMouseWheel(event) {

        // console.log( 'handleMouseWheel' );

        if (event.deltaY < 0) {

            dollyOut(getZoomScale());

        } else if (event.deltaY > 0) {

            dollyIn(getZoomScale());

        }

        scope.update();

    }

    function handleKeyDown(event) {

        //console.log( 'handleKeyDown' );

        switch (event.keyCode) {

            case scope.keys.UP:
                pan(0, scope.keyPanSpeed);
                scope.update();
                break;

            case scope.keys.BOTTOM:
                pan(0, -scope.keyPanSpeed);
                scope.update();
                break;

            case scope.keys.LEFT:
                pan(scope.keyPanSpeed, 0);
                scope.update();
                break;

            case scope.keys.RIGHT:
                pan(-scope.keyPanSpeed, 0);
                scope.update();
                break;

        }

    }

    function handleTouchStartRotate(event) {

        //console.log( 'handleTouchStartRotate' );

        rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);

    }

    function handleTouchStartDolly(event) {

        //console.log( 'handleTouchStartDolly' );

        var dx = event.touches[0].pageX - event.touches[1].pageX;
        var dy = event.touches[0].pageY - event.touches[1].pageY;

        var distance = Math.sqrt(dx * dx + dy * dy);

        dollyStart.set(0, distance);

    }

    function handleTouchStartPan(event) {

        //console.log( 'handleTouchStartPan' );

        panStart.set(event.touches[0].pageX, event.touches[0].pageY);

    }

    function handleTouchMoveRotate(event) {

        //console.log( 'handleTouchMoveRotate' );

        rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);
        rotateDelta.subVectors(rotateEnd, rotateStart);

        var element = scope.domElement === document ? scope.domElement.body : scope.domElement;

        // rotating across whole screen goes 360 degrees around
        rotateLeft(2 * Math.PI * rotateDelta.x / element.clientWidth * scope.rotateSpeed);

        // rotating up and down along whole screen attempts to go 360, but limited to 180
        rotateUp(2 * Math.PI * rotateDelta.y / element.clientHeight * scope.rotateSpeed);

        rotateStart.copy(rotateEnd);

        scope.update();

    }

    function handleTouchMoveDolly(event) {

        //console.log( 'handleTouchMoveDolly' );

        var dx = event.touches[0].pageX - event.touches[1].pageX;
        var dy = event.touches[0].pageY - event.touches[1].pageY;

        var distance = Math.sqrt(dx * dx + dy * dy);

        dollyEnd.set(0, distance);

        dollyDelta.subVectors(dollyEnd, dollyStart);

        if (dollyDelta.y > 0) {

            dollyOut(getZoomScale());

        } else if (dollyDelta.y < 0) {

            dollyIn(getZoomScale());

        }

        dollyStart.copy(dollyEnd);

        scope.update();

    }

    function handleTouchMovePan(event) {

        //console.log( 'handleTouchMovePan' );

        panEnd.set(event.touches[0].pageX, event.touches[0].pageY);

        panDelta.subVectors(panEnd, panStart);

        pan(panDelta.x, panDelta.y);

        panStart.copy(panEnd);

        scope.update();

    }

    function handleTouchEnd(event) {

        //console.log( 'handleTouchEnd' );

    }

    //
    // event handlers - FSM: listen for events and reset state
    //

    function onMouseDown(event) {
        // console.log(event)
        if (scope.enabled === false) return;

        event.preventDefault();

        if (event.button === scope.mouseButtons.ORBIT) {

            if (scope.enableRotate === false) return;

            handleMouseDownRotate(event);

            state = STATE.ROTATE;

        } else if (event.button === scope.mouseButtons.ZOOM) {

            if (scope.enableZoom === false) return;

            handleMouseDownDolly(event);

            state = STATE.DOLLY;

        } else if (event.button === scope.mouseButtons.PAN) {

            if (scope.enablePan === false) return;

            handleMouseDownPan(event);

            state = STATE.PAN;

        }

        if (state !== STATE.NONE) {

            document.addEventListener('mousemove', onMouseMove, false);
            document.addEventListener('mouseup', onMouseUp, false);

            scope.dispatchEvent(startEvent);

        }

    }

    function onMouseMove(event) {

        if (scope.enabled === false) return;

        event.preventDefault();

        if (state === STATE.ROTATE) {

            if (scope.enableRotate === false) return;

            handleMouseMoveRotate(event);

        } else if (state === STATE.DOLLY) {

            if (scope.enableZoom === false) return;

            handleMouseMoveDolly(event);

        } else if (state === STATE.PAN) {

            if (scope.enablePan === false) return;

            handleMouseMovePan(event);

        }

    }

    function onMouseUp(event) {

        if (scope.enabled === false) return;

        handleMouseUp(event);

        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseup', onMouseUp, false);

        scope.dispatchEvent(endEvent);

        state = STATE.NONE;

    }

    function onMouseWheel(event) {
        //debugger;
        if (scope.enabled === false || scope.enableZoom === false || (state !== STATE.NONE && state !== STATE.ROTATE)) return;

        event.preventDefault();
        event.stopPropagation();

        handleMouseWheel(event);

        scope.dispatchEvent(startEvent); // not sure why these are here...
        scope.dispatchEvent(endEvent);

    }

    function onKeyDown(event) {

        if (scope.enabled === false || scope.enableKeys === false || scope.enablePan === false) return;

        handleKeyDown(event);

    }

    function onTouchStart(event) {

        if (scope.enabled === false) return;

        switch (event.touches.length) {

            case 1:	// one-fingered touch: rotate

                if (scope.enableRotate === false) return;

                handleTouchStartRotate(event);

                state = STATE.TOUCH_ROTATE;

                break;

            case 2:	// two-fingered touch: dolly

                if (scope.enableZoom === false) return;

                handleTouchStartDolly(event);

                state = STATE.TOUCH_DOLLY;

                break;

            case 3: // three-fingered touch: pan

                if (scope.enablePan === false) return;

                handleTouchStartPan(event);

                state = STATE.TOUCH_PAN;

                break;

            default:

                state = STATE.NONE;

        }

        if (state !== STATE.NONE) {

            scope.dispatchEvent(startEvent);

        }

    }

    function onTouchMove(event) {

        if (scope.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();

        switch (event.touches.length) {

            case 1: // one-fingered touch: rotate

                if (scope.enableRotate === false) return;
                if (state !== STATE.TOUCH_ROTATE) return; // is this needed?...

                handleTouchMoveRotate(event);

                break;

            case 2: // two-fingered touch: dolly

                if (scope.enableZoom === false) return;
                if (state !== STATE.TOUCH_DOLLY) return; // is this needed?...

                handleTouchMoveDolly(event);

                break;

            case 3: // three-fingered touch: pan

                if (scope.enablePan === false) return;
                if (state !== STATE.TOUCH_PAN) return; // is this needed?...

                handleTouchMovePan(event);

                break;

            default:

                state = STATE.NONE;

        }

    }

    function onTouchEnd(event) {

        if (scope.enabled === false) return;

        handleTouchEnd(event);

        scope.dispatchEvent(endEvent);

        state = STATE.NONE;

    }

    function onContextMenu(event) {

        event.preventDefault();

    }

    //

    scope.domElement.addEventListener('contextmenu', onContextMenu, false);

    scope.domElement.addEventListener('mousedown', onMouseDown, false);
    scope.domElement.addEventListener('wheel', onMouseWheel, false);

    scope.domElement.addEventListener('touchstart', onTouchStart, false);
    scope.domElement.addEventListener('touchend', onTouchEnd, false);
    scope.domElement.addEventListener('touchmove', onTouchMove, false);

    window.addEventListener('keydown', onKeyDown, false);

    // force an update at start

    this.update();

};

THREE.OrbitControls.prototype = Object.create(THREE.EventDispatcher.prototype);
THREE.OrbitControls.prototype.constructor = THREE.OrbitControls;

Object.defineProperties(THREE.OrbitControls.prototype, {

    center: {

        get: function () {

            console.warn('THREE.OrbitControls: .center has been renamed to .target');
            return this.target;

        }

    },

    // backward compatibility

    noZoom: {

        get: function () {

            console.warn('THREE.OrbitControls: .noZoom has been deprecated. Use .enableZoom instead.');
            return !this.enableZoom;

        },

        set: function (value) {

            console.warn('THREE.OrbitControls: .noZoom has been deprecated. Use .enableZoom instead.');
            this.enableZoom = !value;

        }

    },

    noRotate: {

        get: function () {

            console.warn('THREE.OrbitControls: .noRotate has been deprecated. Use .enableRotate instead.');
            return !this.enableRotate;

        },

        set: function (value) {

            console.warn('THREE.OrbitControls: .noRotate has been deprecated. Use .enableRotate instead.');
            this.enableRotate = !value;

        }

    },

    noPan: {

        get: function () {

            console.warn('THREE.OrbitControls: .noPan has been deprecated. Use .enablePan instead.');
            return !this.enablePan;

        },

        set: function (value) {

            console.warn('THREE.OrbitControls: .noPan has been deprecated. Use .enablePan instead.');
            this.enablePan = !value;

        }

    },

    noKeys: {

        get: function () {

            console.warn('THREE.OrbitControls: .noKeys has been deprecated. Use .enableKeys instead.');
            return !this.enableKeys;

        },

        set: function (value) {

            console.warn('THREE.OrbitControls: .noKeys has been deprecated. Use .enableKeys instead.');
            this.enableKeys = !value;

        }

    },

    staticMoving: {

        get: function () {

            console.warn('THREE.OrbitControls: .staticMoving has been deprecated. Use .enableDamping instead.');
            return !this.enableDamping;

        },

        set: function (value) {

            console.warn('THREE.OrbitControls: .staticMoving has been deprecated. Use .enableDamping instead.');
            this.enableDamping = !value;

        }

    },

    dynamicDampingFactor: {

        get: function () {

            console.warn('THREE.OrbitControls: .dynamicDampingFactor has been renamed. Use .dampingFactor instead.');
            return this.dampingFactor;

        },

        set: function (value) {

            console.warn('THREE.OrbitControls: .dynamicDampingFactor has been renamed. Use .dampingFactor instead.');
            this.dampingFactor = value;

        }

    }

});


/**
 * Loads a Wavefront .mtl file specifying materials
 *
 * @author angelxuanchang
 */

THREE.MTLLoader = function (manager) {

    this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;

};

THREE.MTLLoader.prototype = {

    constructor: THREE.MTLLoader,

    /**
     * Loads and parses a MTL asset from a URL.
     *
     * @param {String} url - URL to the MTL file.
     * @param {Function} [onLoad] - Callback invoked with the loaded object.
     * @param {Function} [onProgress] - Callback for download progress.
     * @param {Function} [onError] - Callback for download errors.
     *
     * @see setPath setTexturePath
     *
     * @note In order for relative texture references to resolve correctly
     * you must call setPath and/or setTexturePath explicitly prior to load.
     */
    load: function (url, onLoad, onProgress, onError) {

        var scope = this;

        var loader = new THREE.FileLoader(this.manager);
        loader.setPath(this.path);
        loader.load(url, function (text) {

            onLoad(scope.parse(text));

        }, onProgress, onError);

    },

    /**
     * Set base path for resolving references.
     * If set this path will be prepended to each loaded and found reference.
     *
     * @see setTexturePath
     * @param {String} path
     *
     * @example
     *     mtlLoader.setPath( 'assets/obj/' );
     *     mtlLoader.load( 'my.mtl', ... );
     */
    setPath: function (path) {

        this.path = path;

    },

    /**
     * Set base path for resolving texture references.
     * If set this path will be prepended found texture reference.
     * If not set and setPath is, it will be used as texture base path.
     *
     * @see setPath
     * @param {String} path
     *
     * @example
     *     mtlLoader.setPath( 'assets/obj/' );
     *     mtlLoader.setTexturePath( 'assets/textures/' );
     *     mtlLoader.load( 'my.mtl', ... );
     */
    setTexturePath: function (path) {

        this.texturePath = path;

    },

    setBaseUrl: function (path) {

        console.warn('THREE.MTLLoader: .setBaseUrl() is deprecated. Use .setTexturePath( path ) for texture path or .setPath( path ) for general base path instead.');

        this.setTexturePath(path);

    },

    setCrossOrigin: function (value) {

        this.crossOrigin = value;

    },

    setMaterialOptions: function (value) {

        this.materialOptions = value;

    },

    /**
     * Parses a MTL file.
     *
     * @param {String} text - Content of MTL file
     * @return {THREE.MTLLoader.MaterialCreator}
     *
     * @see setPath setTexturePath
     *
     * @note In order for relative texture references to resolve correctly
     * you must call setPath and/or setTexturePath explicitly prior to parse.
     */
    parse: function (text) {

        var lines = text.split('\n');
        var info = {};
        var delimiter_pattern = /\s+/;
        var materialsInfo = {};

        for (var i = 0; i < lines.length; i++) {

            var line = lines[i];
            line = line.trim();

            if (line.length === 0 || line.charAt(0) === '#') {

                // Blank line or comment ignore
                continue;

            }

            var pos = line.indexOf(' ');

            var key = (pos >= 0) ? line.substring(0, pos) : line;
            key = key.toLowerCase();

            var value = (pos >= 0) ? line.substring(pos + 1) : '';
            value = value.trim();

            if (key === 'newmtl') {

                // New material

                info = {name: value};
                materialsInfo[value] = info;

            } else if (info) {

                if (key === 'ka' || key === 'kd' || key === 'ks') {

                    var ss = value.split(delimiter_pattern, 3);
                    info[key] = [parseFloat(ss[0]), parseFloat(ss[1]), parseFloat(ss[2])];

                } else {

                    info[key] = value;

                }

            }

        }

        var materialCreator = new THREE.MTLLoader.MaterialCreator(this.texturePath || this.path, this.materialOptions);
        materialCreator.setCrossOrigin(this.crossOrigin);
        materialCreator.setManager(this.manager);
        materialCreator.setMaterials(materialsInfo);
        return materialCreator;

    }

};


/**
 * Create a new THREE-MTLLoader.MaterialCreator
 * @param baseUrl - Url relative to which textures are loaded
 * @param options - Set of options on how to construct the materials
 *                  side: Which side to apply the material
 *                        THREE.FrontSide (default), THREE.BackSide, THREE.DoubleSide
 *                  wrap: What type of wrapping to apply for textures
 *                        THREE.RepeatWrapping (default), THREE.ClampToEdgeWrapping, THREE.MirroredRepeatWrapping
 *                  normalizeRGB: RGBs need to be normalized to 0-1 from 0-255
 *                                Default: false, assumed to be already normalized
 *                  ignoreZeroRGBs: Ignore values of RGBs (Ka,Kd,Ks) that are all 0's
 *                                  Default: false
 * @constructor
 */

THREE.MTLLoader.MaterialCreator = function (baseUrl, options) {

    this.baseUrl = baseUrl || '';
    this.options = options;
    this.materialsInfo = {};
    this.materials = {};
    this.materialsArray = [];
    this.nameLookup = {};

    this.side = (this.options && this.options.side) ? this.options.side : THREE.FrontSide;
    this.wrap = (this.options && this.options.wrap) ? this.options.wrap : THREE.RepeatWrapping;

};

THREE.MTLLoader.MaterialCreator.prototype = {

    constructor: THREE.MTLLoader.MaterialCreator,

    crossOrigin: 'Anonymous',

    setCrossOrigin: function (value) {

        this.crossOrigin = value;

    },

    setManager: function (value) {

        this.manager = value;

    },

    setMaterials: function (materialsInfo) {

        this.materialsInfo = this.convert(materialsInfo);
        this.materials = {};
        this.materialsArray = [];
        this.nameLookup = {};

    },

    convert: function (materialsInfo) {

        if (!this.options) return materialsInfo;

        var converted = {};

        for (var mn in materialsInfo) {

            // Convert materials info into normalized form based on options

            var mat = materialsInfo[mn];

            var covmat = {};

            converted[mn] = covmat;

            for (var prop in mat) {

                var save = true;
                var value = mat[prop];
                var lprop = prop.toLowerCase();

                switch (lprop) {

                    case 'kd':
                    case 'ka':
                    case 'ks':

                        // Diffuse color (color under white light) using RGB values

                        if (this.options && this.options.normalizeRGB) {

                            value = [value[0] / 255, value[1] / 255, value[2] / 255];

                        }

                        if (this.options && this.options.ignoreZeroRGBs) {

                            if (value[0] === 0 && value[1] === 0 && value[2] === 0) {

                                // ignore

                                save = false;

                            }

                        }

                        break;

                    default:

                        break;

                }

                if (save) {

                    covmat[lprop] = value;

                }

            }

        }

        return converted;

    },

    preload: function () {

        for (var mn in this.materialsInfo) {

            this.create(mn);

        }

    },

    getIndex: function (materialName) {

        return this.nameLookup[materialName];

    },

    getAsArray: function () {

        var index = 0;

        for (var mn in this.materialsInfo) {

            this.materialsArray[index] = this.create(mn);
            this.nameLookup[mn] = index;
            index++;

        }

        return this.materialsArray;

    },

    create: function (materialName) {

        if (this.materials[materialName] === undefined) {

            this.createMaterial_(materialName);

        }

        return this.materials[materialName];

    },

    createMaterial_: function (materialName) {

        // Create material

        var scope = this;
        var mat = this.materialsInfo[materialName];
        var params = {

            name: materialName,
            side: this.side

        };

        function resolveURL(baseUrl, url) {

            if (typeof url !== 'string' || url === '')
                return '';

            // Absolute URL
            if (/^https?:\/\//i.test(url)) return url;

            return baseUrl + url;

        }

        function setMapForType(mapType, value) {

            if (params[mapType]) return; // Keep the first encountered texture

            var texParams = scope.getTextureParams(value, params);
            var map = scope.loadTexture(resolveURL(scope.baseUrl, texParams.url));

            map.repeat.copy(texParams.scale);
            map.offset.copy(texParams.offset);

            map.wrapS = scope.wrap;
            map.wrapT = scope.wrap;

            params[mapType] = map;

        }

        for (var prop in mat) {

            var value = mat[prop];
            var n;

            if (value === '') continue;

            switch (prop.toLowerCase()) {

                // Ns is material specular exponent

                case 'kd':

                    // Diffuse color (color under white light) using RGB values

                    params.color = new THREE.Color().fromArray(value);

                    break;

                case 'ks':

                    // Specular color (color when light is reflected from shiny surface) using RGB values
                    params.specular = new THREE.Color().fromArray(value);

                    break;

                case 'map_kd':

                    // Diffuse texture map

                    setMapForType("map", value);

                    break;

                case 'map_ks':

                    // Specular map

                    setMapForType("specularMap", value);

                    break;

                case 'norm':

                    setMapForType("normalMap", value);

                    break;

                case 'map_bump':
                case 'bump':

                    // Bump texture map

                    setMapForType("bumpMap", value);

                    break;

                case 'ns':

                    // The specular exponent (defines the focus of the specular highlight)
                    // A high exponent results in a tight, concentrated highlight. Ns values normally range from 0 to 1000.

                    params.shininess = parseFloat(value);

                    break;

                case 'd':
                    n = parseFloat(value);

                    if (n < 1) {

                        params.opacity = n;
                        params.transparent = true;

                    }

                    break;

                case 'tr':
                    n = parseFloat(value);

                    if (n > 0) {

                        params.opacity = 1 - n;
                        params.transparent = true;

                    }

                    break;

                default:
                    break;

            }

        }

        this.materials[materialName] = new THREE.MeshPhongMaterial(params);
        return this.materials[materialName];

    },

    getTextureParams: function (value, matParams) {

        var texParams = {

            scale: new THREE.Vector2(1, 1),
            offset: new THREE.Vector2(0, 0)

        };

        var items = value.split(/\s+/);
        var pos;

        pos = items.indexOf('-bm');

        if (pos >= 0) {

            matParams.bumpScale = parseFloat(items[pos + 1]);
            items.splice(pos, 2);

        }

        pos = items.indexOf('-s');

        if (pos >= 0) {

            texParams.scale.set(parseFloat(items[pos + 1]), parseFloat(items[pos + 2]));
            items.splice(pos, 4); // we expect 3 parameters here!

        }

        pos = items.indexOf('-o');

        if (pos >= 0) {

            texParams.offset.set(parseFloat(items[pos + 1]), parseFloat(items[pos + 2]));
            items.splice(pos, 4); // we expect 3 parameters here!

        }

        texParams.url = items.join(' ').trim();
        return texParams;

    },

    loadTexture: function (url, mapping, onLoad, onProgress, onError) {

        var texture;
        var loader = THREE.Loader.Handlers.get(url);
        var manager = (this.manager !== undefined) ? this.manager : THREE.DefaultLoadingManager;

        if (loader === null) {

            loader = new THREE.TextureLoader(manager);

        }

        if (loader.setCrossOrigin) loader.setCrossOrigin(this.crossOrigin);
        texture = loader.load(url, onLoad, onProgress, onError);

        if (mapping !== undefined) texture.mapping = mapping;

        return texture;

    }

};


/**
 * @author mrdoob / http://mrdoob.com/
 */

THREE.OBJLoader = (function () {

    // o object_name | g group_name
    var object_pattern = /^[og]\s*(.+)?/;
    // mtllib file_reference
    var material_library_pattern = /^mtllib /;
    // usemtl material_name
    var material_use_pattern = /^usemtl /;

    function ParserState() {

        var state = {
            objects: [],
            object: {},

            vertices: [],
            normals: [],
            colors: [],
            uvs: [],

            materialLibraries: [],

            startObject: function (name, fromDeclaration) {

                // If the current object (initial from reset) is not from a g/o declaration in the parsed
                // file. We need to use it for the first parsed g/o to keep things in sync.
                if (this.object && this.object.fromDeclaration === false) {

                    this.object.name = name;
                    this.object.fromDeclaration = (fromDeclaration !== false);
                    return;

                }

                var previousMaterial = (this.object && typeof this.object.currentMaterial === 'function' ? this.object.currentMaterial() : undefined);

                if (this.object && typeof this.object._finalize === 'function') {

                    this.object._finalize(true);

                }

                this.object = {
                    name: name || '',
                    fromDeclaration: (fromDeclaration !== false),

                    geometry: {
                        vertices: [],
                        normals: [],
                        colors: [],
                        uvs: []
                    },
                    materials: [],
                    smooth: true,

                    startMaterial: function (name, libraries) {

                        var previous = this._finalize(false);

                        // New usemtl declaration overwrites an inherited material, except if faces were declared
                        // after the material, then it must be preserved for proper MultiMaterial continuation.
                        if (previous && (previous.inherited || previous.groupCount <= 0)) {

                            this.materials.splice(previous.index, 1);

                        }

                        var material = {
                            index: this.materials.length,
                            name: name || '',
                            mtllib: (Array.isArray(libraries) && libraries.length > 0 ? libraries[libraries.length - 1] : ''),
                            smooth: (previous !== undefined ? previous.smooth : this.smooth),
                            groupStart: (previous !== undefined ? previous.groupEnd : 0),
                            groupEnd: -1,
                            groupCount: -1,
                            inherited: false,

                            clone: function (index) {

                                var cloned = {
                                    index: (typeof index === 'number' ? index : this.index),
                                    name: this.name,
                                    mtllib: this.mtllib,
                                    smooth: this.smooth,
                                    groupStart: 0,
                                    groupEnd: -1,
                                    groupCount: -1,
                                    inherited: false
                                };
                                cloned.clone = this.clone.bind(cloned);
                                return cloned;

                            }
                        };

                        this.materials.push(material);

                        return material;

                    },

                    currentMaterial: function () {

                        if (this.materials.length > 0) {

                            return this.materials[this.materials.length - 1];

                        }

                        return undefined;

                    },

                    _finalize: function (end) {

                        var lastMultiMaterial = this.currentMaterial();
                        if (lastMultiMaterial && lastMultiMaterial.groupEnd === -1) {

                            lastMultiMaterial.groupEnd = this.geometry.vertices.length / 3;
                            lastMultiMaterial.groupCount = lastMultiMaterial.groupEnd - lastMultiMaterial.groupStart;
                            lastMultiMaterial.inherited = false;

                        }

                        // Ignore objects tail materials if no face declarations followed them before a new o/g started.
                        if (end && this.materials.length > 1) {

                            for (var mi = this.materials.length - 1; mi >= 0; mi--) {

                                if (this.materials[mi].groupCount <= 0) {

                                    this.materials.splice(mi, 1);

                                }

                            }

                        }

                        // Guarantee at least one empty material, this makes the creation later more straight forward.
                        if (end && this.materials.length === 0) {

                            this.materials.push({
                                name: '',
                                smooth: this.smooth
                            });

                        }

                        return lastMultiMaterial;

                    }
                };

                // Inherit previous objects material.
                // Spec tells us that a declared material must be set to all objects until a new material is declared.
                // If a usemtl declaration is encountered while this new object is being parsed, it will
                // overwrite the inherited material. Exception being that there was already face declarations
                // to the inherited material, then it will be preserved for proper MultiMaterial continuation.

                if (previousMaterial && previousMaterial.name && typeof previousMaterial.clone === 'function') {

                    var declared = previousMaterial.clone(0);
                    declared.inherited = true;
                    this.object.materials.push(declared);

                }

                this.objects.push(this.object);

            },

            finalize: function () {

                if (this.object && typeof this.object._finalize === 'function') {

                    this.object._finalize(true);

                }

            },

            parseVertexIndex: function (value, len) {

                var index = parseInt(value, 10);
                return (index >= 0 ? index - 1 : index + len / 3) * 3;

            },

            parseNormalIndex: function (value, len) {

                var index = parseInt(value, 10);
                return (index >= 0 ? index - 1 : index + len / 3) * 3;

            },

            parseUVIndex: function (value, len) {

                var index = parseInt(value, 10);
                return (index >= 0 ? index - 1 : index + len / 2) * 2;

            },

            addVertex: function (a, b, c) {

                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);

            },

            addVertexLine: function (a) {

                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);

            },

            addNormal: function (a, b, c) {

                var src = this.normals;
                var dst = this.object.geometry.normals;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);

            },

            addColor: function (a, b, c) {

                var src = this.colors;
                var dst = this.object.geometry.colors;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);

            },

            addUV: function (a, b, c) {

                var src = this.uvs;
                var dst = this.object.geometry.uvs;

                dst.push(src[a + 0], src[a + 1]);
                dst.push(src[b + 0], src[b + 1]);
                dst.push(src[c + 0], src[c + 1]);

            },

            addUVLine: function (a) {

                var src = this.uvs;
                var dst = this.object.geometry.uvs;

                dst.push(src[a + 0], src[a + 1]);

            },

            addFace: function (a, b, c, ua, ub, uc, na, nb, nc) {

                var vLen = this.vertices.length;

                var ia = this.parseVertexIndex(a, vLen);
                var ib = this.parseVertexIndex(b, vLen);
                var ic = this.parseVertexIndex(c, vLen);

                this.addVertex(ia, ib, ic);

                if (ua !== undefined) {

                    var uvLen = this.uvs.length;

                    ia = this.parseUVIndex(ua, uvLen);
                    ib = this.parseUVIndex(ub, uvLen);
                    ic = this.parseUVIndex(uc, uvLen);

                    this.addUV(ia, ib, ic);

                }

                if (na !== undefined) {

                    // Normals are many times the same. If so, skip function call and parseInt.
                    var nLen = this.normals.length;
                    ia = this.parseNormalIndex(na, nLen);

                    ib = na === nb ? ia : this.parseNormalIndex(nb, nLen);
                    ic = na === nc ? ia : this.parseNormalIndex(nc, nLen);

                    this.addNormal(ia, ib, ic);

                }

                if (this.colors.length > 0) {

                    this.addColor(ia, ib, ic);

                }

            },

            addLineGeometry: function (vertices, uvs) {

                this.object.geometry.type = 'Line';

                var vLen = this.vertices.length;
                var uvLen = this.uvs.length;

                for (var vi = 0, l = vertices.length; vi < l; vi++) {

                    this.addVertexLine(this.parseVertexIndex(vertices[vi], vLen));

                }

                for (var uvi = 0, l = uvs.length; uvi < l; uvi++) {

                    this.addUVLine(this.parseUVIndex(uvs[uvi], uvLen));

                }

            }

        };

        state.startObject('', false);

        return state;

    }

    //

    function OBJLoader(manager) {

        this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;

        this.materials = null;

    }

    OBJLoader.prototype = {

        constructor: OBJLoader,

        load: function (url, onLoad, onProgress, onError) {

            var scope = this;

            var loader = new THREE.FileLoader(scope.manager);
            loader.setPath(this.path);
            loader.load(url, function (text) {

                onLoad(scope.parse(text));

            }, onProgress, onError);

        },

        setPath: function (value) {

            this.path = value;

        },

        setMaterials: function (materials) {

            this.materials = materials;

            return this;

        },

        parse: function (text) {

            console.time('OBJLoader');

            var state = new ParserState();

            if (text.indexOf('\r\n') !== -1) {

                // This is faster than String.split with regex that splits on both
                text = text.replace(/\r\n/g, '\n');

            }

            if (text.indexOf('\\\n') !== -1) {

                // join lines separated by a line continuation character (\)
                text = text.replace(/\\\n/g, '');

            }

            var lines = text.split('\n');
            var line = '', lineFirstChar = '';
            var lineLength = 0;
            var result = [];

            // Faster to just trim left side of the line. Use if available.
            var trimLeft = (typeof ''.trimLeft === 'function');

            for (var i = 0, l = lines.length; i < l; i++) {

                line = lines[i];

                line = trimLeft ? line.trimLeft() : line.trim();

                lineLength = line.length;

                if (lineLength === 0) continue;

                lineFirstChar = line.charAt(0);

                // @todo invoke passed in handler if any
                if (lineFirstChar === '#') continue;

                if (lineFirstChar === 'v') {

                    var data = line.split(/\s+/);

                    switch (data[0]) {

                        case 'v':
                            state.vertices.push(
                                parseFloat(data[1]),
                                parseFloat(data[2]),
                                parseFloat(data[3])
                            );
                            if (data.length === 8) {

                                state.colors.push(
                                    parseFloat(data[4]),
                                    parseFloat(data[5]),
                                    parseFloat(data[6])
                                );

                            }
                            break;
                        case 'vn':
                            state.normals.push(
                                parseFloat(data[1]),
                                parseFloat(data[2]),
                                parseFloat(data[3])
                            );
                            break;
                        case 'vt':
                            state.uvs.push(
                                parseFloat(data[1]),
                                parseFloat(data[2])
                            );
                            break;

                    }

                } else if (lineFirstChar === 'f') {

                    var lineData = line.substr(1).trim();
                    var vertexData = lineData.split(/\s+/);
                    var faceVertices = [];

                    // Parse the face vertex data into an easy to work with format

                    for (var j = 0, jl = vertexData.length; j < jl; j++) {

                        var vertex = vertexData[j];

                        if (vertex.length > 0) {

                            var vertexParts = vertex.split('/');
                            faceVertices.push(vertexParts);

                        }

                    }

                    // Draw an edge between the first vertex and all subsequent vertices to form an n-gon

                    var v1 = faceVertices[0];

                    for (var j = 1, jl = faceVertices.length - 1; j < jl; j++) {

                        var v2 = faceVertices[j];
                        var v3 = faceVertices[j + 1];

                        state.addFace(
                            v1[0], v2[0], v3[0],
                            v1[1], v2[1], v3[1],
                            v1[2], v2[2], v3[2]
                        );

                    }

                } else if (lineFirstChar === 'l') {

                    var lineParts = line.substring(1).trim().split(" ");
                    var lineVertices = [], lineUVs = [];

                    if (line.indexOf("/") === -1) {

                        lineVertices = lineParts;

                    } else {

                        for (var li = 0, llen = lineParts.length; li < llen; li++) {

                            var parts = lineParts[li].split("/");

                            if (parts[0] !== "") lineVertices.push(parts[0]);
                            if (parts[1] !== "") lineUVs.push(parts[1]);

                        }

                    }
                    state.addLineGeometry(lineVertices, lineUVs);

                } else if ((result = object_pattern.exec(line)) !== null) {

                    // o object_name
                    // or
                    // g group_name

                    // WORKAROUND: https://bugs.chromium.org/p/v8/issues/detail?id=2869
                    // var name = result[ 0 ].substr( 1 ).trim();
                    var name = (" " + result[0].substr(1).trim()).substr(1);

                    state.startObject(name);

                } else if (material_use_pattern.test(line)) {

                    // material

                    state.object.startMaterial(line.substring(7).trim(), state.materialLibraries);

                } else if (material_library_pattern.test(line)) {

                    // mtl file

                    state.materialLibraries.push(line.substring(7).trim());

                } else if (lineFirstChar === 's') {

                    result = line.split(' ');

                    // smooth shading

                    // @todo Handle files that have varying smooth values for a set of faces inside one geometry,
                    // but does not define a usemtl for each face set.
                    // This should be detected and a dummy material created (later MultiMaterial and geometry groups).
                    // This requires some care to not create extra material on each smooth value for "normal" obj files.
                    // where explicit usemtl defines geometry groups.
                    // Example asset: examples/models/obj/cerberus/Cerberus.obj

                    /*
					 * http://paulbourke.net/dataformats/obj/
					 * or
					 * http://www.cs.utah.edu/~boulos/cs3505/obj_spec.pdf
					 *
					 * From chapter "Grouping" Syntax explanation "s group_number":
					 * "group_number is the smoothing group number. To turn off smoothing groups, use a value of 0 or off.
					 * Polygonal elements use group numbers to put elements in different smoothing groups. For free-form
					 * surfaces, smoothing groups are either turned on or off; there is no difference between values greater
					 * than 0."
					 */
                    if (result.length > 1) {

                        var value = result[1].trim().toLowerCase();
                        state.object.smooth = (value !== '0' && value !== 'off');

                    } else {

                        // ZBrush can produce "s" lines #11707
                        state.object.smooth = true;

                    }
                    var material = state.object.currentMaterial();
                    if (material) material.smooth = state.object.smooth;

                } else {

                    // Handle null terminated files without exception
                    if (line === '\0') continue;

                    throw new Error('THREE.OBJLoader: Unexpected line: "' + line + '"');

                }

            }

            state.finalize();

            var container = new THREE.Group();
            container.materialLibraries = [].concat(state.materialLibraries);

            for (var i = 0, l = state.objects.length; i < l; i++) {

                var object = state.objects[i];
                var geometry = object.geometry;
                var materials = object.materials;
                var isLine = (geometry.type === 'Line');

                // Skip o/g line declarations that did not follow with any faces
                if (geometry.vertices.length === 0) continue;

                var buffergeometry = new THREE.BufferGeometry();

                buffergeometry.addAttribute('position', new THREE.Float32BufferAttribute(geometry.vertices, 3));

                if (geometry.normals.length > 0) {

                    buffergeometry.addAttribute('normal', new THREE.Float32BufferAttribute(geometry.normals, 3));

                } else {

                    buffergeometry.computeVertexNormals();

                }

                if (geometry.colors.length > 0) {

                    buffergeometry.addAttribute('color', new THREE.Float32BufferAttribute(geometry.colors, 3));

                }

                if (geometry.uvs.length > 0) {

                    buffergeometry.addAttribute('uv', new THREE.Float32BufferAttribute(geometry.uvs, 2));

                }

                // Create materials

                var createdMaterials = [];

                for (var mi = 0, miLen = materials.length; mi < miLen; mi++) {

                    var sourceMaterial = materials[mi];
                    var material = undefined;

                    if (this.materials !== null) {

                        material = this.materials.create(sourceMaterial.name);

                        // mtl etc. loaders probably can't create line materials correctly, copy properties to a line material.
                        if (isLine && material && !(material instanceof THREE.LineBasicMaterial)) {

                            var materialLine = new THREE.LineBasicMaterial();
                            materialLine.copy(material);
                            material = materialLine;

                        }

                    }

                    if (!material) {

                        material = (!isLine ? new THREE.MeshPhongMaterial() : new THREE.LineBasicMaterial());
                        material.name = sourceMaterial.name;

                    }

                    material.flatShading = sourceMaterial.smooth ? false : true;

                    createdMaterials.push(material);

                }

                // Create mesh

                var mesh;

                if (createdMaterials.length > 1) {

                    for (var mi = 0, miLen = materials.length; mi < miLen; mi++) {

                        var sourceMaterial = materials[mi];
                        buffergeometry.addGroup(sourceMaterial.groupStart, sourceMaterial.groupCount, mi);

                    }

                    mesh = (!isLine ? new THREE.Mesh(buffergeometry, createdMaterials) : new THREE.LineSegments(buffergeometry, createdMaterials));

                } else {

                    mesh = (!isLine ? new THREE.Mesh(buffergeometry, createdMaterials[0]) : new THREE.LineSegments(buffergeometry, createdMaterials[0]));

                }

                mesh.name = object.name;

                container.add(mesh);

            }

            console.timeEnd('OBJLoader');

            return container;

        }

    };

    return OBJLoader;

})();


/**
 * @author mrdoob / http://mrdoob.com/
 * @author Mugen87 / https://github.com/Mugen87
 */

THREE.ColladaLoader = function (manager) {

    this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;

};

THREE.ColladaLoader.prototype = {

    constructor: THREE.ColladaLoader,

    crossOrigin: 'anonymous',

    load: function (url, onLoad, onProgress, onError) {

        var scope = this;

        var path = scope.path === undefined ? THREE.LoaderUtils.extractUrlBase(url) : scope.path;

        var loader = new THREE.FileLoader(scope.manager);
        loader.load(url, function (text) {

            onLoad(scope.parse(text, path));

        }, onProgress, onError);

    },

    setPath: function (value) {

        this.path = value;
        return this;

    },

    options: {

        set convertUpAxis(value) {

            console.warn('THREE.ColladaLoader: options.convertUpAxis() has been removed. Up axis is converted automatically.');

        }

    },

    setCrossOrigin: function (value) {

        this.crossOrigin = value;
        return this;

    },

    parse: function (text, path) {

        function getElementsByTagName(xml, name) {

            // Non recursive xml.getElementsByTagName() ...

            var array = [];
            var childNodes = xml.childNodes;

            for (var i = 0, l = childNodes.length; i < l; i++) {

                var child = childNodes[i];

                if (child.nodeName === name) {

                    array.push(child);

                }

            }

            return array;

        }

        function parseStrings(text) {

            if (text.length === 0) return [];

            var parts = text.trim().split(/\s+/);
            var array = new Array(parts.length);

            for (var i = 0, l = parts.length; i < l; i++) {

                array[i] = parts[i];

            }

            return array;

        }

        function parseFloats(text) {

            if (text.length === 0) return [];

            var parts = text.trim().split(/\s+/);
            var array = new Array(parts.length);

            for (var i = 0, l = parts.length; i < l; i++) {

                array[i] = parseFloat(parts[i]);

            }

            return array;

        }

        function parseInts(text) {

            if (text.length === 0) return [];

            var parts = text.trim().split(/\s+/);
            var array = new Array(parts.length);

            for (var i = 0, l = parts.length; i < l; i++) {

                array[i] = parseInt(parts[i]);

            }

            return array;

        }

        function parseId(text) {

            return text.substring(1);

        }

        function generateId() {

            return 'three_default_' + (count++);

        }

        function isEmpty(object) {

            return Object.keys(object).length === 0;

        }

        // asset

        function parseAsset(xml) {

            return {
                unit: parseAssetUnit(getElementsByTagName(xml, 'unit')[0]),
                upAxis: parseAssetUpAxis(getElementsByTagName(xml, 'up_axis')[0])
            };

        }

        function parseAssetUnit(xml) {

            if ((xml !== undefined) && (xml.hasAttribute('meter') === true)) {

                return parseFloat(xml.getAttribute('meter'));

            } else {

                return 1; // default 1 meter

            }

        }

        function parseAssetUpAxis(xml) {

            return xml !== undefined ? xml.textContent : 'Y_UP';

        }

        // library

        function parseLibrary(xml, libraryName, nodeName, parser) {

            var library = getElementsByTagName(xml, libraryName)[0];

            if (library !== undefined) {

                var elements = getElementsByTagName(library, nodeName);

                for (var i = 0; i < elements.length; i++) {

                    parser(elements[i]);

                }

            }

        }

        function buildLibrary(data, builder) {

            for (var name in data) {

                var object = data[name];
                object.build = builder(data[name]);

            }

        }

        // get

        function getBuild(data, builder) {

            if (data.build !== undefined) return data.build;

            data.build = builder(data);

            return data.build;

        }

        // animation

        function parseAnimation(xml) {

            var data = {
                sources: {},
                samplers: {},
                channels: {}
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                var id;

                switch (child.nodeName) {

                    case 'source':
                        id = child.getAttribute('id');
                        data.sources[id] = parseSource(child);
                        break;

                    case 'sampler':
                        id = child.getAttribute('id');
                        data.samplers[id] = parseAnimationSampler(child);
                        break;

                    case 'channel':
                        id = child.getAttribute('target');
                        data.channels[id] = parseAnimationChannel(child);
                        break;

                    default:
                        console.log(child);

                }

            }

            library.animations[xml.getAttribute('id')] = data;

        }

        function parseAnimationSampler(xml) {

            var data = {
                inputs: {},
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'input':
                        var id = parseId(child.getAttribute('source'));
                        var semantic = child.getAttribute('semantic');
                        data.inputs[semantic] = id;
                        break;

                }

            }

            return data;

        }

        function parseAnimationChannel(xml) {

            var data = {};

            var target = xml.getAttribute('target');

            // parsing SID Addressing Syntax

            var parts = target.split('/');

            var id = parts.shift();
            var sid = parts.shift();

            // check selection syntax

            var arraySyntax = (sid.indexOf('(') !== -1);
            var memberSyntax = (sid.indexOf('.') !== -1);

            if (memberSyntax) {

                //  member selection access

                parts = sid.split('.');
                sid = parts.shift();
                data.member = parts.shift();

            } else if (arraySyntax) {

                // array-access syntax. can be used to express fields in one-dimensional vectors or two-dimensional matrices.

                var indices = sid.split('(');
                sid = indices.shift();

                for (var i = 0; i < indices.length; i++) {

                    indices[i] = parseInt(indices[i].replace(/\)/, ''));

                }

                data.indices = indices;

            }

            data.id = id;
            data.sid = sid;

            data.arraySyntax = arraySyntax;
            data.memberSyntax = memberSyntax;

            data.sampler = parseId(xml.getAttribute('source'));

            return data;

        }

        function buildAnimation(data) {

            var tracks = [];

            var channels = data.channels;
            var samplers = data.samplers;
            var sources = data.sources;

            for (var target in channels) {

                if (channels.hasOwnProperty(target)) {

                    var channel = channels[target];
                    var sampler = samplers[channel.sampler];

                    var inputId = sampler.inputs.INPUT;
                    var outputId = sampler.inputs.OUTPUT;

                    var inputSource = sources[inputId];
                    var outputSource = sources[outputId];

                    var animation = buildAnimationChannel(channel, inputSource, outputSource);

                    createKeyframeTracks(animation, tracks);

                }

            }

            return tracks;

        }

        function getAnimation(id) {

            return getBuild(library.animations[id], buildAnimation);

        }

        function buildAnimationChannel(channel, inputSource, outputSource) {

            var node = library.nodes[channel.id];
            var object3D = getNode(node.id);

            var transform = node.transforms[channel.sid];
            var defaultMatrix = node.matrix.clone().transpose();

            var time, stride;
            var i, il, j, jl;

            var data = {};

            // the collada spec allows the animation of data in various ways.
            // depending on the transform type (matrix, translate, rotate, scale), we execute different logic

            switch (transform) {

                case 'matrix':

                    for (i = 0, il = inputSource.array.length; i < il; i++) {

                        time = inputSource.array[i];
                        stride = i * outputSource.stride;

                        if (data[time] === undefined) data[time] = {};

                        if (channel.arraySyntax === true) {

                            var value = outputSource.array[stride];
                            var index = channel.indices[0] + 4 * channel.indices[1];

                            data[time][index] = value;

                        } else {

                            for (j = 0, jl = outputSource.stride; j < jl; j++) {

                                data[time][j] = outputSource.array[stride + j];

                            }

                        }

                    }

                    break;

                case 'translate':
                    console.warn('THREE.ColladaLoader: Animation transform type "%s" not yet implemented.', transform);
                    break;

                case 'rotate':
                    console.warn('THREE.ColladaLoader: Animation transform type "%s" not yet implemented.', transform);
                    break;

                case 'scale':
                    console.warn('THREE.ColladaLoader: Animation transform type "%s" not yet implemented.', transform);
                    break;

            }

            var keyframes = prepareAnimationData(data, defaultMatrix);

            var animation = {
                name: object3D.uuid,
                keyframes: keyframes
            };

            return animation;

        }

        function prepareAnimationData(data, defaultMatrix) {

            var keyframes = [];

            // transfer data into a sortable array

            for (var time in data) {

                keyframes.push({time: parseFloat(time), value: data[time]});

            }

            // ensure keyframes are sorted by time

            keyframes.sort(ascending);

            // now we clean up all animation data, so we can use them for keyframe tracks

            for (var i = 0; i < 16; i++) {

                transformAnimationData(keyframes, i, defaultMatrix.elements[i]);

            }

            return keyframes;

            // array sort function

            function ascending(a, b) {

                return a.time - b.time;

            }

        }

        var position = new THREE.Vector3();
        var scale = new THREE.Vector3();
        var quaternion = new THREE.Quaternion();

        function createKeyframeTracks(animation, tracks) {

            var keyframes = animation.keyframes;
            var name = animation.name;

            var times = [];
            var positionData = [];
            var quaternionData = [];
            var scaleData = [];

            for (var i = 0, l = keyframes.length; i < l; i++) {

                var keyframe = keyframes[i];

                var time = keyframe.time;
                var value = keyframe.value;

                matrix.fromArray(value).transpose();
                matrix.decompose(position, quaternion, scale);

                times.push(time);
                positionData.push(position.x, position.y, position.z);
                quaternionData.push(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
                scaleData.push(scale.x, scale.y, scale.z);

            }

            if (positionData.length > 0) tracks.push(new THREE.VectorKeyframeTrack(name + '.position', times, positionData));
            if (quaternionData.length > 0) tracks.push(new THREE.QuaternionKeyframeTrack(name + '.quaternion', times, quaternionData));
            if (scaleData.length > 0) tracks.push(new THREE.VectorKeyframeTrack(name + '.scale', times, scaleData));

            return tracks;

        }

        function transformAnimationData(keyframes, property, defaultValue) {

            var keyframe;

            var empty = true;
            var i, l;

            // check, if values of a property are missing in our keyframes

            for (i = 0, l = keyframes.length; i < l; i++) {

                keyframe = keyframes[i];

                if (keyframe.value[property] === undefined) {

                    keyframe.value[property] = null; // mark as missing

                } else {

                    empty = false;

                }

            }

            if (empty === true) {

                // no values at all, so we set a default value

                for (i = 0, l = keyframes.length; i < l; i++) {

                    keyframe = keyframes[i];

                    keyframe.value[property] = defaultValue;

                }

            } else {

                // filling gaps

                createMissingKeyframes(keyframes, property);

            }

        }

        function createMissingKeyframes(keyframes, property) {

            var prev, next;

            for (var i = 0, l = keyframes.length; i < l; i++) {

                var keyframe = keyframes[i];

                if (keyframe.value[property] === null) {

                    prev = getPrev(keyframes, i, property);
                    next = getNext(keyframes, i, property);

                    if (prev === null) {

                        keyframe.value[property] = next.value[property];
                        continue;

                    }

                    if (next === null) {

                        keyframe.value[property] = prev.value[property];
                        continue;

                    }

                    interpolate(keyframe, prev, next, property);

                }

            }

        }

        function getPrev(keyframes, i, property) {

            while (i >= 0) {

                var keyframe = keyframes[i];

                if (keyframe.value[property] !== null) return keyframe;

                i--;

            }

            return null;

        }

        function getNext(keyframes, i, property) {

            while (i < keyframes.length) {

                var keyframe = keyframes[i];

                if (keyframe.value[property] !== null) return keyframe;

                i++;

            }

            return null;

        }

        function interpolate(key, prev, next, property) {

            if ((next.time - prev.time) === 0) {

                key.value[property] = prev.value[property];
                return;

            }

            key.value[property] = ((key.time - prev.time) * (next.value[property] - prev.value[property]) / (next.time - prev.time)) + prev.value[property];

        }

        // animation clips

        function parseAnimationClip(xml) {

            var data = {
                name: xml.getAttribute('id') || 'default',
                start: parseFloat(xml.getAttribute('start') || 0),
                end: parseFloat(xml.getAttribute('end') || 0),
                animations: []
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'instance_animation':
                        data.animations.push(parseId(child.getAttribute('url')));
                        break;

                }

            }

            library.clips[xml.getAttribute('id')] = data;

        }

        function buildAnimationClip(data) {

            var tracks = [];

            var name = data.name;
            var duration = (data.end - data.start) || -1;
            var animations = data.animations;

            for (var i = 0, il = animations.length; i < il; i++) {

                var animationTracks = getAnimation(animations[i]);

                for (var j = 0, jl = animationTracks.length; j < jl; j++) {

                    tracks.push(animationTracks[j]);

                }

            }

            return new THREE.AnimationClip(name, duration, tracks);

        }

        function getAnimationClip(id) {

            return getBuild(library.clips[id], buildAnimationClip);

        }

        // controller

        function parseController(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'skin':
                        // there is exactly one skin per controller
                        data.id = parseId(child.getAttribute('source'));
                        data.skin = parseSkin(child);
                        break;

                    case 'morph':
                        data.id = parseId(child.getAttribute('source'));
                        console.warn('THREE.ColladaLoader: Morph target animation not supported yet.');
                        break;

                }

            }

            library.controllers[xml.getAttribute('id')] = data;

        }

        function parseSkin(xml) {

            var data = {
                sources: {}
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'bind_shape_matrix':
                        data.bindShapeMatrix = parseFloats(child.textContent);
                        break;

                    case 'source':
                        var id = child.getAttribute('id');
                        data.sources[id] = parseSource(child);
                        break;

                    case 'joints':
                        data.joints = parseJoints(child);
                        break;

                    case 'vertex_weights':
                        data.vertexWeights = parseVertexWeights(child);
                        break;

                }

            }

            return data;

        }

        function parseJoints(xml) {

            var data = {
                inputs: {}
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'input':
                        var semantic = child.getAttribute('semantic');
                        var id = parseId(child.getAttribute('source'));
                        data.inputs[semantic] = id;
                        break;

                }

            }

            return data;

        }

        function parseVertexWeights(xml) {

            var data = {
                inputs: {}
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'input':
                        var semantic = child.getAttribute('semantic');
                        var id = parseId(child.getAttribute('source'));
                        var offset = parseInt(child.getAttribute('offset'));
                        data.inputs[semantic] = {id: id, offset: offset};
                        break;

                    case 'vcount':
                        data.vcount = parseInts(child.textContent);
                        break;

                    case 'v':
                        data.v = parseInts(child.textContent);
                        break;

                }

            }

            return data;

        }

        function buildController(data) {

            var build = {
                id: data.id
            };

            var geometry = library.geometries[build.id];

            if (data.skin !== undefined) {

                build.skin = buildSkin(data.skin);

                // we enhance the 'sources' property of the corresponding geometry with our skin data

                geometry.sources.skinIndices = build.skin.indices;
                geometry.sources.skinWeights = build.skin.weights;

            }

            return build;

        }

        function buildSkin(data) {

            var BONE_LIMIT = 4;

            var build = {
                joints: [], // this must be an array to preserve the joint order
                indices: {
                    array: [],
                    stride: BONE_LIMIT
                },
                weights: {
                    array: [],
                    stride: BONE_LIMIT
                }
            };

            var sources = data.sources;
            var vertexWeights = data.vertexWeights;

            var vcount = vertexWeights.vcount;
            var v = vertexWeights.v;
            var jointOffset = vertexWeights.inputs.JOINT.offset;
            var weightOffset = vertexWeights.inputs.WEIGHT.offset;

            var jointSource = data.sources[data.joints.inputs.JOINT];
            var inverseSource = data.sources[data.joints.inputs.INV_BIND_MATRIX];

            var weights = sources[vertexWeights.inputs.WEIGHT.id].array;
            var stride = 0;

            var i, j, l;

            // procces skin data for each vertex

            for (i = 0, l = vcount.length; i < l; i++) {

                var jointCount = vcount[i]; // this is the amount of joints that affect a single vertex
                var vertexSkinData = [];

                for (j = 0; j < jointCount; j++) {

                    var skinIndex = v[stride + jointOffset];
                    var weightId = v[stride + weightOffset];
                    var skinWeight = weights[weightId];

                    vertexSkinData.push({index: skinIndex, weight: skinWeight});

                    stride += 2;

                }

                // we sort the joints in descending order based on the weights.
                // this ensures, we only procced the most important joints of the vertex

                vertexSkinData.sort(descending);

                // now we provide for each vertex a set of four index and weight values.
                // the order of the skin data matches the order of vertices

                for (j = 0; j < BONE_LIMIT; j++) {

                    var d = vertexSkinData[j];

                    if (d !== undefined) {

                        build.indices.array.push(d.index);
                        build.weights.array.push(d.weight);

                    } else {

                        build.indices.array.push(0);
                        build.weights.array.push(0);

                    }

                }

            }

            // setup bind matrix

            if (data.bindShapeMatrix) {

                build.bindMatrix = new THREE.Matrix4().fromArray(data.bindShapeMatrix).transpose();

            } else {

                build.bindMatrix = new THREE.Matrix4().transpose();

            }

            // process bones and inverse bind matrix data

            for (i = 0, l = jointSource.array.length; i < l; i++) {

                var name = jointSource.array[i];
                var boneInverse = new THREE.Matrix4().fromArray(inverseSource.array, i * inverseSource.stride).transpose();

                build.joints.push({name: name, boneInverse: boneInverse});

            }

            return build;

            // array sort function

            function descending(a, b) {

                return b.weight - a.weight;

            }

        }

        function getController(id) {

            return getBuild(library.controllers[id], buildController);

        }

        // image

        function parseImage(xml) {

            var data = {
                init_from: getElementsByTagName(xml, 'init_from')[0].textContent
            };

            library.images[xml.getAttribute('id')] = data;

        }

        function buildImage(data) {

            if (data.build !== undefined) return data.build;

            return data.init_from;

        }

        function getImage(id) {

            var data = library.images[id];

            if (data !== undefined) {

                return getBuild(data, buildImage);

            }

            console.warn('THREE.ColladaLoader: Couldn\'t find image with ID:', id);

            return null;

        }

        // effect

        function parseEffect(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'profile_COMMON':
                        data.profile = parseEffectProfileCOMMON(child);
                        break;

                }

            }

            library.effects[xml.getAttribute('id')] = data;

        }

        function parseEffectProfileCOMMON(xml) {

            var data = {
                surfaces: {},
                samplers: {}
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'newparam':
                        parseEffectNewparam(child, data);
                        break;

                    case 'technique':
                        data.technique = parseEffectTechnique(child);
                        break;

                    case 'extra':
                        data.extra = parseEffectExtra(child);
                        break;

                }

            }

            return data;

        }

        function parseEffectNewparam(xml, data) {

            var sid = xml.getAttribute('sid');

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'surface':
                        data.surfaces[sid] = parseEffectSurface(child);
                        break;

                    case 'sampler2D':
                        data.samplers[sid] = parseEffectSampler(child);
                        break;

                }

            }

        }

        function parseEffectSurface(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'init_from':
                        data.init_from = child.textContent;
                        break;

                }

            }

            return data;

        }

        function parseEffectSampler(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'source':
                        data.source = child.textContent;
                        break;

                }

            }

            return data;

        }

        function parseEffectTechnique(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'constant':
                    case 'lambert':
                    case 'blinn':
                    case 'phong':
                        data.type = child.nodeName;
                        data.parameters = parseEffectParameters(child);
                        break;

                }

            }

            return data;

        }

        function parseEffectParameters(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'emission':
                    case 'diffuse':
                    case 'specular':
                    case 'shininess':
                    case 'transparency':
                        data[child.nodeName] = parseEffectParameter(child);
                        break;
                    case 'transparent':
                        data[child.nodeName] = {
                            opaque: child.getAttribute('opaque'),
                            data: parseEffectParameter(child)
                        };
                        break;

                }

            }

            return data;

        }

        function parseEffectParameter(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'color':
                        data[child.nodeName] = parseFloats(child.textContent);
                        break;

                    case 'float':
                        data[child.nodeName] = parseFloat(child.textContent);
                        break;

                    case 'texture':
                        data[child.nodeName] = {
                            id: child.getAttribute('texture'),
                            extra: parseEffectParameterTexture(child)
                        };
                        break;

                }

            }

            return data;

        }

        function parseEffectParameterTexture(xml) {

            var data = {
                technique: {}
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'extra':
                        parseEffectParameterTextureExtra(child, data);
                        break;

                }

            }

            return data;

        }

        function parseEffectParameterTextureExtra(xml, data) {

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'technique':
                        parseEffectParameterTextureExtraTechnique(child, data);
                        break;

                }

            }

        }

        function parseEffectParameterTextureExtraTechnique(xml, data) {

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'repeatU':
                    case 'repeatV':
                    case 'offsetU':
                    case 'offsetV':
                        data.technique[child.nodeName] = parseFloat(child.textContent);
                        break;

                    case 'wrapU':
                    case 'wrapV':

                        // some files have values for wrapU/wrapV which become NaN via parseInt

                        if (child.textContent.toUpperCase() === 'TRUE') {

                            data.technique[child.nodeName] = 1;

                        } else if (child.textContent.toUpperCase() === 'FALSE') {

                            data.technique[child.nodeName] = 0;

                        } else {

                            data.technique[child.nodeName] = parseInt(child.textContent);

                        }

                        break;

                }

            }

        }

        function parseEffectExtra(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'technique':
                        data.technique = parseEffectExtraTechnique(child);
                        break;

                }

            }

            return data;

        }

        function parseEffectExtraTechnique(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'double_sided':
                        data[child.nodeName] = parseInt(child.textContent);
                        break;

                }

            }

            return data;

        }

        function buildEffect(data) {

            return data;

        }

        function getEffect(id) {

            return getBuild(library.effects[id], buildEffect);

        }

        // material

        function parseMaterial(xml) {

            var data = {
                name: xml.getAttribute('name')
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'instance_effect':
                        data.url = parseId(child.getAttribute('url'));
                        break;

                }

            }

            library.materials[xml.getAttribute('id')] = data;

        }

        function buildMaterial(data) {

            var effect = getEffect(data.url);
            var technique = effect.profile.technique;
            var extra = effect.profile.extra;

            var material;

            switch (technique.type) {

                case 'phong':
                case 'blinn':
                    material = new THREE.MeshPhongMaterial();
                    break;

                case 'lambert':
                    material = new THREE.MeshLambertMaterial();
                    break;

                default:
                    material = new THREE.MeshBasicMaterial();
                    break;

            }

            material.name = data.name;

            function getTexture(textureObject) {

                var sampler = effect.profile.samplers[textureObject.id];
                var image = null;

                // get image

                if (sampler !== undefined) {

                    var surface = effect.profile.surfaces[sampler.source];
                    image = getImage(surface.init_from);

                } else {

                    console.warn('THREE.ColladaLoader: Undefined sampler. Access image directly (see #12530).');
                    image = getImage(textureObject.id);

                }

                // create texture if image is avaiable

                if (image !== null) {

                    var texture = textureLoader.load(image);

                    var extra = textureObject.extra;

                    if (extra !== undefined && extra.technique !== undefined && isEmpty(extra.technique) === false) {

                        var technique = extra.technique;

                        texture.wrapS = technique.wrapU ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;
                        texture.wrapT = technique.wrapV ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;

                        texture.offset.set(technique.offsetU || 0, technique.offsetV || 0);
                        texture.repeat.set(technique.repeatU || 1, technique.repeatV || 1);

                    } else {

                        texture.wrapS = THREE.RepeatWrapping;
                        texture.wrapT = THREE.RepeatWrapping;

                    }

                    return texture;

                } else {

                    console.warn('THREE.ColladaLoader: Couldn\'t create texture with ID:', textureObject.id);

                    return null;

                }

            }

            var parameters = technique.parameters;

            for (var key in parameters) {

                var parameter = parameters[key];

                switch (key) {

                    case 'diffuse':
                        if (parameter.color) material.color.fromArray(parameter.color);
                        if (parameter.texture) material.map = getTexture(parameter.texture);
                        break;
                    case 'specular':
                        if (parameter.color && material.specular) material.specular.fromArray(parameter.color);
                        if (parameter.texture) material.specularMap = getTexture(parameter.texture);
                        break;
                    case 'shininess':
                        if (parameter.float && material.shininess) material.shininess = parameter.float;
                        break;
                    case 'emission':
                        if (parameter.color && material.emissive) material.emissive.fromArray(parameter.color);
                        if (parameter.texture) material.emissiveMap = getTexture(parameter.texture);
                        break;

                }

            }

            //

            var transparent = parameters['transparent'];
            var transparency = parameters['transparency'];

            // <transparency> does not exist but <transparent>

            if (transparency === undefined && transparent) {

                transparency = {
                    float: 1
                };

            }

            // <transparent> does not exist but <transparency>

            if (transparent === undefined && transparency) {

                transparent = {
                    opaque: 'A_ONE',
                    data: {
                        color: [1, 1, 1, 1]
                    }
                };

            }

            if (transparent && transparency) {

                // handle case if a texture exists but no color

                if (transparent.data.texture) {

                    // we do not set an alpha map (see #13792)

                    material.transparent = true;

                } else {

                    var color = transparent.data.color;

                    switch (transparent.opaque) {

                        case 'A_ONE':
                            material.opacity = color[3] * transparency.float;
                            break;
                        case 'RGB_ZERO':
                            material.opacity = 1 - (color[0] * transparency.float);
                            break;
                        case 'A_ZERO':
                            material.opacity = 1 - (color[3] * transparency.float);
                            break;
                        case 'RGB_ONE':
                            material.opacity = color[0] * transparency.float;
                            break;
                        default:
                            console.warn('THREE.ColladaLoader: Invalid opaque type "%s" of transparent tag.', transparent.opaque);

                    }

                    if (material.opacity < 1) material.transparent = true;

                }

            }

            //

            if (extra !== undefined && extra.technique !== undefined && extra.technique.double_sided === 1) {

                material.side = THREE.DoubleSide;

            }

            return material;

        }

        function getMaterial(id) {

            return getBuild(library.materials[id], buildMaterial);

        }

        // camera

        function parseCamera(xml) {

            var data = {
                name: xml.getAttribute('name')
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'optics':
                        data.optics = parseCameraOptics(child);
                        break;

                }

            }

            library.cameras[xml.getAttribute('id')] = data;

        }

        function parseCameraOptics(xml) {

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                switch (child.nodeName) {

                    case 'technique_common':
                        return parseCameraTechnique(child);

                }

            }

            return {};

        }

        function parseCameraTechnique(xml) {

            var data = {};

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                switch (child.nodeName) {

                    case 'perspective':
                    case 'orthographic':

                        data.technique = child.nodeName;
                        data.parameters = parseCameraParameters(child);

                        break;

                }

            }

            return data;

        }

        function parseCameraParameters(xml) {

            var data = {};

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                switch (child.nodeName) {

                    case 'xfov':
                    case 'yfov':
                    case 'xmag':
                    case 'ymag':
                    case 'znear':
                    case 'zfar':
                    case 'aspect_ratio':
                        data[child.nodeName] = parseFloat(child.textContent);
                        break;

                }

            }

            return data;

        }

        function buildCamera(data) {

            var camera;

            switch (data.optics.technique) {

                case 'perspective':
                    camera = new THREE.PerspectiveCamera(
                        data.optics.parameters.yfov,
                        data.optics.parameters.aspect_ratio,
                        data.optics.parameters.znear,
                        data.optics.parameters.zfar
                    );
                    break;

                case 'orthographic':
                    var ymag = data.optics.parameters.ymag;
                    var xmag = data.optics.parameters.xmag;
                    var aspectRatio = data.optics.parameters.aspect_ratio;

                    xmag = (xmag === undefined) ? (ymag * aspectRatio) : xmag;
                    ymag = (ymag === undefined) ? (xmag / aspectRatio) : ymag;

                    xmag *= 0.5;
                    ymag *= 0.5;

                    camera = new THREE.OrthographicCamera(
                        -xmag, xmag, ymag, -ymag, // left, right, top, bottom
                        data.optics.parameters.znear,
                        data.optics.parameters.zfar
                    );
                    break;

                default:
                    camera = new THREE.PerspectiveCamera();
                    break;

            }

            camera.name = data.name;

            return camera;

        }

        function getCamera(id) {

            var data = library.cameras[id];

            if (data !== undefined) {

                return getBuild(data, buildCamera);

            }

            console.warn('THREE.ColladaLoader: Couldn\'t find camera with ID:', id);

            return null;

        }

        // light

        function parseLight(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'technique_common':
                        data = parseLightTechnique(child);
                        break;

                }

            }

            library.lights[xml.getAttribute('id')] = data;

        }

        function parseLightTechnique(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'directional':
                    case 'point':
                    case 'spot':
                    case 'ambient':

                        data.technique = child.nodeName;
                        data.parameters = parseLightParameters(child);

                }

            }

            return data;

        }

        function parseLightParameters(xml) {

            var data = {};

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'color':
                        var array = parseFloats(child.textContent);
                        data.color = new THREE.Color().fromArray(array);
                        break;

                    case 'falloff_angle':
                        data.falloffAngle = parseFloat(child.textContent);
                        break;

                    case 'quadratic_attenuation':
                        var f = parseFloat(child.textContent);
                        data.distance = f ? Math.sqrt(1 / f) : 0;
                        break;

                }

            }

            return data;

        }

        function buildLight(data) {

            var light;

            switch (data.technique) {

                case 'directional':
                    light = new THREE.DirectionalLight();
                    break;

                case 'point':
                    light = new THREE.PointLight();
                    break;

                case 'spot':
                    light = new THREE.SpotLight();
                    break;

                case 'ambient':
                    light = new THREE.AmbientLight();
                    break;

            }

            if (data.parameters.color) light.color.copy(data.parameters.color);
            if (data.parameters.distance) light.distance = data.parameters.distance;

            return light;

        }

        function getLight(id) {

            var data = library.lights[id];

            if (data !== undefined) {

                return getBuild(data, buildLight);

            }

            console.warn('THREE.ColladaLoader: Couldn\'t find light with ID:', id);

            return null;

        }

        // geometry

        function parseGeometry(xml) {

            var data = {
                name: xml.getAttribute('name'),
                sources: {},
                vertices: {},
                primitives: []
            };

            var mesh = getElementsByTagName(xml, 'mesh')[0];

            // the following tags inside geometry are not supported yet (see https://github.com/mrdoob/three.js/pull/12606): convex_mesh, spline, brep
            if (mesh === undefined) return;

            for (var i = 0; i < mesh.childNodes.length; i++) {

                var child = mesh.childNodes[i];

                if (child.nodeType !== 1) continue;

                var id = child.getAttribute('id');

                switch (child.nodeName) {

                    case 'source':
                        data.sources[id] = parseSource(child);
                        break;

                    case 'vertices':
                        // data.sources[ id ] = data.sources[ parseId( getElementsByTagName( child, 'input' )[ 0 ].getAttribute( 'source' ) ) ];
                        data.vertices = parseGeometryVertices(child);
                        break;

                    case 'polygons':
                        console.warn('THREE.ColladaLoader: Unsupported primitive type: ', child.nodeName);
                        break;

                    case 'lines':
                    case 'linestrips':
                    case 'polylist':
                    case 'triangles':
                        data.primitives.push(parseGeometryPrimitive(child));
                        break;

                    default:
                        console.log(child);

                }

            }

            library.geometries[xml.getAttribute('id')] = data;

        }

        function parseSource(xml) {

            var data = {
                array: [],
                stride: 3
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'float_array':
                        data.array = parseFloats(child.textContent);
                        break;

                    case 'Name_array':
                        data.array = parseStrings(child.textContent);
                        break;

                    case 'technique_common':
                        var accessor = getElementsByTagName(child, 'accessor')[0];

                        if (accessor !== undefined) {

                            data.stride = parseInt(accessor.getAttribute('stride'));

                        }
                        break;

                }

            }

            return data;

        }

        function parseGeometryVertices(xml) {

            var data = {};

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                data[child.getAttribute('semantic')] = parseId(child.getAttribute('source'));

            }

            return data;

        }

        function parseGeometryPrimitive(xml) {

            var primitive = {
                type: xml.nodeName,
                material: xml.getAttribute('material'),
                count: parseInt(xml.getAttribute('count')),
                inputs: {},
                stride: 0,
                hasUV: false
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'input':
                        var id = parseId(child.getAttribute('source'));
                        var semantic = child.getAttribute('semantic');
                        var offset = parseInt(child.getAttribute('offset'));
                        primitive.inputs[semantic] = {id: id, offset: offset};
                        primitive.stride = Math.max(primitive.stride, offset + 1);
                        if (semantic === 'TEXCOORD') primitive.hasUV = true;
                        break;

                    case 'vcount':
                        primitive.vcount = parseInts(child.textContent);
                        break;

                    case 'p':
                        primitive.p = parseInts(child.textContent);
                        break;

                }

            }

            return primitive;

        }

        function groupPrimitives(primitives) {

            var build = {};

            for (var i = 0; i < primitives.length; i++) {

                var primitive = primitives[i];

                if (build[primitive.type] === undefined) build[primitive.type] = [];

                build[primitive.type].push(primitive);

            }

            return build;

        }

        function checkUVCoordinates(primitives) {

            var count = 0;

            for (var i = 0, l = primitives.length; i < l; i++) {

                var primitive = primitives[i];

                if (primitive.hasUV === true) {

                    count++;

                }

            }

            if (count > 0 && count < primitives.length) {

                primitives.uvsNeedsFix = true;

            }

        }

        function buildGeometry(data) {

            var build = {};

            var sources = data.sources;
            var vertices = data.vertices;
            var primitives = data.primitives;

            if (primitives.length === 0) return {};

            // our goal is to create one buffer geometry for a single type of primitives
            // first, we group all primitives by their type

            var groupedPrimitives = groupPrimitives(primitives);

            for (var type in groupedPrimitives) {

                var primitiveType = groupedPrimitives[type];

                // second, ensure consistent uv coordinates for each type of primitives (polylist,triangles or lines)

                checkUVCoordinates(primitiveType);

                // third, create a buffer geometry for each type of primitives

                build[type] = buildGeometryType(primitiveType, sources, vertices);

            }

            return build;

        }

        function buildGeometryType(primitives, sources, vertices) {

            var build = {};

            var position = {array: [], stride: 0};
            var normal = {array: [], stride: 0};
            var uv = {array: [], stride: 0};
            var color = {array: [], stride: 0};

            var skinIndex = {array: [], stride: 4};
            var skinWeight = {array: [], stride: 4};

            var geometry = new THREE.BufferGeometry();

            var materialKeys = [];

            var start = 0;

            for (var p = 0; p < primitives.length; p++) {

                var primitive = primitives[p];
                var inputs = primitive.inputs;

                // groups

                var count = 0;

                switch (primitive.type) {

                    case 'lines':
                    case 'linestrips':
                        count = primitive.count * 2;
                        break;

                    case 'triangles':
                        count = primitive.count * 3;
                        break;

                    case 'polylist':

                        for (var g = 0; g < primitive.count; g++) {

                            var vc = primitive.vcount[g];

                            switch (vc) {

                                case 3:
                                    count += 3; // single triangle
                                    break;

                                case 4:
                                    count += 6; // quad, subdivided into two triangles
                                    break;

                                default:
                                    count += (vc - 2) * 3; // polylist with more than four vertices
                                    break;

                            }

                        }

                        break;

                    default:
                        console.warn('THREE.ColladaLoader: Unknow primitive type:', primitive.type);

                }

                geometry.addGroup(start, count, p);
                start += count;

                // material

                if (primitive.material) {

                    materialKeys.push(primitive.material);

                }

                // geometry data

                for (var name in inputs) {

                    var input = inputs[name];

                    switch (name) {

                        case 'VERTEX':
                            for (var key in vertices) {

                                var id = vertices[key];

                                switch (key) {

                                    case 'POSITION':
                                        var prevLength = position.array.length;
                                        buildGeometryData(primitive, sources[id], input.offset, position.array);
                                        position.stride = sources[id].stride;

                                        if (sources.skinWeights && sources.skinIndices) {

                                            buildGeometryData(primitive, sources.skinIndices, input.offset, skinIndex.array);
                                            buildGeometryData(primitive, sources.skinWeights, input.offset, skinWeight.array);

                                        }

                                        // see #3803

                                        if (primitive.hasUV === false && primitives.uvsNeedsFix === true) {

                                            var count = (position.array.length - prevLength) / position.stride;

                                            for (var i = 0; i < count; i++) {

                                                // fill missing uv coordinates

                                                uv.array.push(0, 0);

                                            }

                                        }
                                        break;

                                    case 'NORMAL':
                                        buildGeometryData(primitive, sources[id], input.offset, normal.array);
                                        normal.stride = sources[id].stride;
                                        break;

                                    case 'COLOR':
                                        buildGeometryData(primitive, sources[id], input.offset, color.array);
                                        color.stride = sources[id].stride;
                                        break;

                                    case 'TEXCOORD':
                                        buildGeometryData(primitive, sources[id], input.offset, uv.array);
                                        uv.stride = sources[id].stride;
                                        break;

                                    default:
                                        console.warn('THREE.ColladaLoader: Semantic "%s" not handled in geometry build process.', key);

                                }

                            }
                            break;

                        case 'NORMAL':
                            buildGeometryData(primitive, sources[input.id], input.offset, normal.array);
                            normal.stride = sources[input.id].stride;
                            break;

                        case 'COLOR':
                            buildGeometryData(primitive, sources[input.id], input.offset, color.array);
                            color.stride = sources[input.id].stride;
                            break;

                        case 'TEXCOORD':
                            buildGeometryData(primitive, sources[input.id], input.offset, uv.array);
                            uv.stride = sources[input.id].stride;
                            break;

                    }

                }

            }

            // build geometry

            if (position.array.length > 0) geometry.addAttribute('position', new THREE.Float32BufferAttribute(position.array, position.stride));
            if (normal.array.length > 0) geometry.addAttribute('normal', new THREE.Float32BufferAttribute(normal.array, normal.stride));
            if (color.array.length > 0) geometry.addAttribute('color', new THREE.Float32BufferAttribute(color.array, color.stride));
            if (uv.array.length > 0) geometry.addAttribute('uv', new THREE.Float32BufferAttribute(uv.array, uv.stride));

            if (skinIndex.array.length > 0) geometry.addAttribute('skinIndex', new THREE.Float32BufferAttribute(skinIndex.array, skinIndex.stride));
            if (skinWeight.array.length > 0) geometry.addAttribute('skinWeight', new THREE.Float32BufferAttribute(skinWeight.array, skinWeight.stride));

            build.data = geometry;
            build.type = primitives[0].type;
            build.materialKeys = materialKeys;

            return build;

        }

        function buildGeometryData(primitive, source, offset, array) {

            var indices = primitive.p;
            var stride = primitive.stride;
            var vcount = primitive.vcount;

            function pushVector(i) {

                var index = indices[i + offset] * sourceStride;
                var length = index + sourceStride;

                for (; index < length; index++) {

                    array.push(sourceArray[index]);

                }

            }

            var sourceArray = source.array;
            var sourceStride = source.stride;

            if (primitive.vcount !== undefined) {

                var index = 0;

                for (var i = 0, l = vcount.length; i < l; i++) {

                    var count = vcount[i];

                    if (count === 4) {

                        var a = index + stride * 0;
                        var b = index + stride * 1;
                        var c = index + stride * 2;
                        var d = index + stride * 3;

                        pushVector(a);
                        pushVector(b);
                        pushVector(d);
                        pushVector(b);
                        pushVector(c);
                        pushVector(d);

                    } else if (count === 3) {

                        var a = index + stride * 0;
                        var b = index + stride * 1;
                        var c = index + stride * 2;

                        pushVector(a);
                        pushVector(b);
                        pushVector(c);

                    } else if (count > 4) {

                        for (var k = 1, kl = (count - 2); k <= kl; k++) {

                            var a = index + stride * 0;
                            var b = index + stride * k;
                            var c = index + stride * (k + 1);

                            pushVector(a);
                            pushVector(b);
                            pushVector(c);

                        }

                    }

                    index += stride * count;

                }

            } else {

                for (var i = 0, l = indices.length; i < l; i += stride) {

                    pushVector(i);

                }

            }

        }

        function getGeometry(id) {

            return getBuild(library.geometries[id], buildGeometry);

        }

        // kinematics

        function parseKinematicsModel(xml) {

            var data = {
                name: xml.getAttribute('name') || '',
                joints: {},
                links: []
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'technique_common':
                        parseKinematicsTechniqueCommon(child, data);
                        break;

                }

            }

            library.kinematicsModels[xml.getAttribute('id')] = data;

        }

        function buildKinematicsModel(data) {

            if (data.build !== undefined) return data.build;

            return data;

        }

        function getKinematicsModel(id) {

            return getBuild(library.kinematicsModels[id], buildKinematicsModel);

        }

        function parseKinematicsTechniqueCommon(xml, data) {

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'joint':
                        data.joints[child.getAttribute('sid')] = parseKinematicsJoint(child);
                        break;

                    case 'link':
                        data.links.push(parseKinematicsLink(child));
                        break;

                }

            }

        }

        function parseKinematicsJoint(xml) {

            var data;

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'prismatic':
                    case 'revolute':
                        data = parseKinematicsJointParameter(child);
                        break;

                }

            }

            return data;

        }

        function parseKinematicsJointParameter(xml, data) {

            var data = {
                sid: xml.getAttribute('sid'),
                name: xml.getAttribute('name') || '',
                axis: new THREE.Vector3(),
                limits: {
                    min: 0,
                    max: 0
                },
                type: xml.nodeName,
                static: false,
                zeroPosition: 0,
                middlePosition: 0
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'axis':
                        var array = parseFloats(child.textContent);
                        data.axis.fromArray(array);
                        break;
                    case 'limits':
                        var max = child.getElementsByTagName('max')[0];
                        var min = child.getElementsByTagName('min')[0];

                        data.limits.max = parseFloat(max.textContent);
                        data.limits.min = parseFloat(min.textContent);
                        break;

                }

            }

            // if min is equal to or greater than max, consider the joint static

            if (data.limits.min >= data.limits.max) {

                data.static = true;

            }

            // calculate middle position

            data.middlePosition = (data.limits.min + data.limits.max) / 2.0;

            return data;

        }

        function parseKinematicsLink(xml) {

            var data = {
                sid: xml.getAttribute('sid'),
                name: xml.getAttribute('name') || '',
                attachments: [],
                transforms: []
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'attachment_full':
                        data.attachments.push(parseKinematicsAttachment(child));
                        break;

                    case 'matrix':
                    case 'translate':
                    case 'rotate':
                        data.transforms.push(parseKinematicsTransform(child));
                        break;

                }

            }

            return data;

        }

        function parseKinematicsAttachment(xml) {

            var data = {
                joint: xml.getAttribute('joint').split('/').pop(),
                transforms: [],
                links: []
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'link':
                        data.links.push(parseKinematicsLink(child));
                        break;

                    case 'matrix':
                    case 'translate':
                    case 'rotate':
                        data.transforms.push(parseKinematicsTransform(child));
                        break;

                }

            }

            return data;

        }

        function parseKinematicsTransform(xml) {

            var data = {
                type: xml.nodeName
            };

            var array = parseFloats(xml.textContent);

            switch (data.type) {

                case 'matrix':
                    data.obj = new THREE.Matrix4();
                    data.obj.fromArray(array).transpose();
                    break;

                case 'translate':
                    data.obj = new THREE.Vector3();
                    data.obj.fromArray(array);
                    break;

                case 'rotate':
                    data.obj = new THREE.Vector3();
                    data.obj.fromArray(array);
                    data.angle = THREE.Math.degToRad(array[3]);
                    break;

            }

            return data;

        }

        // physics

        function parsePhysicsModel(xml) {

            var data = {
                name: xml.getAttribute('name') || '',
                rigidBodies: {}
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'rigid_body':
                        data.rigidBodies[child.getAttribute('name')] = {};
                        parsePhysicsRigidBody(child, data.rigidBodies[child.getAttribute('name')]);
                        break;

                }

            }

            library.physicsModels[xml.getAttribute('id')] = data;

        }

        function parsePhysicsRigidBody(xml, data) {

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'technique_common':
                        parsePhysicsTechniqueCommon(child, data);
                        break;

                }

            }

        }

        function parsePhysicsTechniqueCommon(xml, data) {

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'inertia':
                        data.inertia = parseFloats(child.textContent);
                        break;

                    case 'mass':
                        data.mass = parseFloats(child.textContent)[0];
                        break;

                }

            }

        }

        // scene

        function parseKinematicsScene(xml) {

            var data = {
                bindJointAxis: []
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'bind_joint_axis':
                        data.bindJointAxis.push(parseKinematicsBindJointAxis(child));
                        break;

                }

            }

            library.kinematicsScenes[parseId(xml.getAttribute('url'))] = data;

        }

        function parseKinematicsBindJointAxis(xml) {

            var data = {
                target: xml.getAttribute('target').split('/').pop()
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'axis':
                        var param = child.getElementsByTagName('param')[0];
                        data.axis = param.textContent;
                        var tmpJointIndex = data.axis.split('inst_').pop().split('axis')[0];
                        data.jointIndex = tmpJointIndex.substr(0, tmpJointIndex.length - 1);
                        break;

                }

            }

            return data;

        }

        function buildKinematicsScene(data) {

            if (data.build !== undefined) return data.build;

            return data;

        }

        function getKinematicsScene(id) {

            return getBuild(library.kinematicsScenes[id], buildKinematicsScene);

        }

        function setupKinematics() {

            var kinematicsModelId = Object.keys(library.kinematicsModels)[0];
            var kinematicsSceneId = Object.keys(library.kinematicsScenes)[0];
            var visualSceneId = Object.keys(library.visualScenes)[0];

            if (kinematicsModelId === undefined || kinematicsSceneId === undefined) return;

            var kinematicsModel = getKinematicsModel(kinematicsModelId);
            var kinematicsScene = getKinematicsScene(kinematicsSceneId);
            var visualScene = getVisualScene(visualSceneId);

            var bindJointAxis = kinematicsScene.bindJointAxis;
            var jointMap = {};

            for (var i = 0, l = bindJointAxis.length; i < l; i++) {

                var axis = bindJointAxis[i];

                // the result of the following query is an element of type 'translate', 'rotate','scale' or 'matrix'

                var targetElement = collada.querySelector('[sid="' + axis.target + '"]');

                if (targetElement) {

                    // get the parent of the transfrom element

                    var parentVisualElement = targetElement.parentElement;

                    // connect the joint of the kinematics model with the element in the visual scene

                    connect(axis.jointIndex, parentVisualElement);

                }

            }

            function connect(jointIndex, visualElement) {

                var visualElementName = visualElement.getAttribute('name');
                var joint = kinematicsModel.joints[jointIndex];

                visualScene.traverse(function (object) {

                    if (object.name === visualElementName) {

                        jointMap[jointIndex] = {
                            object: object,
                            transforms: buildTransformList(visualElement),
                            joint: joint,
                            position: joint.zeroPosition
                        };

                    }

                });

            }

            var m0 = new THREE.Matrix4();

            kinematics = {

                joints: kinematicsModel && kinematicsModel.joints,

                getJointValue: function (jointIndex) {

                    var jointData = jointMap[jointIndex];

                    if (jointData) {

                        return jointData.position;

                    } else {

                        console.warn('THREE.ColladaLoader: Joint ' + jointIndex + ' doesn\'t exist.');

                    }

                },

                setJointValue: function (jointIndex, value) {

                    var jointData = jointMap[jointIndex];

                    if (jointData) {

                        var joint = jointData.joint;

                        if (value > joint.limits.max || value < joint.limits.min) {

                            console.warn('THREE.ColladaLoader: Joint ' + jointIndex + ' value ' + value + ' outside of limits (min: ' + joint.limits.min + ', max: ' + joint.limits.max + ').');

                        } else if (joint.static) {

                            console.warn('THREE.ColladaLoader: Joint ' + jointIndex + ' is static.');

                        } else {

                            var object = jointData.object;
                            var axis = joint.axis;
                            var transforms = jointData.transforms;

                            matrix.identity();

                            // each update, we have to apply all transforms in the correct order

                            for (var i = 0; i < transforms.length; i++) {

                                var transform = transforms[i];

                                // if there is a connection of the transform node with a joint, apply the joint value

                                if (transform.sid && transform.sid.indexOf(jointIndex) !== -1) {

                                    switch (joint.type) {

                                        case 'revolute':
                                            matrix.multiply(m0.makeRotationAxis(axis, THREE.Math.degToRad(value)));
                                            break;

                                        case 'prismatic':
                                            matrix.multiply(m0.makeTranslation(axis.x * value, axis.y * value, axis.z * value));
                                            break;

                                        default:
                                            console.warn('THREE.ColladaLoader: Unknown joint type: ' + joint.type);
                                            break;

                                    }

                                } else {

                                    switch (transform.type) {

                                        case 'matrix':
                                            matrix.multiply(transform.obj);
                                            break;

                                        case 'translate':
                                            matrix.multiply(m0.makeTranslation(transform.obj.x, transform.obj.y, transform.obj.z));
                                            break;

                                        case 'scale':
                                            matrix.scale(transform.obj);
                                            break;

                                        case 'rotate':
                                            matrix.multiply(m0.makeRotationAxis(transform.obj, transform.angle));
                                            break;

                                    }

                                }

                            }

                            object.matrix.copy(matrix);
                            object.matrix.decompose(object.position, object.quaternion, object.scale);

                            jointMap[jointIndex].position = value;

                        }

                    } else {

                        console.log('THREE.ColladaLoader: ' + jointIndex + ' does not exist.');

                    }

                }

            };

        }

        function buildTransformList(node) {

            var transforms = [];

            var xml = collada.querySelector('[id="' + node.id + '"]');

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'matrix':
                        var array = parseFloats(child.textContent);
                        var matrix = new THREE.Matrix4().fromArray(array).transpose();
                        transforms.push({
                            sid: child.getAttribute('sid'),
                            type: child.nodeName,
                            obj: matrix
                        });
                        break;

                    case 'translate':
                    case 'scale':
                        var array = parseFloats(child.textContent);
                        var vector = new THREE.Vector3().fromArray(array);
                        transforms.push({
                            sid: child.getAttribute('sid'),
                            type: child.nodeName,
                            obj: vector
                        });
                        break;

                    case 'rotate':
                        var array = parseFloats(child.textContent);
                        var vector = new THREE.Vector3().fromArray(array);
                        var angle = THREE.Math.degToRad(array[3]);
                        transforms.push({
                            sid: child.getAttribute('sid'),
                            type: child.nodeName,
                            obj: vector,
                            angle: angle
                        });
                        break;

                }

            }

            return transforms;

        }

        // nodes

        function prepareNodes(xml) {

            var elements = xml.getElementsByTagName('node');

            // ensure all node elements have id attributes

            for (var i = 0; i < elements.length; i++) {

                var element = elements[i];

                if (element.hasAttribute('id') === false) {

                    element.setAttribute('id', generateId());

                }

            }

        }

        var matrix = new THREE.Matrix4();
        var vector = new THREE.Vector3();

        function parseNode(xml) {

            var data = {
                name: xml.getAttribute('name') || '',
                type: xml.getAttribute('type'),
                id: xml.getAttribute('id'),
                sid: xml.getAttribute('sid'),
                matrix: new THREE.Matrix4(),
                nodes: [],
                instanceCameras: [],
                instanceControllers: [],
                instanceLights: [],
                instanceGeometries: [],
                instanceNodes: [],
                transforms: {}
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'node':
                        data.nodes.push(child.getAttribute('id'));
                        parseNode(child);
                        break;

                    case 'instance_camera':
                        data.instanceCameras.push(parseId(child.getAttribute('url')));
                        break;

                    case 'instance_controller':
                        data.instanceControllers.push(parseNodeInstance(child));
                        break;

                    case 'instance_light':
                        data.instanceLights.push(parseId(child.getAttribute('url')));
                        break;

                    case 'instance_geometry':
                        data.instanceGeometries.push(parseNodeInstance(child));
                        break;

                    case 'instance_node':
                        data.instanceNodes.push(parseId(child.getAttribute('url')));
                        break;

                    case 'matrix':
                        var array = parseFloats(child.textContent);
                        data.matrix.multiply(matrix.fromArray(array).transpose());
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'translate':
                        var array = parseFloats(child.textContent);
                        vector.fromArray(array);
                        data.matrix.multiply(matrix.makeTranslation(vector.x, vector.y, vector.z));
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'rotate':
                        var array = parseFloats(child.textContent);
                        var angle = THREE.Math.degToRad(array[3]);
                        data.matrix.multiply(matrix.makeRotationAxis(vector.fromArray(array), angle));
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'scale':
                        var array = parseFloats(child.textContent);
                        data.matrix.scale(vector.fromArray(array));
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'extra':
                        break;

                    default:
                        console.log(child);

                }

            }

            library.nodes[data.id] = data;

            return data;

        }

        function parseNodeInstance(xml) {

            var data = {
                id: parseId(xml.getAttribute('url')),
                materials: {},
                skeletons: []
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                switch (child.nodeName) {

                    case 'bind_material':
                        var instances = child.getElementsByTagName('instance_material');

                        for (var j = 0; j < instances.length; j++) {

                            var instance = instances[j];
                            var symbol = instance.getAttribute('symbol');
                            var target = instance.getAttribute('target');

                            data.materials[symbol] = parseId(target);

                        }

                        break;

                    case 'skeleton':
                        data.skeletons.push(parseId(child.textContent));
                        break;

                    default:
                        break;

                }

            }

            return data;

        }

        function buildSkeleton(skeletons, joints) {

            var boneData = [];
            var sortedBoneData = [];

            var i, j, data;

            // a skeleton can have multiple root bones. collada expresses this
            // situtation with multiple "skeleton" tags per controller instance

            for (i = 0; i < skeletons.length; i++) {

                var skeleton = skeletons[i];

                var root;

                if (hasNode(skeleton)) {

                    root = getNode(skeleton);
                    buildBoneHierarchy(root, joints, boneData);

                } else if (hasVisualScene(skeleton)) {

                    // handle case where the skeleton refers to the visual scene (#13335)

                    var visualScene = library.visualScenes[skeleton];
                    var children = visualScene.children;

                    for (var j = 0; j < children.length; j++) {

                        var child = children[j];

                        if (child.type === 'JOINT') {

                            var root = getNode(child.id);
                            buildBoneHierarchy(root, joints, boneData);

                        }

                    }

                } else {

                    console.error('THREE.ColladaLoader: Unable to find root bone of skeleton with ID:', skeleton);

                }

            }

            // sort bone data (the order is defined in the corresponding controller)

            for (i = 0; i < joints.length; i++) {

                for (j = 0; j < boneData.length; j++) {

                    data = boneData[j];

                    if (data.bone.name === joints[i].name) {

                        sortedBoneData[i] = data;
                        data.processed = true;
                        break;

                    }

                }

            }

            // add unprocessed bone data at the end of the list

            for (i = 0; i < boneData.length; i++) {

                data = boneData[i];

                if (data.processed === false) {

                    sortedBoneData.push(data);
                    data.processed = true;

                }

            }

            // setup arrays for skeleton creation

            var bones = [];
            var boneInverses = [];

            for (i = 0; i < sortedBoneData.length; i++) {

                data = sortedBoneData[i];

                bones.push(data.bone);
                boneInverses.push(data.boneInverse);

            }

            return new THREE.Skeleton(bones, boneInverses);

        }

        function buildBoneHierarchy(root, joints, boneData) {

            // setup bone data from visual scene

            root.traverse(function (object) {

                if (object.isBone === true) {

                    var boneInverse;

                    // retrieve the boneInverse from the controller data

                    for (var i = 0; i < joints.length; i++) {

                        var joint = joints[i];

                        if (joint.name === object.name) {

                            boneInverse = joint.boneInverse;
                            break;

                        }

                    }

                    if (boneInverse === undefined) {

                        // Unfortunately, there can be joints in the visual scene that are not part of the
                        // corresponding controller. In this case, we have to create a dummy boneInverse matrix
                        // for the respective bone. This bone won't affect any vertices, because there are no skin indices
                        // and weights defined for it. But we still have to add the bone to the sorted bone list in order to
                        // ensure a correct animation of the model.

                        boneInverse = new THREE.Matrix4();

                    }

                    boneData.push({bone: object, boneInverse: boneInverse, processed: false});

                }

            });

        }

        function buildNode(data) {

            var objects = [];

            var matrix = data.matrix;
            var nodes = data.nodes;
            var type = data.type;
            var instanceCameras = data.instanceCameras;
            var instanceControllers = data.instanceControllers;
            var instanceLights = data.instanceLights;
            var instanceGeometries = data.instanceGeometries;
            var instanceNodes = data.instanceNodes;

            // nodes

            for (var i = 0, l = nodes.length; i < l; i++) {

                objects.push(getNode(nodes[i]));

            }

            // instance cameras

            for (var i = 0, l = instanceCameras.length; i < l; i++) {

                var instanceCamera = getCamera(instanceCameras[i]);

                if (instanceCamera !== null) {

                    objects.push(instanceCamera.clone());

                }

            }

            // instance controllers

            for (var i = 0, l = instanceControllers.length; i < l; i++) {

                var instance = instanceControllers[i];
                var controller = getController(instance.id);
                var geometries = getGeometry(controller.id);
                var newObjects = buildObjects(geometries, instance.materials);

                var skeletons = instance.skeletons;
                var joints = controller.skin.joints;

                var skeleton = buildSkeleton(skeletons, joints);

                for (var j = 0, jl = newObjects.length; j < jl; j++) {

                    var object = newObjects[j];

                    if (object.isSkinnedMesh) {

                        object.bind(skeleton, controller.skin.bindMatrix);
                        object.normalizeSkinWeights();

                    }

                    objects.push(object);

                }

            }

            // instance lights

            for (var i = 0, l = instanceLights.length; i < l; i++) {

                var instanceLight = getLight(instanceLights[i]);

                if (instanceLight !== null) {

                    objects.push(instanceLight.clone());

                }

            }

            // instance geometries

            for (var i = 0, l = instanceGeometries.length; i < l; i++) {

                var instance = instanceGeometries[i];

                // a single geometry instance in collada can lead to multiple object3Ds.
                // this is the case when primitives are combined like triangles and lines

                var geometries = getGeometry(instance.id);
                var newObjects = buildObjects(geometries, instance.materials);

                for (var j = 0, jl = newObjects.length; j < jl; j++) {

                    objects.push(newObjects[j]);

                }

            }

            // instance nodes

            for (var i = 0, l = instanceNodes.length; i < l; i++) {

                objects.push(getNode(instanceNodes[i]).clone());

            }

            var object;

            if (nodes.length === 0 && objects.length === 1) {

                object = objects[0];

            } else {

                object = (type === 'JOINT') ? new THREE.Bone() : new THREE.Group();

                for (var i = 0; i < objects.length; i++) {

                    object.add(objects[i]);

                }

            }

            if (object.name === '') {

                object.name = (type === 'JOINT') ? data.sid : data.name;

            }

            object.matrix.copy(matrix);
            object.matrix.decompose(object.position, object.quaternion, object.scale);

            return object;

        }

        function resolveMaterialBinding(keys, instanceMaterials) {

            var materials = [];

            for (var i = 0, l = keys.length; i < l; i++) {

                var id = instanceMaterials[keys[i]];
                materials.push(getMaterial(id));

            }

            return materials;

        }

        function buildObjects(geometries, instanceMaterials) {

            var objects = [];

            for (var type in geometries) {

                var geometry = geometries[type];

                var materials = resolveMaterialBinding(geometry.materialKeys, instanceMaterials);

                // handle case if no materials are defined

                if (materials.length === 0) {

                    if (type === 'lines' || type === 'linestrips') {

                        materials.push(new THREE.LineBasicMaterial());

                    } else {

                        materials.push(new THREE.MeshPhongMaterial());

                    }

                }

                // regard skinning

                var skinning = (geometry.data.attributes.skinIndex !== undefined);

                if (skinning) {

                    for (var i = 0, l = materials.length; i < l; i++) {

                        materials[i].skinning = true;

                    }

                }

                // choose between a single or multi materials (material array)

                var material = (materials.length === 1) ? materials[0] : materials;

                // now create a specific 3D object

                var object;

                switch (type) {

                    case 'lines':
                        object = new THREE.LineSegments(geometry.data, material);
                        break;

                    case 'linestrips':
                        object = new THREE.Line(geometry.data, material);
                        break;

                    case 'triangles':
                    case 'polylist':
                        if (skinning) {

                            object = new THREE.SkinnedMesh(geometry.data, material);

                        } else {

                            object = new THREE.Mesh(geometry.data, material);

                        }
                        break;

                }

                objects.push(object);

            }

            return objects;

        }

        function hasNode(id) {

            return library.nodes[id] !== undefined;

        }

        function getNode(id) {

            return getBuild(library.nodes[id], buildNode);

        }

        // visual scenes

        function parseVisualScene(xml) {

            var data = {
                name: xml.getAttribute('name'),
                children: []
            };

            prepareNodes(xml);

            var elements = getElementsByTagName(xml, 'node');

            for (var i = 0; i < elements.length; i++) {

                data.children.push(parseNode(elements[i]));

            }

            library.visualScenes[xml.getAttribute('id')] = data;

        }

        function buildVisualScene(data) {

            var group = new THREE.Group();
            group.name = data.name;

            var children = data.children;

            for (var i = 0; i < children.length; i++) {

                var child = children[i];

                group.add(getNode(child.id));

            }

            return group;

        }

        function hasVisualScene(id) {

            return library.visualScenes[id] !== undefined;

        }

        function getVisualScene(id) {

            return getBuild(library.visualScenes[id], buildVisualScene);

        }

        // scenes

        function parseScene(xml) {

            var instance = getElementsByTagName(xml, 'instance_visual_scene')[0];
            return getVisualScene(parseId(instance.getAttribute('url')));

        }

        function setupAnimations() {

            var clips = library.clips;

            if (isEmpty(clips) === true) {

                if (isEmpty(library.animations) === false) {

                    // if there are animations but no clips, we create a default clip for playback

                    var tracks = [];

                    for (var id in library.animations) {

                        var animationTracks = getAnimation(id);

                        for (var i = 0, l = animationTracks.length; i < l; i++) {

                            tracks.push(animationTracks[i]);

                        }

                    }

                    animations.push(new THREE.AnimationClip('default', -1, tracks));

                }

            } else {

                for (var id in clips) {

                    animations.push(getAnimationClip(id));

                }

            }

        }

        if (text.length === 0) {

            return {scene: new THREE.Scene()};

        }

        var xml = new DOMParser().parseFromString(text, 'application/xml');

        var collada = getElementsByTagName(xml, 'COLLADA')[0];

        // metadata

        var version = collada.getAttribute('version');
        console.log('THREE.ColladaLoader: File version', version);

        var asset = parseAsset(getElementsByTagName(collada, 'asset')[0]);
        var textureLoader = new THREE.TextureLoader(this.manager);
        textureLoader.setPath(path).setCrossOrigin(this.crossOrigin);

        //

        var animations = [];
        var kinematics = {};
        var count = 0;

        //

        var library = {
            animations: {},
            clips: {},
            controllers: {},
            images: {},
            effects: {},
            materials: {},
            cameras: {},
            lights: {},
            geometries: {},
            nodes: {},
            visualScenes: {},
            kinematicsModels: {},
            physicsModels: {},
            kinematicsScenes: {}
        };

        parseLibrary(collada, 'library_animations', 'animation', parseAnimation);
        parseLibrary(collada, 'library_animation_clips', 'animation_clip', parseAnimationClip);
        parseLibrary(collada, 'library_controllers', 'controller', parseController);
        parseLibrary(collada, 'library_images', 'image', parseImage);
        parseLibrary(collada, 'library_effects', 'effect', parseEffect);
        parseLibrary(collada, 'library_materials', 'material', parseMaterial);
        parseLibrary(collada, 'library_cameras', 'camera', parseCamera);
        parseLibrary(collada, 'library_lights', 'light', parseLight);
        parseLibrary(collada, 'library_geometries', 'geometry', parseGeometry);
        parseLibrary(collada, 'library_nodes', 'node', parseNode);
        parseLibrary(collada, 'library_visual_scenes', 'visual_scene', parseVisualScene);
        parseLibrary(collada, 'library_kinematics_models', 'kinematics_model', parseKinematicsModel);
        parseLibrary(collada, 'library_physics_models', 'physics_model', parsePhysicsModel);
        parseLibrary(collada, 'scene', 'instance_kinematics_scene', parseKinematicsScene);

        buildLibrary(library.animations, buildAnimation);
        buildLibrary(library.clips, buildAnimationClip);
        buildLibrary(library.controllers, buildController);
        buildLibrary(library.images, buildImage);
        buildLibrary(library.effects, buildEffect);
        buildLibrary(library.materials, buildMaterial);
        buildLibrary(library.cameras, buildCamera);
        buildLibrary(library.lights, buildLight);
        buildLibrary(library.geometries, buildGeometry);
        buildLibrary(library.visualScenes, buildVisualScene);

        setupAnimations();
        setupKinematics();

        var scene = parseScene(getElementsByTagName(collada, 'scene')[0]);

        if (asset.upAxis === 'Z_UP') {

            scene.quaternion.setFromEuler(new THREE.Euler(-Math.PI / 2, 0, 0));

        }

        scene.scale.multiplyScalar(asset.unit);

        return {
            animations: animations,
            kinematics: kinematics,
            library: library,
            scene: scene
        };

    }

};


/**
 * @author Rich Tibbett / https://github.com/richtr
 * @author mrdoob / http://mrdoob.com/
 * @author Tony Parisi / http://www.tonyparisi.com/
 * @author Takahiro / https://github.com/takahirox
 * @author Don McCurdy / https://www.donmccurdy.com
 */

THREE.GLTFLoader = (function () {

    function GLTFLoader(manager) {

        this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;
        this.dracoLoader = null;

    }

    GLTFLoader.prototype = {

        constructor: GLTFLoader,

        crossOrigin: 'anonymous',

        load: function (url, onLoad, onProgress, onError) {

            var scope = this;

            var path = this.path !== undefined ? this.path : THREE.LoaderUtils.extractUrlBase(url);

            var loader = new THREE.FileLoader(scope.manager);

            loader.setResponseType('arraybuffer');

            loader.load(url, function (data) {

                try {

                    scope.parse(data, path, onLoad, onError);

                } catch (e) {

                    if (onError !== undefined) {

                        onError(e);

                    } else {

                        throw e;

                    }

                }

            }, onProgress, onError);

        },

        setCrossOrigin: function (value) {

            this.crossOrigin = value;
            return this;

        },

        setPath: function (value) {

            this.path = value;
            return this;

        },

        setDRACOLoader: function (dracoLoader) {

            this.dracoLoader = dracoLoader;
            return this;

        },

        parse: function (data, path, onLoad, onError) {

            var content;
            var extensions = {};

            if (typeof data === 'string') {

                content = data;

            } else {

                var magic = THREE.LoaderUtils.decodeText(new Uint8Array(data, 0, 4));

                if (magic === BINARY_EXTENSION_HEADER_MAGIC) {

                    try {

                        extensions[EXTENSIONS.KHR_BINARY_GLTF] = new GLTFBinaryExtension(data);

                    } catch (error) {

                        if (onError) onError(error);
                        return;

                    }

                    content = extensions[EXTENSIONS.KHR_BINARY_GLTF].content;

                } else {

                    content = THREE.LoaderUtils.decodeText(new Uint8Array(data));

                }

            }

            var json = JSON.parse(content);

            if (json.asset === undefined || json.asset.version[0] < 2) {

                if (onError) onError(new Error('THREE.GLTFLoader: Unsupported asset. glTF versions >=2.0 are supported. Use LegacyGLTFLoader instead.'));
                return;

            }

            if (json.extensionsUsed) {

                for (var i = 0; i < json.extensionsUsed.length; ++i) {

                    var extensionName = json.extensionsUsed[i];
                    var extensionsRequired = json.extensionsRequired || [];

                    switch (extensionName) {

                        case EXTENSIONS.KHR_LIGHTS:
                            extensions[extensionName] = new GLTFLightsExtension(json);
                            break;

                        case EXTENSIONS.KHR_MATERIALS_UNLIT:
                            extensions[extensionName] = new GLTFMaterialsUnlitExtension(json);
                            break;

                        case EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS:
                            extensions[extensionName] = new GLTFMaterialsPbrSpecularGlossinessExtension();
                            break;

                        case EXTENSIONS.KHR_DRACO_MESH_COMPRESSION:
                            extensions[extensionName] = new GLTFDracoMeshCompressionExtension(json, this.dracoLoader);
                            break;

                        case EXTENSIONS.MSFT_TEXTURE_DDS:
                            extensions[EXTENSIONS.MSFT_TEXTURE_DDS] = new GLTFTextureDDSExtension();
                            break;

                        default:

                            if (extensionsRequired.indexOf(extensionName) >= 0) {

                                console.warn('THREE.GLTFLoader: Unknown extension "' + extensionName + '".');

                            }

                    }

                }

            }

            var parser = new GLTFParser(json, extensions, {

                path: path || this.path || '',
                crossOrigin: this.crossOrigin,
                manager: this.manager

            });

            parser.parse(function (scene, scenes, cameras, animations, json) {

                var glTF = {
                    scene: scene,
                    scenes: scenes,
                    cameras: cameras,
                    animations: animations,
                    asset: json.asset,
                    parser: parser,
                    userData: {}
                };

                addUnknownExtensionsToUserData(extensions, glTF, json);

                onLoad(glTF);

            }, onError);

        }

    };

    /* GLTFREGISTRY */

    function GLTFRegistry() {

        var objects = {};

        return {

            get: function (key) {

                return objects[key];

            },

            add: function (key, object) {

                objects[key] = object;

            },

            remove: function (key) {

                delete objects[key];

            },

            removeAll: function () {

                objects = {};

            }

        };

    }

    /*********************************/
    /********** EXTENSIONS ***********/
    /*********************************/

    var EXTENSIONS = {
        KHR_BINARY_GLTF: 'KHR_binary_glTF',
        KHR_DRACO_MESH_COMPRESSION: 'KHR_draco_mesh_compression',
        KHR_LIGHTS: 'KHR_lights',
        KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS: 'KHR_materials_pbrSpecularGlossiness',
        KHR_MATERIALS_UNLIT: 'KHR_materials_unlit',
        MSFT_TEXTURE_DDS: 'MSFT_texture_dds'
    };

    /**
     * DDS Texture Extension
     *
     * Specification:
     * https://github.com/KhronosGroup/glTF/tree/master/extensions/2.0/Vendor/MSFT_texture_dds
     *
     */
    function GLTFTextureDDSExtension() {

        if (!THREE.DDSLoader) {

            throw new Error('THREE.GLTFLoader: Attempting to load .dds texture without importing THREE.DDSLoader');

        }

        this.name = EXTENSIONS.MSFT_TEXTURE_DDS;
        this.ddsLoader = new THREE.DDSLoader();

    }

    /**
     * Lights Extension
     *
     * Specification: PENDING
     */
    function GLTFLightsExtension(json) {

        this.name = EXTENSIONS.KHR_LIGHTS;

        this.lights = {};

        var extension = (json.extensions && json.extensions[EXTENSIONS.KHR_LIGHTS]) || {};
        var lights = extension.lights || {};

        for (var lightId in lights) {

            var light = lights[lightId];
            var lightNode;

            var color = new THREE.Color().fromArray(light.color);

            switch (light.type) {

                case 'directional':
                    lightNode = new THREE.DirectionalLight(color);
                    lightNode.target.position.set(0, 0, 1);
                    lightNode.add(lightNode.target);
                    break;

                case 'point':
                    lightNode = new THREE.PointLight(color);
                    break;

                case 'spot':
                    lightNode = new THREE.SpotLight(color);
                    // Handle spotlight properties.
                    light.spot = light.spot || {};
                    light.spot.innerConeAngle = light.spot.innerConeAngle !== undefined ? light.spot.innerConeAngle : 0;
                    light.spot.outerConeAngle = light.spot.outerConeAngle !== undefined ? light.spot.outerConeAngle : Math.PI / 4.0;
                    lightNode.angle = light.spot.outerConeAngle;
                    lightNode.penumbra = 1.0 - light.spot.innerConeAngle / light.spot.outerConeAngle;
                    lightNode.target.position.set(0, 0, 1);
                    lightNode.add(lightNode.target);
                    break;

                case 'ambient':
                    lightNode = new THREE.AmbientLight(color);
                    break;

            }

            if (lightNode) {

                lightNode.decay = 2;

                if (light.intensity !== undefined) {

                    lightNode.intensity = light.intensity;

                }

                lightNode.name = light.name || ('light_' + lightId);
                this.lights[lightId] = lightNode;

            }

        }

    }

    /**
     * Unlit Materials Extension (pending)
     *
     * PR: https://github.com/KhronosGroup/glTF/pull/1163
     */
    function GLTFMaterialsUnlitExtension(json) {

        this.name = EXTENSIONS.KHR_MATERIALS_UNLIT;

    }

    GLTFMaterialsUnlitExtension.prototype.getMaterialType = function (material) {

        return THREE.MeshBasicMaterial;

    };

    GLTFMaterialsUnlitExtension.prototype.extendParams = function (materialParams, material, parser) {

        var pending = [];

        materialParams.color = new THREE.Color(1.0, 1.0, 1.0);
        materialParams.opacity = 1.0;

        var metallicRoughness = material.pbrMetallicRoughness;

        if (metallicRoughness) {

            if (Array.isArray(metallicRoughness.baseColorFactor)) {

                var array = metallicRoughness.baseColorFactor;

                materialParams.color.fromArray(array);
                materialParams.opacity = array[3];

            }

            if (metallicRoughness.baseColorTexture !== undefined) {

                pending.push(parser.assignTexture(materialParams, 'map', metallicRoughness.baseColorTexture.index));

            }

        }

        return Promise.all(pending);

    };

    /* BINARY EXTENSION */

    var BINARY_EXTENSION_BUFFER_NAME = 'binary_glTF';
    var BINARY_EXTENSION_HEADER_MAGIC = 'glTF';
    var BINARY_EXTENSION_HEADER_LENGTH = 12;
    var BINARY_EXTENSION_CHUNK_TYPES = {JSON: 0x4E4F534A, BIN: 0x004E4942};

    function GLTFBinaryExtension(data) {

        this.name = EXTENSIONS.KHR_BINARY_GLTF;
        this.content = null;
        this.body = null;

        var headerView = new DataView(data, 0, BINARY_EXTENSION_HEADER_LENGTH);

        this.header = {
            magic: THREE.LoaderUtils.decodeText(new Uint8Array(data.slice(0, 4))),
            version: headerView.getUint32(4, true),
            length: headerView.getUint32(8, true)
        };

        if (this.header.magic !== BINARY_EXTENSION_HEADER_MAGIC) {

            throw new Error('THREE.GLTFLoader: Unsupported glTF-Binary header.');

        } else if (this.header.version < 2.0) {

            throw new Error('THREE.GLTFLoader: Legacy binary file detected. Use LegacyGLTFLoader instead.');

        }

        var chunkView = new DataView(data, BINARY_EXTENSION_HEADER_LENGTH);
        var chunkIndex = 0;

        while (chunkIndex < chunkView.byteLength) {

            var chunkLength = chunkView.getUint32(chunkIndex, true);
            chunkIndex += 4;

            var chunkType = chunkView.getUint32(chunkIndex, true);
            chunkIndex += 4;

            if (chunkType === BINARY_EXTENSION_CHUNK_TYPES.JSON) {

                var contentArray = new Uint8Array(data, BINARY_EXTENSION_HEADER_LENGTH + chunkIndex, chunkLength);
                this.content = THREE.LoaderUtils.decodeText(contentArray);

            } else if (chunkType === BINARY_EXTENSION_CHUNK_TYPES.BIN) {

                var byteOffset = BINARY_EXTENSION_HEADER_LENGTH + chunkIndex;
                this.body = data.slice(byteOffset, byteOffset + chunkLength);

            }

            // Clients must ignore chunks with unknown types.

            chunkIndex += chunkLength;

        }

        if (this.content === null) {

            throw new Error('THREE.GLTFLoader: JSON content not found.');

        }

    }

    /**
     * DRACO Mesh Compression Extension
     *
     * Specification: https://github.com/KhronosGroup/glTF/pull/874
     */
    function GLTFDracoMeshCompressionExtension(json, dracoLoader) {

        if (!dracoLoader) {

            throw new Error('THREE.GLTFLoader: No DRACOLoader instance provided.');

        }

        this.name = EXTENSIONS.KHR_DRACO_MESH_COMPRESSION;
        this.json = json;
        this.dracoLoader = dracoLoader;

    }

    GLTFDracoMeshCompressionExtension.prototype.decodePrimitive = function (primitive, parser) {

        var json = this.json;
        var dracoLoader = this.dracoLoader;
        var bufferViewIndex = primitive.extensions[this.name].bufferView;
        var gltfAttributeMap = primitive.extensions[this.name].attributes;
        var threeAttributeMap = {};
        var attributeNormalizedMap = {};
        var attributeTypeMap = {};

        for (var attributeName in gltfAttributeMap) {

            if (!(attributeName in ATTRIBUTES)) continue;

            threeAttributeMap[ATTRIBUTES[attributeName]] = gltfAttributeMap[attributeName];

        }

        for (attributeName in primitive.attributes) {

            if (ATTRIBUTES[attributeName] !== undefined && gltfAttributeMap[attributeName] !== undefined) {

                var accessorDef = json.accessors[primitive.attributes[attributeName]];
                var componentType = WEBGL_COMPONENT_TYPES[accessorDef.componentType];

                attributeTypeMap[ATTRIBUTES[attributeName]] = componentType;
                attributeNormalizedMap[ATTRIBUTES[attributeName]] = accessorDef.normalized === true;

            }

        }

        return parser.getDependency('bufferView', bufferViewIndex).then(function (bufferView) {

            return new Promise(function (resolve) {

                dracoLoader.decodeDracoFile(bufferView, function (geometry) {

                    for (var attributeName in geometry.attributes) {

                        var attribute = geometry.attributes[attributeName];
                        var normalized = attributeNormalizedMap[attributeName];

                        if (normalized !== undefined) attribute.normalized = normalized;

                    }

                    resolve(geometry);

                }, threeAttributeMap, attributeTypeMap);

            });

        });

    };

    /**
     * Specular-Glossiness Extension
     *
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/extensions/2.0/Khronos/KHR_materials_pbrSpecularGlossiness
     */
    function GLTFMaterialsPbrSpecularGlossinessExtension() {

        return {

            name: EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS,

            specularGlossinessParams: [
                'color',
                'map',
                'lightMap',
                'lightMapIntensity',
                'aoMap',
                'aoMapIntensity',
                'emissive',
                'emissiveIntensity',
                'emissiveMap',
                'bumpMap',
                'bumpScale',
                'normalMap',
                'displacementMap',
                'displacementScale',
                'displacementBias',
                'specularMap',
                'specular',
                'glossinessMap',
                'glossiness',
                'alphaMap',
                'envMap',
                'envMapIntensity',
                'refractionRatio',
            ],

            getMaterialType: function () {

                return THREE.ShaderMaterial;

            },

            extendParams: function (params, material, parser) {

                var pbrSpecularGlossiness = material.extensions[this.name];

                var shader = THREE.ShaderLib['standard'];

                var uniforms = THREE.UniformsUtils.clone(shader.uniforms);

                var specularMapParsFragmentChunk = [
                    '#ifdef USE_SPECULARMAP',
                    '	uniform sampler2D specularMap;',
                    '#endif'
                ].join('\n');

                var glossinessMapParsFragmentChunk = [
                    '#ifdef USE_GLOSSINESSMAP',
                    '	uniform sampler2D glossinessMap;',
                    '#endif'
                ].join('\n');

                var specularMapFragmentChunk = [
                    'vec3 specularFactor = specular;',
                    '#ifdef USE_SPECULARMAP',
                    '	vec4 texelSpecular = texture2D( specularMap, vUv );',
                    '	texelSpecular = sRGBToLinear( texelSpecular );',
                    '	// reads channel RGB, compatible with a glTF Specular-Glossiness (RGBA) texture',
                    '	specularFactor *= texelSpecular.rgb;',
                    '#endif'
                ].join('\n');

                var glossinessMapFragmentChunk = [
                    'float glossinessFactor = glossiness;',
                    '#ifdef USE_GLOSSINESSMAP',
                    '	vec4 texelGlossiness = texture2D( glossinessMap, vUv );',
                    '	// reads channel A, compatible with a glTF Specular-Glossiness (RGBA) texture',
                    '	glossinessFactor *= texelGlossiness.a;',
                    '#endif'
                ].join('\n');

                var lightPhysicalFragmentChunk = [
                    'PhysicalMaterial material;',
                    'material.diffuseColor = diffuseColor.rgb;',
                    'material.specularRoughness = clamp( 1.0 - glossinessFactor, 0.04, 1.0 );',
                    'material.specularColor = specularFactor.rgb;',
                ].join('\n');

                var fragmentShader = shader.fragmentShader
                    .replace('uniform float roughness;', 'uniform vec3 specular;')
                    .replace('uniform float metalness;', 'uniform float glossiness;')
                    .replace('#include <roughnessmap_pars_fragment>', specularMapParsFragmentChunk)
                    .replace('#include <metalnessmap_pars_fragment>', glossinessMapParsFragmentChunk)
                    .replace('#include <roughnessmap_fragment>', specularMapFragmentChunk)
                    .replace('#include <metalnessmap_fragment>', glossinessMapFragmentChunk)
                    .replace('#include <lights_physical_fragment>', lightPhysicalFragmentChunk);

                delete uniforms.roughness;
                delete uniforms.metalness;
                delete uniforms.roughnessMap;
                delete uniforms.metalnessMap;

                uniforms.specular = {value: new THREE.Color().setHex(0x111111)};
                uniforms.glossiness = {value: 0.5};
                uniforms.specularMap = {value: null};
                uniforms.glossinessMap = {value: null};

                params.vertexShader = shader.vertexShader;
                params.fragmentShader = fragmentShader;
                params.uniforms = uniforms;
                params.defines = {'STANDARD': ''};

                params.color = new THREE.Color(1.0, 1.0, 1.0);
                params.opacity = 1.0;

                var pending = [];

                if (Array.isArray(pbrSpecularGlossiness.diffuseFactor)) {

                    var array = pbrSpecularGlossiness.diffuseFactor;

                    params.color.fromArray(array);
                    params.opacity = array[3];

                }

                if (pbrSpecularGlossiness.diffuseTexture !== undefined) {

                    pending.push(parser.assignTexture(params, 'map', pbrSpecularGlossiness.diffuseTexture.index));

                }

                params.emissive = new THREE.Color(0.0, 0.0, 0.0);
                params.glossiness = pbrSpecularGlossiness.glossinessFactor !== undefined ? pbrSpecularGlossiness.glossinessFactor : 1.0;
                params.specular = new THREE.Color(1.0, 1.0, 1.0);

                if (Array.isArray(pbrSpecularGlossiness.specularFactor)) {

                    params.specular.fromArray(pbrSpecularGlossiness.specularFactor);

                }

                if (pbrSpecularGlossiness.specularGlossinessTexture !== undefined) {

                    var specGlossIndex = pbrSpecularGlossiness.specularGlossinessTexture.index;
                    pending.push(parser.assignTexture(params, 'glossinessMap', specGlossIndex));
                    pending.push(parser.assignTexture(params, 'specularMap', specGlossIndex));

                }

                return Promise.all(pending);

            },

            createMaterial: function (params) {

                // setup material properties based on MeshStandardMaterial for Specular-Glossiness

                var material = new THREE.ShaderMaterial({
                    defines: params.defines,
                    vertexShader: params.vertexShader,
                    fragmentShader: params.fragmentShader,
                    uniforms: params.uniforms,
                    fog: true,
                    lights: true,
                    opacity: params.opacity,
                    transparent: params.transparent
                });

                material.isGLTFSpecularGlossinessMaterial = true;

                material.color = params.color;

                material.map = params.map === undefined ? null : params.map;

                material.lightMap = null;
                material.lightMapIntensity = 1.0;

                material.aoMap = params.aoMap === undefined ? null : params.aoMap;
                material.aoMapIntensity = 1.0;

                material.emissive = params.emissive;
                material.emissiveIntensity = 1.0;
                material.emissiveMap = params.emissiveMap === undefined ? null : params.emissiveMap;

                material.bumpMap = params.bumpMap === undefined ? null : params.bumpMap;
                material.bumpScale = 1;

                material.normalMap = params.normalMap === undefined ? null : params.normalMap;
                if (params.normalScale) material.normalScale = params.normalScale;

                material.displacementMap = null;
                material.displacementScale = 1;
                material.displacementBias = 0;

                material.specularMap = params.specularMap === undefined ? null : params.specularMap;
                material.specular = params.specular;

                material.glossinessMap = params.glossinessMap === undefined ? null : params.glossinessMap;
                material.glossiness = params.glossiness;

                material.alphaMap = null;

                material.envMap = params.envMap === undefined ? null : params.envMap;
                material.envMapIntensity = 1.0;

                material.refractionRatio = 0.98;

                material.extensions.derivatives = true;

                return material;

            },

            /**
             * Clones a GLTFSpecularGlossinessMaterial instance. The ShaderMaterial.copy() method can
             * copy only properties it knows about or inherits, and misses many properties that would
             * normally be defined by MeshStandardMaterial.
             *
             * This method allows GLTFSpecularGlossinessMaterials to be cloned in the process of
             * loading a glTF model, but cloning later (e.g. by the user) would require these changes
             * AND also updating `.onBeforeRender` on the parent mesh.
             *
             * @param  {THREE.ShaderMaterial} source
             * @return {THREE.ShaderMaterial}
             */
            cloneMaterial: function (source) {

                var target = source.clone();

                target.isGLTFSpecularGlossinessMaterial = true;

                var params = this.specularGlossinessParams;

                for (var i = 0, il = params.length; i < il; i++) {

                    target[params[i]] = source[params[i]];

                }

                return target;

            },

            // Here's based on refreshUniformsCommon() and refreshUniformsStandard() in WebGLRenderer.
            refreshUniforms: function (renderer, scene, camera, geometry, material, group) {

                if (material.isGLTFSpecularGlossinessMaterial !== true) {

                    return;

                }

                var uniforms = material.uniforms;
                var defines = material.defines;

                uniforms.opacity.value = material.opacity;

                uniforms.diffuse.value.copy(material.color);
                uniforms.emissive.value.copy(material.emissive).multiplyScalar(material.emissiveIntensity);

                uniforms.map.value = material.map;
                uniforms.specularMap.value = material.specularMap;
                uniforms.alphaMap.value = material.alphaMap;

                uniforms.lightMap.value = material.lightMap;
                uniforms.lightMapIntensity.value = material.lightMapIntensity;

                uniforms.aoMap.value = material.aoMap;
                uniforms.aoMapIntensity.value = material.aoMapIntensity;

                // uv repeat and offset setting priorities
                // 1. color map
                // 2. specular map
                // 3. normal map
                // 4. bump map
                // 5. alpha map
                // 6. emissive map

                var uvScaleMap;

                if (material.map) {

                    uvScaleMap = material.map;

                } else if (material.specularMap) {

                    uvScaleMap = material.specularMap;

                } else if (material.displacementMap) {

                    uvScaleMap = material.displacementMap;

                } else if (material.normalMap) {

                    uvScaleMap = material.normalMap;

                } else if (material.bumpMap) {

                    uvScaleMap = material.bumpMap;

                } else if (material.glossinessMap) {

                    uvScaleMap = material.glossinessMap;

                } else if (material.alphaMap) {

                    uvScaleMap = material.alphaMap;

                } else if (material.emissiveMap) {

                    uvScaleMap = material.emissiveMap;

                }

                if (uvScaleMap !== undefined) {

                    // backwards compatibility
                    if (uvScaleMap.isWebGLRenderTarget) {

                        uvScaleMap = uvScaleMap.texture;

                    }

                    if (uvScaleMap.matrixAutoUpdate === true) {

                        uvScaleMap.updateMatrix();

                    }

                    uniforms.uvTransform.value.copy(uvScaleMap.matrix);

                }

                uniforms.envMap.value = material.envMap;
                uniforms.envMapIntensity.value = material.envMapIntensity;
                uniforms.flipEnvMap.value = (material.envMap && material.envMap.isCubeTexture) ? -1 : 1;

                uniforms.refractionRatio.value = material.refractionRatio;

                uniforms.specular.value.copy(material.specular);
                uniforms.glossiness.value = material.glossiness;

                uniforms.glossinessMap.value = material.glossinessMap;

                uniforms.emissiveMap.value = material.emissiveMap;
                uniforms.bumpMap.value = material.bumpMap;
                uniforms.normalMap.value = material.normalMap;

                uniforms.displacementMap.value = material.displacementMap;
                uniforms.displacementScale.value = material.displacementScale;
                uniforms.displacementBias.value = material.displacementBias;

                if (uniforms.glossinessMap.value !== null && defines.USE_GLOSSINESSMAP === undefined) {

                    defines.USE_GLOSSINESSMAP = '';
                    // set USE_ROUGHNESSMAP to enable vUv
                    defines.USE_ROUGHNESSMAP = '';

                }

                if (uniforms.glossinessMap.value === null && defines.USE_GLOSSINESSMAP !== undefined) {

                    delete defines.USE_GLOSSINESSMAP;
                    delete defines.USE_ROUGHNESSMAP;

                }

            }

        };

    }

    /*********************************/
    /********** INTERPOLATION ********/
    /*********************************/

    // Spline Interpolation
    // Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#appendix-c-spline-interpolation
    function GLTFCubicSplineInterpolant(parameterPositions, sampleValues, sampleSize, resultBuffer) {

        THREE.Interpolant.call(this, parameterPositions, sampleValues, sampleSize, resultBuffer);

    }

    GLTFCubicSplineInterpolant.prototype = Object.create(THREE.Interpolant.prototype);
    GLTFCubicSplineInterpolant.prototype.constructor = GLTFCubicSplineInterpolant;

    GLTFCubicSplineInterpolant.prototype.interpolate_ = function (i1, t0, t, t1) {

        var result = this.resultBuffer;
        var values = this.sampleValues;
        var stride = this.valueSize;

        var stride2 = stride * 2;
        var stride3 = stride * 3;

        var td = t1 - t0;

        var p = (t - t0) / td;
        var pp = p * p;
        var ppp = pp * p;

        var offset1 = i1 * stride3;
        var offset0 = offset1 - stride3;

        var s0 = 2 * ppp - 3 * pp + 1;
        var s1 = ppp - 2 * pp + p;
        var s2 = -2 * ppp + 3 * pp;
        var s3 = ppp - pp;

        // Layout of keyframe output values for CUBICSPLINE animations:
        //   [ inTangent_1, splineVertex_1, outTangent_1, inTangent_2, splineVertex_2, ... ]
        for (var i = 0; i !== stride; i++) {

            var p0 = values[offset0 + i + stride]; // splineVertex_k
            var m0 = values[offset0 + i + stride2] * td; // outTangent_k * (t_k+1 - t_k)
            var p1 = values[offset1 + i + stride]; // splineVertex_k+1
            var m1 = values[offset1 + i] * td; // inTangent_k+1 * (t_k+1 - t_k)

            result[i] = s0 * p0 + s1 * m0 + s2 * p1 + s3 * m1;

        }

        return result;

    };

    /*********************************/
    /********** INTERNALS ************/
    /*********************************/

    /* CONSTANTS */

    var WEBGL_CONSTANTS = {
        FLOAT: 5126,
        //FLOAT_MAT2: 35674,
        FLOAT_MAT3: 35675,
        FLOAT_MAT4: 35676,
        FLOAT_VEC2: 35664,
        FLOAT_VEC3: 35665,
        FLOAT_VEC4: 35666,
        LINEAR: 9729,
        REPEAT: 10497,
        SAMPLER_2D: 35678,
        POINTS: 0,
        LINES: 1,
        LINE_LOOP: 2,
        LINE_STRIP: 3,
        TRIANGLES: 4,
        TRIANGLE_STRIP: 5,
        TRIANGLE_FAN: 6,
        UNSIGNED_BYTE: 5121,
        UNSIGNED_SHORT: 5123
    };

    var WEBGL_TYPE = {
        5126: Number,
        //35674: THREE.Matrix2,
        35675: THREE.Matrix3,
        35676: THREE.Matrix4,
        35664: THREE.Vector2,
        35665: THREE.Vector3,
        35666: THREE.Vector4,
        35678: THREE.Texture
    };

    var WEBGL_COMPONENT_TYPES = {
        5120: Int8Array,
        5121: Uint8Array,
        5122: Int16Array,
        5123: Uint16Array,
        5125: Uint32Array,
        5126: Float32Array
    };

    var WEBGL_FILTERS = {
        9728: THREE.NearestFilter,
        9729: THREE.LinearFilter,
        9984: THREE.NearestMipMapNearestFilter,
        9985: THREE.LinearMipMapNearestFilter,
        9986: THREE.NearestMipMapLinearFilter,
        9987: THREE.LinearMipMapLinearFilter
    };

    var WEBGL_WRAPPINGS = {
        33071: THREE.ClampToEdgeWrapping,
        33648: THREE.MirroredRepeatWrapping,
        10497: THREE.RepeatWrapping
    };

    var WEBGL_TEXTURE_FORMATS = {
        6406: THREE.AlphaFormat,
        6407: THREE.RGBFormat,
        6408: THREE.RGBAFormat,
        6409: THREE.LuminanceFormat,
        6410: THREE.LuminanceAlphaFormat
    };

    var WEBGL_TEXTURE_DATATYPES = {
        5121: THREE.UnsignedByteType,
        32819: THREE.UnsignedShort4444Type,
        32820: THREE.UnsignedShort5551Type,
        33635: THREE.UnsignedShort565Type
    };

    var WEBGL_SIDES = {
        1028: THREE.BackSide, // Culling front
        1029: THREE.FrontSide // Culling back
        //1032: THREE.NoSide   // Culling front and back, what to do?
    };

    var WEBGL_DEPTH_FUNCS = {
        512: THREE.NeverDepth,
        513: THREE.LessDepth,
        514: THREE.EqualDepth,
        515: THREE.LessEqualDepth,
        516: THREE.GreaterEqualDepth,
        517: THREE.NotEqualDepth,
        518: THREE.GreaterEqualDepth,
        519: THREE.AlwaysDepth
    };

    var WEBGL_BLEND_EQUATIONS = {
        32774: THREE.AddEquation,
        32778: THREE.SubtractEquation,
        32779: THREE.ReverseSubtractEquation
    };

    var WEBGL_BLEND_FUNCS = {
        0: THREE.ZeroFactor,
        1: THREE.OneFactor,
        768: THREE.SrcColorFactor,
        769: THREE.OneMinusSrcColorFactor,
        770: THREE.SrcAlphaFactor,
        771: THREE.OneMinusSrcAlphaFactor,
        772: THREE.DstAlphaFactor,
        773: THREE.OneMinusDstAlphaFactor,
        774: THREE.DstColorFactor,
        775: THREE.OneMinusDstColorFactor,
        776: THREE.SrcAlphaSaturateFactor
        // The followings are not supported by Three.js yet
        //32769: CONSTANT_COLOR,
        //32770: ONE_MINUS_CONSTANT_COLOR,
        //32771: CONSTANT_ALPHA,
        //32772: ONE_MINUS_CONSTANT_COLOR
    };

    var WEBGL_TYPE_SIZES = {
        'SCALAR': 1,
        'VEC2': 2,
        'VEC3': 3,
        'VEC4': 4,
        'MAT2': 4,
        'MAT3': 9,
        'MAT4': 16
    };

    var ATTRIBUTES = {
        POSITION: 'position',
        NORMAL: 'normal',
        TEXCOORD_0: 'uv',
        TEXCOORD0: 'uv', // deprecated
        TEXCOORD: 'uv', // deprecated
        TEXCOORD_1: 'uv2',
        COLOR_0: 'color',
        COLOR0: 'color', // deprecated
        COLOR: 'color', // deprecated
        WEIGHTS_0: 'skinWeight',
        WEIGHT: 'skinWeight', // deprecated
        JOINTS_0: 'skinIndex',
        JOINT: 'skinIndex' // deprecated
    };

    var PATH_PROPERTIES = {
        scale: 'scale',
        translation: 'position',
        rotation: 'quaternion',
        weights: 'morphTargetInfluences'
    };

    var INTERPOLATION = {
        CUBICSPLINE: THREE.InterpolateSmooth, // We use custom interpolation GLTFCubicSplineInterpolation for CUBICSPLINE.
                                              // KeyframeTrack.optimize() can't handle glTF Cubic Spline output values layout,
                                              // using THREE.InterpolateSmooth for KeyframeTrack instantiation to prevent optimization.
                                              // See KeyframeTrack.optimize() for the detail.
        LINEAR: THREE.InterpolateLinear,
        STEP: THREE.InterpolateDiscrete
    };

    var STATES_ENABLES = {
        2884: 'CULL_FACE',
        2929: 'DEPTH_TEST',
        3042: 'BLEND',
        3089: 'SCISSOR_TEST',
        32823: 'POLYGON_OFFSET_FILL',
        32926: 'SAMPLE_ALPHA_TO_COVERAGE'
    };

    var ALPHA_MODES = {
        OPAQUE: 'OPAQUE',
        MASK: 'MASK',
        BLEND: 'BLEND'
    };

    /* UTILITY FUNCTIONS */

    function resolveURL(url, path) {

        // Invalid URL
        if (typeof url !== 'string' || url === '') return '';

        // Absolute URL http://,https://,//
        if (/^(https?:)?\/\//i.test(url)) return url;

        // Data URI
        if (/^data:.*,.*$/i.test(url)) return url;

        // Blob URL
        if (/^blob:.*$/i.test(url)) return url;

        // Relative URL
        return path + url;

    }

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#default-material
     */
    function createDefaultMaterial() {

        return new THREE.MeshStandardMaterial({
            color: 0xFFFFFF,
            emissive: 0x000000,
            metalness: 1,
            roughness: 1,
            transparent: false,
            depthTest: true,
            side: THREE.FrontSide
        });

    }

    function addUnknownExtensionsToUserData(knownExtensions, object, objectDef) {

        // Add unknown glTF extensions to an object's userData.

        for (var name in objectDef.extensions) {

            if (knownExtensions[name] === undefined) {

                object.userData.gltfExtensions = object.userData.gltfExtensions || {};
                object.userData.gltfExtensions[name] = objectDef.extensions[name];

            }

        }

    }

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#morph-targets
     *
     * @param {THREE.BufferGeometry} geometry
     * @param {Array<GLTF.Target>} targets
     * @param {Array<THREE.BufferAttribute>} accessors
     */
    function addMorphTargets(geometry, targets, accessors) {

        var hasMorphPosition = false;
        var hasMorphNormal = false;

        for (var i = 0, il = targets.length; i < il; i++) {

            var target = targets[i];

            if (target.POSITION !== undefined) hasMorphPosition = true;
            if (target.NORMAL !== undefined) hasMorphNormal = true;

            if (hasMorphPosition && hasMorphNormal) break;

        }

        if (!hasMorphPosition && !hasMorphNormal) return;

        var morphPositions = [];
        var morphNormals = [];

        for (var i = 0, il = targets.length; i < il; i++) {

            var target = targets[i];
            var attributeName = 'morphTarget' + i;

            if (hasMorphPosition) {

                // Three.js morph position is absolute value. The formula is
                //   basePosition
                //     + weight0 * ( morphPosition0 - basePosition )
                //     + weight1 * ( morphPosition1 - basePosition )
                //     ...
                // while the glTF one is relative
                //   basePosition
                //     + weight0 * glTFmorphPosition0
                //     + weight1 * glTFmorphPosition1
                //     ...
                // then we need to convert from relative to absolute here.

                if (target.POSITION !== undefined) {

                    // Cloning not to pollute original accessor
                    var positionAttribute = cloneBufferAttribute(accessors[target.POSITION]);
                    positionAttribute.name = attributeName;

                    var position = geometry.attributes.position;

                    for (var j = 0, jl = positionAttribute.count; j < jl; j++) {

                        positionAttribute.setXYZ(
                            j,
                            positionAttribute.getX(j) + position.getX(j),
                            positionAttribute.getY(j) + position.getY(j),
                            positionAttribute.getZ(j) + position.getZ(j)
                        );

                    }

                } else {

                    positionAttribute = geometry.attributes.position;

                }

                morphPositions.push(positionAttribute);

            }

            if (hasMorphNormal) {

                // see target.POSITION's comment

                var normalAttribute;

                if (target.NORMAL !== undefined) {

                    var normalAttribute = cloneBufferAttribute(accessors[target.NORMAL]);
                    normalAttribute.name = attributeName;

                    var normal = geometry.attributes.normal;

                    for (var j = 0, jl = normalAttribute.count; j < jl; j++) {

                        normalAttribute.setXYZ(
                            j,
                            normalAttribute.getX(j) + normal.getX(j),
                            normalAttribute.getY(j) + normal.getY(j),
                            normalAttribute.getZ(j) + normal.getZ(j)
                        );

                    }

                } else {

                    normalAttribute = geometry.attributes.normal;

                }

                morphNormals.push(normalAttribute);

            }

        }

        if (hasMorphPosition) geometry.morphAttributes.position = morphPositions;
        if (hasMorphNormal) geometry.morphAttributes.normal = morphNormals;

    }

    /**
     * @param {THREE.Mesh} mesh
     * @param {GLTF.Mesh} meshDef
     */
    function updateMorphTargets(mesh, meshDef) {

        mesh.updateMorphTargets();

        if (meshDef.weights !== undefined) {

            for (var i = 0, il = meshDef.weights.length; i < il; i++) {

                mesh.morphTargetInfluences[i] = meshDef.weights[i];

            }

        }

        // .extras has user-defined data, so check that .extras.targetNames is an array.
        if (meshDef.extras && Array.isArray(meshDef.extras.targetNames)) {

            var targetNames = meshDef.extras.targetNames;

            if (mesh.morphTargetInfluences.length === targetNames.length) {

                mesh.morphTargetDictionary = {};

                for (var i = 0, il = targetNames.length; i < il; i++) {

                    mesh.morphTargetDictionary[targetNames[i]] = i;

                }

            } else {

                console.warn('THREE.GLTFLoader: Invalid extras.targetNames length. Ignoring names.');

            }

        }

    }

    function isPrimitiveEqual(a, b) {

        if (a.indices !== b.indices) {

            return false;

        }

        return isObjectEqual(a.attributes, b.attributes);

    }

    function isObjectEqual(a, b) {

        if (Object.keys(a).length !== Object.keys(b).length) return false;

        for (var key in a) {

            if (a[key] !== b[key]) return false;

        }

        return true;

    }

    function isArrayEqual(a, b) {

        if (a.length !== b.length) return false;

        for (var i = 0, il = a.length; i < il; i++) {

            if (a[i] !== b[i]) return false;

        }

        return true;

    }

    function getCachedGeometry(cache, newPrimitive) {

        for (var i = 0, il = cache.length; i < il; i++) {

            var cached = cache[i];

            if (isPrimitiveEqual(cached.primitive, newPrimitive)) return cached.promise;

        }

        return null;

    }

    function getCachedCombinedGeometry(cache, geometries) {

        for (var i = 0, il = cache.length; i < il; i++) {

            var cached = cache[i];

            if (isArrayEqual(geometries, cached.baseGeometries)) return cached.geometry;

        }

        return null;

    }

    function getCachedMultiPassGeometry(cache, geometry, primitives) {

        for (var i = 0, il = cache.length; i < il; i++) {

            var cached = cache[i];

            if (geometry === cached.baseGeometry && isArrayEqual(primitives, cached.primitives)) return cached.geometry;

        }

        return null;

    }

    function cloneBufferAttribute(attribute) {

        if (attribute.isInterleavedBufferAttribute) {

            var count = attribute.count;
            var itemSize = attribute.itemSize;
            var array = attribute.array.slice(0, count * itemSize);

            for (var i = 0; i < count; ++i) {

                array[i] = attribute.getX(i);
                if (itemSize >= 2) array[i + 1] = attribute.getY(i);
                if (itemSize >= 3) array[i + 2] = attribute.getZ(i);
                if (itemSize >= 4) array[i + 3] = attribute.getW(i);

            }

            return new THREE.BufferAttribute(array, itemSize, attribute.normalized);

        }

        return attribute.clone();

    }

    /**
     * Checks if we can build a single Mesh with MultiMaterial from multiple primitives.
     * Returns true if all primitives use the same attributes/morphAttributes/mode
     * and also have index. Otherwise returns false.
     *
     * @param {Array<GLTF.Primitive>} primitives
     * @return {Boolean}
     */
    function isMultiPassGeometry(primitives) {

        if (primitives.length < 2) return false;

        var primitive0 = primitives[0];
        var targets0 = primitive0.targets || [];

        if (primitive0.indices === undefined) return false;

        for (var i = 1, il = primitives.length; i < il; i++) {

            var primitive = primitives[i];

            if (primitive0.mode !== primitive.mode) return false;
            if (primitive.indices === undefined) return false;
            if (!isObjectEqual(primitive0.attributes, primitive.attributes)) return false;

            var targets = primitive.targets || [];

            if (targets0.length !== targets.length) return false;

            for (var j = 0, jl = targets0.length; j < jl; j++) {

                if (!isObjectEqual(targets0[j], targets[j])) return false;

            }

        }

        return true;

    }

    /* GLTF PARSER */

    function GLTFParser(json, extensions, options) {

        this.json = json || {};
        this.extensions = extensions || {};
        this.options = options || {};

        // loader object cache
        this.cache = new GLTFRegistry();

        // BufferGeometry caching
        this.primitiveCache = [];
        this.multiplePrimitivesCache = [];
        this.multiPassGeometryCache = [];

        this.textureLoader = new THREE.TextureLoader(this.options.manager);
        this.textureLoader.setCrossOrigin(this.options.crossOrigin);

        this.fileLoader = new THREE.FileLoader(this.options.manager);
        this.fileLoader.setResponseType('arraybuffer');

    }

    GLTFParser.prototype.parse = function (onLoad, onError) {

        var json = this.json;

        // Clear the loader cache
        this.cache.removeAll();

        // Mark the special nodes/meshes in json for efficient parse
        this.markDefs();

        // Fire the callback on complete
        this.getMultiDependencies([

            'scene',
            'animation',
            'camera'

        ]).then(function (dependencies) {

            var scenes = dependencies.scenes || [];
            var scene = scenes[json.scene || 0];
            var animations = dependencies.animations || [];
            var cameras = dependencies.cameras || [];

            onLoad(scene, scenes, cameras, animations, json);

        }).catch(onError);

    };

    /**
     * Marks the special nodes/meshes in json for efficient parse.
     */
    GLTFParser.prototype.markDefs = function () {

        var nodeDefs = this.json.nodes || [];
        var skinDefs = this.json.skins || [];
        var meshDefs = this.json.meshes || [];

        var meshReferences = {};
        var meshUses = {};

        // Nothing in the node definition indicates whether it is a Bone or an
        // Object3D. Use the skins' joint references to mark bones.
        for (var skinIndex = 0, skinLength = skinDefs.length; skinIndex < skinLength; skinIndex++) {

            var joints = skinDefs[skinIndex].joints;

            for (var i = 0, il = joints.length; i < il; i++) {

                nodeDefs[joints[i]].isBone = true;

            }

        }

        // Meshes can (and should) be reused by multiple nodes in a glTF asset. To
        // avoid having more than one THREE.Mesh with the same name, count
        // references and rename instances below.
        //
        // Example: CesiumMilkTruck sample model reuses "Wheel" meshes.
        for (var nodeIndex = 0, nodeLength = nodeDefs.length; nodeIndex < nodeLength; nodeIndex++) {

            var nodeDef = nodeDefs[nodeIndex];

            if (nodeDef.mesh !== undefined) {

                if (meshReferences[nodeDef.mesh] === undefined) {

                    meshReferences[nodeDef.mesh] = meshUses[nodeDef.mesh] = 0;

                }

                meshReferences[nodeDef.mesh]++;

                // Nothing in the mesh definition indicates whether it is
                // a SkinnedMesh or Mesh. Use the node's mesh reference
                // to mark SkinnedMesh if node has skin.
                if (nodeDef.skin !== undefined) {

                    meshDefs[nodeDef.mesh].isSkinnedMesh = true;

                }

            }

        }

        this.json.meshReferences = meshReferences;
        this.json.meshUses = meshUses;

    };

    /**
     * Requests the specified dependency asynchronously, with caching.
     * @param {string} type
     * @param {number} index
     * @return {Promise<Object>}
     */
    GLTFParser.prototype.getDependency = function (type, index) {

        var cacheKey = type + ':' + index;
        var dependency = this.cache.get(cacheKey);

        if (!dependency) {

            switch (type) {

                case 'scene':
                    dependency = this.loadScene(index);
                    break;

                case 'node':
                    dependency = this.loadNode(index);
                    break;

                case 'mesh':
                    dependency = this.loadMesh(index);
                    break;

                case 'accessor':
                    dependency = this.loadAccessor(index);
                    break;

                case 'bufferView':
                    dependency = this.loadBufferView(index);
                    break;

                case 'buffer':
                    dependency = this.loadBuffer(index);
                    break;

                case 'material':
                    dependency = this.loadMaterial(index);
                    break;

                case 'texture':
                    dependency = this.loadTexture(index);
                    break;

                case 'skin':
                    dependency = this.loadSkin(index);
                    break;

                case 'animation':
                    dependency = this.loadAnimation(index);
                    break;

                case 'camera':
                    dependency = this.loadCamera(index);
                    break;

                default:
                    throw new Error('Unknown type: ' + type);

            }

            this.cache.add(cacheKey, dependency);

        }

        return dependency;

    };

    /**
     * Requests all dependencies of the specified type asynchronously, with caching.
     * @param {string} type
     * @return {Promise<Array<Object>>}
     */
    GLTFParser.prototype.getDependencies = function (type) {

        var dependencies = this.cache.get(type);

        if (!dependencies) {

            var parser = this;
            var defs = this.json[type + (type === 'mesh' ? 'es' : 's')] || [];

            dependencies = Promise.all(defs.map(function (def, index) {

                return parser.getDependency(type, index);

            }));

            this.cache.add(type, dependencies);

        }

        return dependencies;

    };

    /**
     * Requests all multiple dependencies of the specified types asynchronously, with caching.
     * @param {Array<string>} types
     * @return {Promise<Object<Array<Object>>>}
     */
    GLTFParser.prototype.getMultiDependencies = function (types) {

        var results = {};
        var pendings = [];

        for (var i = 0, il = types.length; i < il; i++) {

            var type = types[i];
            var value = this.getDependencies(type);

            value = value.then(function (key, value) {

                results[key] = value;

            }.bind(this, type + (type === 'mesh' ? 'es' : 's')));

            pendings.push(value);

        }

        return Promise.all(pendings).then(function () {

            return results;

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#buffers-and-buffer-views
     * @param {number} bufferIndex
     * @return {Promise<ArrayBuffer>}
     */
    GLTFParser.prototype.loadBuffer = function (bufferIndex) {

        var bufferDef = this.json.buffers[bufferIndex];
        var loader = this.fileLoader;

        if (bufferDef.type && bufferDef.type !== 'arraybuffer') {

            throw new Error('THREE.GLTFLoader: ' + bufferDef.type + ' buffer type is not supported.');

        }

        // If present, GLB container is required to be the first buffer.
        if (bufferDef.uri === undefined && bufferIndex === 0) {

            return Promise.resolve(this.extensions[EXTENSIONS.KHR_BINARY_GLTF].body);

        }

        var options = this.options;

        return new Promise(function (resolve, reject) {

            loader.load(resolveURL(bufferDef.uri, options.path), resolve, undefined, function () {

                reject(new Error('THREE.GLTFLoader: Failed to load buffer "' + bufferDef.uri + '".'));

            });

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#buffers-and-buffer-views
     * @param {number} bufferViewIndex
     * @return {Promise<ArrayBuffer>}
     */
    GLTFParser.prototype.loadBufferView = function (bufferViewIndex) {

        var bufferViewDef = this.json.bufferViews[bufferViewIndex];

        return this.getDependency('buffer', bufferViewDef.buffer).then(function (buffer) {

            var byteLength = bufferViewDef.byteLength || 0;
            var byteOffset = bufferViewDef.byteOffset || 0;
            return buffer.slice(byteOffset, byteOffset + byteLength);

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#accessors
     * @param {number} accessorIndex
     * @return {Promise<THREE.BufferAttribute|THREE.InterleavedBufferAttribute>}
     */
    GLTFParser.prototype.loadAccessor = function (accessorIndex) {

        var parser = this;
        var json = this.json;

        var accessorDef = this.json.accessors[accessorIndex];

        if (accessorDef.bufferView === undefined && accessorDef.sparse === undefined) {

            // Ignore empty accessors, which may be used to declare runtime
            // information about attributes coming from another source (e.g. Draco
            // compression extension).
            return null;

        }

        var pendingBufferViews = [];

        if (accessorDef.bufferView !== undefined) {

            pendingBufferViews.push(this.getDependency('bufferView', accessorDef.bufferView));

        } else {

            pendingBufferViews.push(null);

        }

        if (accessorDef.sparse !== undefined) {

            pendingBufferViews.push(this.getDependency('bufferView', accessorDef.sparse.indices.bufferView));
            pendingBufferViews.push(this.getDependency('bufferView', accessorDef.sparse.values.bufferView));

        }

        return Promise.all(pendingBufferViews).then(function (bufferViews) {

            var bufferView = bufferViews[0];

            var itemSize = WEBGL_TYPE_SIZES[accessorDef.type];
            var TypedArray = WEBGL_COMPONENT_TYPES[accessorDef.componentType];

            // For VEC3: itemSize is 3, elementBytes is 4, itemBytes is 12.
            var elementBytes = TypedArray.BYTES_PER_ELEMENT;
            var itemBytes = elementBytes * itemSize;
            var byteOffset = accessorDef.byteOffset || 0;
            var byteStride = json.bufferViews[accessorDef.bufferView].byteStride;
            var normalized = accessorDef.normalized === true;
            var array, bufferAttribute;

            // The buffer is not interleaved if the stride is the item size in bytes.
            if (byteStride && byteStride !== itemBytes) {

                var ibCacheKey = 'InterleavedBuffer:' + accessorDef.bufferView + ':' + accessorDef.componentType;
                var ib = parser.cache.get(ibCacheKey);

                if (!ib) {

                    // Use the full buffer if it's interleaved.
                    array = new TypedArray(bufferView);

                    // Integer parameters to IB/IBA are in array elements, not bytes.
                    ib = new THREE.InterleavedBuffer(array, byteStride / elementBytes);

                    parser.cache.add(ibCacheKey, ib);

                }

                bufferAttribute = new THREE.InterleavedBufferAttribute(ib, itemSize, byteOffset / elementBytes, normalized);

            } else {

                if (bufferView === null) {

                    array = new TypedArray(accessorDef.count * itemSize);

                } else {

                    array = new TypedArray(bufferView, byteOffset, accessorDef.count * itemSize);

                }

                bufferAttribute = new THREE.BufferAttribute(array, itemSize, normalized);

            }

            // https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#sparse-accessors
            if (accessorDef.sparse !== undefined) {

                var itemSizeIndices = WEBGL_TYPE_SIZES.SCALAR;
                var TypedArrayIndices = WEBGL_COMPONENT_TYPES[accessorDef.sparse.indices.componentType];

                var byteOffsetIndices = accessorDef.sparse.indices.byteOffset || 0;
                var byteOffsetValues = accessorDef.sparse.values.byteOffset || 0;

                var sparseIndices = new TypedArrayIndices(bufferViews[1], byteOffsetIndices, accessorDef.sparse.count * itemSizeIndices);
                var sparseValues = new TypedArray(bufferViews[2], byteOffsetValues, accessorDef.sparse.count * itemSize);

                if (bufferView !== null) {

                    // Avoid modifying the original ArrayBuffer, if the bufferView wasn't initialized with zeroes.
                    bufferAttribute.setArray(bufferAttribute.array.slice());

                }

                for (var i = 0, il = sparseIndices.length; i < il; i++) {

                    var index = sparseIndices[i];

                    bufferAttribute.setX(index, sparseValues[i * itemSize]);
                    if (itemSize >= 2) bufferAttribute.setY(index, sparseValues[i * itemSize + 1]);
                    if (itemSize >= 3) bufferAttribute.setZ(index, sparseValues[i * itemSize + 2]);
                    if (itemSize >= 4) bufferAttribute.setW(index, sparseValues[i * itemSize + 3]);
                    if (itemSize >= 5) throw new Error('THREE.GLTFLoader: Unsupported itemSize in sparse BufferAttribute.');

                }

            }

            return bufferAttribute;

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#textures
     * @param {number} textureIndex
     * @return {Promise<THREE.Texture>}
     */
    GLTFParser.prototype.loadTexture = function (textureIndex) {

        var parser = this;
        var json = this.json;
        var options = this.options;
        var textureLoader = this.textureLoader;

        var URL = window.URL || window.webkitURL;

        var textureDef = json.textures[textureIndex];

        var textureExtensions = textureDef.extensions || {};

        var source;

        if (textureExtensions[EXTENSIONS.MSFT_TEXTURE_DDS]) {

            source = json.images[textureExtensions[EXTENSIONS.MSFT_TEXTURE_DDS].source];

        } else {

            source = json.images[textureDef.source];

        }

        var sourceURI = source.uri;
        var isObjectURL = false;

        if (source.bufferView !== undefined) {

            // Load binary image data from bufferView, if provided.

            sourceURI = parser.getDependency('bufferView', source.bufferView).then(function (bufferView) {

                isObjectURL = true;
                var blob = new Blob([bufferView], {type: source.mimeType});
                sourceURI = URL.createObjectURL(blob);
                return sourceURI;

            });

        }

        return Promise.resolve(sourceURI).then(function (sourceURI) {

            // Load Texture resource.

            var loader = THREE.Loader.Handlers.get(sourceURI);

            if (!loader) {

                loader = textureExtensions[EXTENSIONS.MSFT_TEXTURE_DDS]
                    ? parser.extensions[EXTENSIONS.MSFT_TEXTURE_DDS].ddsLoader
                    : textureLoader;

            }

            return new Promise(function (resolve, reject) {

                loader.load(resolveURL(sourceURI, options.path), resolve, undefined, reject);

            });

        }).then(function (texture) {

            // Clean up resources and configure Texture.

            if (isObjectURL === true) {

                URL.revokeObjectURL(sourceURI);

            }

            texture.flipY = false;

            if (textureDef.name !== undefined) texture.name = textureDef.name;

            // .format of dds texture is set in DDSLoader
            if (!textureExtensions[EXTENSIONS.MSFT_TEXTURE_DDS]) {

                texture.format = textureDef.format !== undefined ? WEBGL_TEXTURE_FORMATS[textureDef.format] : THREE.RGBAFormat;

            }

            if (textureDef.internalFormat !== undefined && texture.format !== WEBGL_TEXTURE_FORMATS[textureDef.internalFormat]) {

                console.warn('THREE.GLTFLoader: Three.js does not support texture internalFormat which is different from texture format. ' +
                    'internalFormat will be forced to be the same value as format.');

            }

            texture.type = textureDef.type !== undefined ? WEBGL_TEXTURE_DATATYPES[textureDef.type] : THREE.UnsignedByteType;

            var samplers = json.samplers || {};
            var sampler = samplers[textureDef.sampler] || {};

            texture.magFilter = WEBGL_FILTERS[sampler.magFilter] || THREE.LinearFilter;
            texture.minFilter = WEBGL_FILTERS[sampler.minFilter] || THREE.LinearMipMapLinearFilter;
            texture.wrapS = WEBGL_WRAPPINGS[sampler.wrapS] || THREE.RepeatWrapping;
            texture.wrapT = WEBGL_WRAPPINGS[sampler.wrapT] || THREE.RepeatWrapping;

            return texture;

        });

    };

    /**
     * Asynchronously assigns a texture to the given material parameters.
     * @param {Object} materialParams
     * @param {string} textureName
     * @param {number} textureIndex
     * @return {Promise}
     */
    GLTFParser.prototype.assignTexture = function (materialParams, textureName, textureIndex) {

        return this.getDependency('texture', textureIndex).then(function (texture) {

            materialParams[textureName] = texture;

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#materials
     * @param {number} materialIndex
     * @return {Promise<THREE.Material>}
     */
    GLTFParser.prototype.loadMaterial = function (materialIndex) {

        var parser = this;
        var json = this.json;
        var extensions = this.extensions;
        var materialDef = this.json.materials[materialIndex];

        var materialType;
        var materialParams = {};
        var materialExtensions = materialDef.extensions || {};

        var pending = [];

        if (materialExtensions[EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS]) {

            var sgExtension = extensions[EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS];
            materialType = sgExtension.getMaterialType(materialDef);
            pending.push(sgExtension.extendParams(materialParams, materialDef, parser));

        } else if (materialExtensions[EXTENSIONS.KHR_MATERIALS_UNLIT]) {

            var kmuExtension = extensions[EXTENSIONS.KHR_MATERIALS_UNLIT];
            materialType = kmuExtension.getMaterialType(materialDef);
            pending.push(kmuExtension.extendParams(materialParams, materialDef, parser));

        } else {

            // Specification:
            // https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#metallic-roughness-material

            materialType = THREE.MeshStandardMaterial;

            var metallicRoughness = materialDef.pbrMetallicRoughness || {};

            materialParams.color = new THREE.Color(1.0, 1.0, 1.0);
            materialParams.opacity = 1.0;

            if (Array.isArray(metallicRoughness.baseColorFactor)) {

                var array = metallicRoughness.baseColorFactor;

                materialParams.color.fromArray(array);
                materialParams.opacity = array[3];

            }

            if (metallicRoughness.baseColorTexture !== undefined) {

                pending.push(parser.assignTexture(materialParams, 'map', metallicRoughness.baseColorTexture.index));

            }

            materialParams.metalness = metallicRoughness.metallicFactor !== undefined ? metallicRoughness.metallicFactor : 1.0;
            materialParams.roughness = metallicRoughness.roughnessFactor !== undefined ? metallicRoughness.roughnessFactor : 1.0;

            if (metallicRoughness.metallicRoughnessTexture !== undefined) {

                var textureIndex = metallicRoughness.metallicRoughnessTexture.index;
                pending.push(parser.assignTexture(materialParams, 'metalnessMap', textureIndex));
                pending.push(parser.assignTexture(materialParams, 'roughnessMap', textureIndex));

            }

        }

        if (materialDef.doubleSided === true) {

            materialParams.side = THREE.DoubleSide;

        }

        var alphaMode = materialDef.alphaMode || ALPHA_MODES.OPAQUE;

        if (alphaMode === ALPHA_MODES.BLEND) {

            materialParams.transparent = true;

        } else {

            materialParams.transparent = false;

            if (alphaMode === ALPHA_MODES.MASK) {

                materialParams.alphaTest = materialDef.alphaCutoff !== undefined ? materialDef.alphaCutoff : 0.5;

            }

        }

        if (materialDef.normalTexture !== undefined && materialType !== THREE.MeshBasicMaterial) {

            pending.push(parser.assignTexture(materialParams, 'normalMap', materialDef.normalTexture.index));

            materialParams.normalScale = new THREE.Vector2(1, 1);

            if (materialDef.normalTexture.scale !== undefined) {

                materialParams.normalScale.set(materialDef.normalTexture.scale, materialDef.normalTexture.scale);

            }

        }

        if (materialDef.occlusionTexture !== undefined && materialType !== THREE.MeshBasicMaterial) {

            pending.push(parser.assignTexture(materialParams, 'aoMap', materialDef.occlusionTexture.index));

            if (materialDef.occlusionTexture.strength !== undefined) {

                materialParams.aoMapIntensity = materialDef.occlusionTexture.strength;

            }

        }

        if (materialDef.emissiveFactor !== undefined && materialType !== THREE.MeshBasicMaterial) {

            materialParams.emissive = new THREE.Color().fromArray(materialDef.emissiveFactor);

        }

        if (materialDef.emissiveTexture !== undefined && materialType !== THREE.MeshBasicMaterial) {

            pending.push(parser.assignTexture(materialParams, 'emissiveMap', materialDef.emissiveTexture.index));

        }

        return Promise.all(pending).then(function () {

            var material;

            if (materialType === THREE.ShaderMaterial) {

                material = extensions[EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS].createMaterial(materialParams);

            } else {

                material = new materialType(materialParams);

            }

            if (materialDef.name !== undefined) material.name = materialDef.name;

            // Normal map textures use OpenGL conventions:
            // https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#materialnormaltexture
            if (material.normalScale) {

                material.normalScale.y = -material.normalScale.y;

            }

            // baseColorTexture, emissiveTexture, and specularGlossinessTexture use sRGB encoding.
            if (material.map) material.map.encoding = THREE.sRGBEncoding;
            if (material.emissiveMap) material.emissiveMap.encoding = THREE.sRGBEncoding;
            if (material.specularMap) material.specularMap.encoding = THREE.sRGBEncoding;

            if (materialDef.extras) material.userData = materialDef.extras;

            if (materialDef.extensions) addUnknownExtensionsToUserData(extensions, material, materialDef);

            return material;

        });

    };

    /**
     * @param  {THREE.BufferGeometry} geometry
     * @param  {GLTF.Primitive} primitiveDef
     * @param  {Array<THREE.BufferAttribute>} accessors
     */
    function addPrimitiveAttributes(geometry, primitiveDef, accessors) {

        var attributes = primitiveDef.attributes;

        for (var gltfAttributeName in attributes) {

            var threeAttributeName = ATTRIBUTES[gltfAttributeName];
            var bufferAttribute = accessors[attributes[gltfAttributeName]];

            // Skip attributes already provided by e.g. Draco extension.
            if (!threeAttributeName) continue;
            if (threeAttributeName in geometry.attributes) continue;

            geometry.addAttribute(threeAttributeName, bufferAttribute);

        }

        if (primitiveDef.indices !== undefined && !geometry.index) {

            geometry.setIndex(accessors[primitiveDef.indices]);

        }

        if (primitiveDef.targets !== undefined) {

            addMorphTargets(geometry, primitiveDef.targets, accessors);

        }

        if (primitiveDef.extras !== undefined) {

            geometry.userData = primitiveDef.extras;

        }

    }

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#geometry
     *
     * Creates BufferGeometries from primitives.
     * If we can build a single BufferGeometry with .groups from multiple primitives, returns one BufferGeometry.
     * Otherwise, returns BufferGeometries without .groups as many as primitives.
     *
     * @param {Array<Object>} primitives
     * @return {Promise<Array<THREE.BufferGeometry>>}
     */
    GLTFParser.prototype.loadGeometries = function (primitives) {

        var parser = this;
        var extensions = this.extensions;
        var cache = this.primitiveCache;

        var isMultiPass = isMultiPassGeometry(primitives);
        var originalPrimitives;

        if (isMultiPass) {

            originalPrimitives = primitives; // save original primitives and use later

            // We build a single BufferGeometry with .groups from multiple primitives
            // because all primitives share the same attributes/morph/mode and have indices.

            primitives = [primitives[0]];

            // Sets .groups and combined indices to a geometry later in this method.

        }

        return this.getDependencies('accessor').then(function (accessors) {

            var pending = [];

            for (var i = 0, il = primitives.length; i < il; i++) {

                var primitive = primitives[i];

                // See if we've already created this geometry
                var cached = getCachedGeometry(cache, primitive);

                if (cached) {

                    // Use the cached geometry if it exists
                    pending.push(cached);

                } else if (primitive.extensions && primitive.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION]) {

                    // Use DRACO geometry if available
                    var geometryPromise = extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION]
                        .decodePrimitive(primitive, parser)
                        .then(function (geometry) {

                            addPrimitiveAttributes(geometry, primitive, accessors);

                            return geometry;

                        });

                    cache.push({primitive: primitive, promise: geometryPromise});

                    pending.push(geometryPromise);

                } else {

                    // Otherwise create a new geometry
                    var geometry = new THREE.BufferGeometry();

                    addPrimitiveAttributes(geometry, primitive, accessors);

                    var geometryPromise = Promise.resolve(geometry);

                    // Cache this geometry
                    cache.push({primitive: primitive, promise: geometryPromise});

                    pending.push(geometryPromise);

                }

            }

            return Promise.all(pending).then(function (geometries) {

                if (isMultiPass) {

                    var baseGeometry = geometries[0];

                    // See if we've already created this combined geometry
                    var cache = parser.multiPassGeometryCache;
                    var cached = getCachedMultiPassGeometry(cache, baseGeometry, originalPrimitives);

                    if (cached !== null) return [cached.geometry];

                    // Cloning geometry because of index override.
                    // Attributes can be reused so cloning by myself here.
                    var geometry = new THREE.BufferGeometry();

                    geometry.name = baseGeometry.name;
                    geometry.userData = baseGeometry.userData;

                    for (var key in baseGeometry.attributes) geometry.addAttribute(key, baseGeometry.attributes[key]);
                    for (var key in baseGeometry.morphAttributes) geometry.morphAttributes[key] = baseGeometry.morphAttributes[key];

                    var indices = [];
                    var offset = 0;

                    for (var i = 0, il = originalPrimitives.length; i < il; i++) {

                        var accessor = accessors[originalPrimitives[i].indices];

                        for (var j = 0, jl = accessor.count; j < jl; j++) indices.push(accessor.array[j]);

                        geometry.addGroup(offset, accessor.count, i);

                        offset += accessor.count;

                    }

                    geometry.setIndex(indices);

                    cache.push({geometry: geometry, baseGeometry: baseGeometry, primitives: originalPrimitives});

                    return [geometry];

                } else if (geometries.length > 1 && THREE.BufferGeometryUtils !== undefined) {

                    // Tries to merge geometries with BufferGeometryUtils if possible

                    for (var i = 1, il = primitives.length; i < il; i++) {

                        // can't merge if draw mode is different
                        if (primitives[0].mode !== primitives[i].mode) return geometries;

                    }

                    // See if we've already created this combined geometry
                    var cache = parser.multiplePrimitivesCache;
                    var cached = getCachedCombinedGeometry(cache, geometries);

                    if (cached) {

                        if (cached.geometry !== null) return [cached.geometry];

                    } else {

                        var geometry = THREE.BufferGeometryUtils.mergeBufferGeometries(geometries, true);

                        cache.push({geometry: geometry, baseGeometries: geometries});

                        if (geometry !== null) return [geometry];

                    }

                }

                return geometries;

            });

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#meshes
     * @param {number} meshIndex
     * @return {Promise<THREE.Group|THREE.Mesh|THREE.SkinnedMesh>}
     */
    GLTFParser.prototype.loadMesh = function (meshIndex) {

        var scope = this;
        var json = this.json;
        var extensions = this.extensions;

        var meshDef = this.json.meshes[meshIndex];

        return this.getMultiDependencies([

            'accessor',
            'material'

        ]).then(function (dependencies) {

            var primitives = meshDef.primitives;
            var originalMaterials = [];

            for (var i = 0, il = primitives.length; i < il; i++) {

                originalMaterials[i] = primitives[i].material === undefined
                    ? createDefaultMaterial()
                    : dependencies.materials[primitives[i].material];

            }

            return scope.loadGeometries(primitives).then(function (geometries) {

                var isMultiMaterial = geometries.length === 1 && geometries[0].groups.length > 0;

                var meshes = [];

                for (var i = 0, il = geometries.length; i < il; i++) {

                    var geometry = geometries[i];
                    var primitive = primitives[i];

                    // 1. create Mesh

                    var mesh;

                    var material = isMultiMaterial ? originalMaterials : originalMaterials[i];

                    if (primitive.mode === WEBGL_CONSTANTS.TRIANGLES ||
                        primitive.mode === WEBGL_CONSTANTS.TRIANGLE_STRIP ||
                        primitive.mode === WEBGL_CONSTANTS.TRIANGLE_FAN ||
                        primitive.mode === undefined) {

                        // .isSkinnedMesh isn't in glTF spec. See .markDefs()
                        mesh = meshDef.isSkinnedMesh === true
                            ? new THREE.SkinnedMesh(geometry, material)
                            : new THREE.Mesh(geometry, material);

                        if (primitive.mode === WEBGL_CONSTANTS.TRIANGLE_STRIP) {

                            mesh.drawMode = THREE.TriangleStripDrawMode;

                        } else if (primitive.mode === WEBGL_CONSTANTS.TRIANGLE_FAN) {

                            mesh.drawMode = THREE.TriangleFanDrawMode;

                        }

                    } else if (primitive.mode === WEBGL_CONSTANTS.LINES) {

                        mesh = new THREE.LineSegments(geometry, material);

                    } else if (primitive.mode === WEBGL_CONSTANTS.LINE_STRIP) {

                        mesh = new THREE.Line(geometry, material);

                    } else if (primitive.mode === WEBGL_CONSTANTS.LINE_LOOP) {

                        mesh = new THREE.LineLoop(geometry, material);

                    } else if (primitive.mode === WEBGL_CONSTANTS.POINTS) {

                        mesh = new THREE.Points(geometry, material);

                    } else {

                        throw new Error('THREE.GLTFLoader: Primitive mode unsupported: ' + primitive.mode);

                    }

                    if (Object.keys(mesh.geometry.morphAttributes).length > 0) {

                        updateMorphTargets(mesh, meshDef);

                    }

                    mesh.name = meshDef.name || ('mesh_' + meshIndex);

                    if (geometries.length > 1) mesh.name += '_' + i;

                    if (meshDef.extras !== undefined) mesh.userData = meshDef.extras;

                    meshes.push(mesh);

                    // 2. update Material depending on Mesh and BufferGeometry

                    var materials = isMultiMaterial ? mesh.material : [mesh.material];

                    var useVertexColors = geometry.attributes.color !== undefined;
                    var useFlatShading = geometry.attributes.normal === undefined;
                    var useSkinning = mesh.isSkinnedMesh === true;
                    var useMorphTargets = Object.keys(geometry.morphAttributes).length > 0;
                    var useMorphNormals = useMorphTargets && geometry.morphAttributes.normal !== undefined;

                    for (var j = 0, jl = materials.length; j < jl; j++) {

                        var material = materials[j];

                        if (mesh.isPoints) {

                            var cacheKey = 'PointsMaterial:' + material.uuid;

                            var pointsMaterial = scope.cache.get(cacheKey);

                            if (!pointsMaterial) {

                                pointsMaterial = new THREE.PointsMaterial();
                                THREE.Material.prototype.copy.call(pointsMaterial, material);
                                pointsMaterial.color.copy(material.color);
                                pointsMaterial.map = material.map;
                                pointsMaterial.lights = false; // PointsMaterial doesn't support lights yet

                                scope.cache.add(cacheKey, pointsMaterial);

                            }

                            material = pointsMaterial;

                        } else if (mesh.isLine) {

                            var cacheKey = 'LineBasicMaterial:' + material.uuid;

                            var lineMaterial = scope.cache.get(cacheKey);

                            if (!lineMaterial) {

                                lineMaterial = new THREE.LineBasicMaterial();
                                THREE.Material.prototype.copy.call(lineMaterial, material);
                                lineMaterial.color.copy(material.color);
                                lineMaterial.lights = false; // LineBasicMaterial doesn't support lights yet

                                scope.cache.add(cacheKey, lineMaterial);

                            }

                            material = lineMaterial;

                        }

                        // Clone the material if it will be modified
                        if (useVertexColors || useFlatShading || useSkinning || useMorphTargets) {

                            var cacheKey = 'ClonedMaterial:' + material.uuid + ':';

                            if (material.isGLTFSpecularGlossinessMaterial) cacheKey += 'specular-glossiness:';
                            if (useSkinning) cacheKey += 'skinning:';
                            if (useVertexColors) cacheKey += 'vertex-colors:';
                            if (useFlatShading) cacheKey += 'flat-shading:';
                            if (useMorphTargets) cacheKey += 'morph-targets:';
                            if (useMorphNormals) cacheKey += 'morph-normals:';

                            var cachedMaterial = scope.cache.get(cacheKey);

                            if (!cachedMaterial) {

                                cachedMaterial = material.isGLTFSpecularGlossinessMaterial
                                    ? extensions[EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS].cloneMaterial(material)
                                    : material.clone();

                                if (useSkinning) cachedMaterial.skinning = true;
                                if (useVertexColors) cachedMaterial.vertexColors = THREE.VertexColors;
                                if (useFlatShading) cachedMaterial.flatShading = true;
                                if (useMorphTargets) cachedMaterial.morphTargets = true;
                                if (useMorphNormals) cachedMaterial.morphNormals = true;

                                scope.cache.add(cacheKey, cachedMaterial);

                            }

                            material = cachedMaterial;

                        }

                        materials[j] = material;

                        // workarounds for mesh and geometry

                        if (material.aoMap && geometry.attributes.uv2 === undefined && geometry.attributes.uv !== undefined) {

                            console.log('THREE.GLTFLoader: Duplicating UVs to support aoMap.');
                            geometry.addAttribute('uv2', new THREE.BufferAttribute(geometry.attributes.uv.array, 2));

                        }

                        if (material.isGLTFSpecularGlossinessMaterial) {

                            // for GLTFSpecularGlossinessMaterial(ShaderMaterial) uniforms runtime update
                            mesh.onBeforeRender = extensions[EXTENSIONS.KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS].refreshUniforms;

                        }

                    }

                    mesh.material = isMultiMaterial ? materials : materials[0];

                }

                if (meshes.length === 1) {

                    return meshes[0];

                }

                var group = new THREE.Group();

                for (var i = 0, il = meshes.length; i < il; i++) {

                    group.add(meshes[i]);

                }

                return group;

            });

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#cameras
     * @param {number} cameraIndex
     * @return {Promise<THREE.Camera>}
     */
    GLTFParser.prototype.loadCamera = function (cameraIndex) {

        var camera;
        var cameraDef = this.json.cameras[cameraIndex];
        var params = cameraDef[cameraDef.type];

        if (!params) {

            console.warn('THREE.GLTFLoader: Missing camera parameters.');
            return;

        }

        if (cameraDef.type === 'perspective') {

            camera = new THREE.PerspectiveCamera(THREE.Math.radToDeg(params.yfov), params.aspectRatio || 1, params.znear || 1, params.zfar || 2e6);

        } else if (cameraDef.type === 'orthographic') {

            camera = new THREE.OrthographicCamera(params.xmag / -2, params.xmag / 2, params.ymag / 2, params.ymag / -2, params.znear, params.zfar);

        }

        if (cameraDef.name !== undefined) camera.name = cameraDef.name;
        if (cameraDef.extras) camera.userData = cameraDef.extras;

        return Promise.resolve(camera);

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#skins
     * @param {number} skinIndex
     * @return {Promise<Object>}
     */
    GLTFParser.prototype.loadSkin = function (skinIndex) {

        var skinDef = this.json.skins[skinIndex];

        var skinEntry = {joints: skinDef.joints};

        if (skinDef.inverseBindMatrices === undefined) {

            return Promise.resolve(skinEntry);

        }

        return this.getDependency('accessor', skinDef.inverseBindMatrices).then(function (accessor) {

            skinEntry.inverseBindMatrices = accessor;

            return skinEntry;

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#animations
     * @param {number} animationIndex
     * @return {Promise<THREE.AnimationClip>}
     */
    GLTFParser.prototype.loadAnimation = function (animationIndex) {

        var json = this.json;

        var animationDef = this.json.animations[animationIndex];

        return this.getMultiDependencies([

            'accessor',
            'node'

        ]).then(function (dependencies) {

            var tracks = [];

            for (var i = 0, il = animationDef.channels.length; i < il; i++) {

                var channel = animationDef.channels[i];
                var sampler = animationDef.samplers[channel.sampler];

                if (sampler) {

                    var target = channel.target;
                    var name = target.node !== undefined ? target.node : target.id; // NOTE: target.id is deprecated.
                    var input = animationDef.parameters !== undefined ? animationDef.parameters[sampler.input] : sampler.input;
                    var output = animationDef.parameters !== undefined ? animationDef.parameters[sampler.output] : sampler.output;

                    var inputAccessor = dependencies.accessors[input];
                    var outputAccessor = dependencies.accessors[output];

                    var node = dependencies.nodes[name];

                    if (node) {

                        node.updateMatrix();
                        node.matrixAutoUpdate = true;

                        var TypedKeyframeTrack;

                        switch (PATH_PROPERTIES[target.path]) {

                            case PATH_PROPERTIES.weights:

                                TypedKeyframeTrack = THREE.NumberKeyframeTrack;
                                break;

                            case PATH_PROPERTIES.rotation:

                                TypedKeyframeTrack = THREE.QuaternionKeyframeTrack;
                                break;

                            case PATH_PROPERTIES.position:
                            case PATH_PROPERTIES.scale:
                            default:

                                TypedKeyframeTrack = THREE.VectorKeyframeTrack;
                                break;

                        }

                        var targetName = node.name ? node.name : node.uuid;

                        var interpolation = sampler.interpolation !== undefined ? INTERPOLATION[sampler.interpolation] : THREE.InterpolateLinear;

                        var targetNames = [];

                        if (PATH_PROPERTIES[target.path] === PATH_PROPERTIES.weights) {

                            // node can be THREE.Group here but
                            // PATH_PROPERTIES.weights(morphTargetInfluences) should be
                            // the property of a mesh object under group.

                            node.traverse(function (object) {

                                if (object.isMesh === true && object.morphTargetInfluences) {

                                    targetNames.push(object.name ? object.name : object.uuid);

                                }

                            });

                        } else {

                            targetNames.push(targetName);

                        }

                        // KeyframeTrack.optimize() will modify given 'times' and 'values'
                        // buffers before creating a truncated copy to keep. Because buffers may
                        // be reused by other tracks, make copies here.
                        for (var j = 0, jl = targetNames.length; j < jl; j++) {

                            var track = new TypedKeyframeTrack(
                                targetNames[j] + '.' + PATH_PROPERTIES[target.path],
                                THREE.AnimationUtils.arraySlice(inputAccessor.array, 0),
                                THREE.AnimationUtils.arraySlice(outputAccessor.array, 0),
                                interpolation
                            );

                            // Here is the trick to enable custom interpolation.
                            // Overrides .createInterpolant in a factory method which creates custom interpolation.
                            if (sampler.interpolation === 'CUBICSPLINE') {

                                track.createInterpolant = function InterpolantFactoryMethodGLTFCubicSpline(result) {

                                    // A CUBICSPLINE keyframe in glTF has three output values for each input value,
                                    // representing inTangent, splineVertex, and outTangent. As a result, track.getValueSize()
                                    // must be divided by three to get the interpolant's sampleSize argument.

                                    return new GLTFCubicSplineInterpolant(this.times, this.values, this.getValueSize() / 3, result);

                                };

                                // Workaround, provide an alternate way to know if the interpolant type is cubis spline to track.
                                // track.getInterpolation() doesn't return valid value for custom interpolant.
                                track.createInterpolant.isInterpolantFactoryMethodGLTFCubicSpline = true;

                            }

                            tracks.push(track);

                        }

                    }

                }

            }

            var name = animationDef.name !== undefined ? animationDef.name : 'animation_' + animationIndex;

            return new THREE.AnimationClip(name, undefined, tracks);

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#nodes-and-hierarchy
     * @param {number} nodeIndex
     * @return {Promise<THREE.Object3D>}
     */
    GLTFParser.prototype.loadNode = function (nodeIndex) {

        var json = this.json;
        var extensions = this.extensions;

        var meshReferences = this.json.meshReferences;
        var meshUses = this.json.meshUses;

        var nodeDef = this.json.nodes[nodeIndex];

        return this.getMultiDependencies([

            'mesh',
            'skin',
            'camera',
            'light'

        ]).then(function (dependencies) {

            var node;

            // .isBone isn't in glTF spec. See .markDefs
            if (nodeDef.isBone === true) {

                node = new THREE.Bone();

            } else if (nodeDef.mesh !== undefined) {

                var mesh = dependencies.meshes[nodeDef.mesh];

                node = mesh.clone();

                // for Specular-Glossiness
                if (mesh.isGroup === true) {

                    for (var i = 0, il = mesh.children.length; i < il; i++) {

                        var child = mesh.children[i];

                        if (child.material && child.material.isGLTFSpecularGlossinessMaterial === true) {

                            node.children[i].onBeforeRender = child.onBeforeRender;

                        }

                    }

                } else {

                    if (mesh.material && mesh.material.isGLTFSpecularGlossinessMaterial === true) {

                        node.onBeforeRender = mesh.onBeforeRender;

                    }

                }

                if (meshReferences[nodeDef.mesh] > 1) {

                    node.name += '_instance_' + meshUses[nodeDef.mesh]++;

                }

            } else if (nodeDef.camera !== undefined) {

                node = dependencies.cameras[nodeDef.camera];

            } else if (nodeDef.extensions
                && nodeDef.extensions[EXTENSIONS.KHR_LIGHTS]
                && nodeDef.extensions[EXTENSIONS.KHR_LIGHTS].light !== undefined) {

                var lights = extensions[EXTENSIONS.KHR_LIGHTS].lights;
                node = lights[nodeDef.extensions[EXTENSIONS.KHR_LIGHTS].light];

            } else {

                node = new THREE.Object3D();

            }

            if (nodeDef.name !== undefined) {

                node.name = THREE.PropertyBinding.sanitizeNodeName(nodeDef.name);

            }

            if (nodeDef.extras) node.userData = nodeDef.extras;

            if (nodeDef.extensions) addUnknownExtensionsToUserData(extensions, node, nodeDef);

            if (nodeDef.matrix !== undefined) {

                var matrix = new THREE.Matrix4();
                matrix.fromArray(nodeDef.matrix);
                node.applyMatrix(matrix);

            } else {

                if (nodeDef.translation !== undefined) {

                    node.position.fromArray(nodeDef.translation);

                }

                if (nodeDef.rotation !== undefined) {

                    node.quaternion.fromArray(nodeDef.rotation);

                }

                if (nodeDef.scale !== undefined) {

                    node.scale.fromArray(nodeDef.scale);

                }

            }

            return node;

        });

    };

    /**
     * Specification: https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#scenes
     * @param {number} sceneIndex
     * @return {Promise<THREE.Scene>}
     */
    GLTFParser.prototype.loadScene = function () {

        // scene node hierachy builder

        function buildNodeHierachy(nodeId, parentObject, json, allNodes, skins) {

            var node = allNodes[nodeId];
            var nodeDef = json.nodes[nodeId];

            // build skeleton here as well

            if (nodeDef.skin !== undefined) {

                var meshes = node.isGroup === true ? node.children : [node];

                for (var i = 0, il = meshes.length; i < il; i++) {

                    var mesh = meshes[i];
                    var skinEntry = skins[nodeDef.skin];

                    var bones = [];
                    var boneInverses = [];

                    for (var j = 0, jl = skinEntry.joints.length; j < jl; j++) {

                        var jointId = skinEntry.joints[j];
                        var jointNode = allNodes[jointId];

                        if (jointNode) {

                            bones.push(jointNode);

                            var mat = new THREE.Matrix4();

                            if (skinEntry.inverseBindMatrices !== undefined) {

                                mat.fromArray(skinEntry.inverseBindMatrices.array, j * 16);

                            }

                            boneInverses.push(mat);

                        } else {

                            console.warn('THREE.GLTFLoader: Joint "%s" could not be found.', jointId);

                        }

                    }

                    mesh.bind(new THREE.Skeleton(bones, boneInverses), mesh.matrixWorld);

                }

            }

            // build node hierachy

            parentObject.add(node);

            if (nodeDef.children) {

                var children = nodeDef.children;

                for (var i = 0, il = children.length; i < il; i++) {

                    var child = children[i];
                    buildNodeHierachy(child, node, json, allNodes, skins);

                }

            }

        }

        return function loadScene(sceneIndex) {

            var json = this.json;
            var extensions = this.extensions;
            var sceneDef = this.json.scenes[sceneIndex];

            return this.getMultiDependencies([

                'node',
                'skin'

            ]).then(function (dependencies) {

                var scene = new THREE.Scene();
                if (sceneDef.name !== undefined) scene.name = sceneDef.name;

                if (sceneDef.extras) scene.userData = sceneDef.extras;

                if (sceneDef.extensions) addUnknownExtensionsToUserData(extensions, scene, sceneDef);

                var nodeIds = sceneDef.nodes || [];

                for (var i = 0, il = nodeIds.length; i < il; i++) {

                    buildNodeHierachy(nodeIds[i], scene, json, dependencies.nodes, dependencies.skins);

                }

                // Ambient lighting, if present, is always attached to the scene root.
                if (sceneDef.extensions
                    && sceneDef.extensions[EXTENSIONS.KHR_LIGHTS]
                    && sceneDef.extensions[EXTENSIONS.KHR_LIGHTS].light !== undefined) {

                    var lights = extensions[EXTENSIONS.KHR_LIGHTS].lights;
                    scene.add(lights[sceneDef.extensions[EXTENSIONS.KHR_LIGHTS].light]);

                }

                return scene;

            });

        };

    }();

    return GLTFLoader;

})();


var MTLLoader = THREE.MTLLoader;
var OBJLoader = THREE.OBJLoader;
var OrbitControls = THREE.OrbitControls;
var ColladaLoader = THREE.ColladaLoader;
var GLTFLoader = THREE.GLTFLoader;

//model-json
//ModelLoader.obj
//ModelLoader.objmtl
//ModelLoader.dae
//ModelLoader.gltf


export {OrbitControls, MTLLoader, OBJLoader, ColladaLoader, GLTFLoader};
