import React from 'react';
import pre from './img/pre.png';
import usage1 from './img/usage1.png';
import usage2 from './img/usage2.png';
import usage3 from './img/usage3.png';
import usage4 from './img/usage4.png';
import usage5 from './img/usage5.png';
import usage6 from './img/usage6.png';
import usage1_intro from './img/usage1_intro.png';
import usage2_intro from './img/usage2_intro.png';
import usage3_intro from './img/usage3_intro.png';
import usage4_intro from './img/usage4_intro.png';
import usage5_intro from './img/usage5_intro.png';
import usage6_intro from './img/usage6_intro.png';
import usage1_intro_img from './img/usage1_intro_img.png';
import usage2_intro_img from './img/usage2_intro_img.png';
import usage3_intro_img from './img/usage3_intro_img.png';
import usage4_intro_img from './img/usage4_intro_img.png';
import usage5_intro_img from './img/usage5_intro_img.png';
import usage6_intro_img from './img/usage6_intro_img.png';
import usage1v from './video/usage1.mp4';
import usage2v from './video/usage2.mp4';
import usage3v from './video/usage3.mp4';
import usage4v from './video/usage4.mp4';
import usage5v from './video/usage5.mp4';
import usage6v from './video/usage6.mp4';
import './KnifeUsage.css';
import {Button} from "antd";
import {Row, Col} from 'antd';
import {Player} from 'video-react';

const introArr = ['针刀体以进针刀点的皮肤为中心，刀刃端在体内沿刀口线方向做纵向的运动。主要以刀刃及接近刀刃的部分刀体为作用部位。其运动距离以厘米为单位，范围根据病情而定。进刀至剥离处组织而切开粘连等病变组织。如果疏通阻力过大，可以沿着肌或腱等病变组织的纤维走行方向切开，则可顺利进行纵行疏通。',
    '横行剥离法是在纵行疏通法的基础上进行的。针刀体以进针刀点的皮肤为中心，刀刃端在体内垂直刀口线方向做横向的运动。横行剥离使粘连、瘢痕等组织在纵向松解的基础上进一步加大松解力度，其运动距离以厘米为单位，范围根据病情而定。',
    '纵行疏通法与横行剥离法是针刀手术操作的最基本和最常用的刀法。临床上常将纵行疏通法与横行剥离法相结合使用，简称纵疏横剥法，纵疏横剥1次为1刀。',
    '刀刃到达病变部位以后，切割第1刀，然后针刀上提0.5cm，再向下插入0.5cm，切割第2刀，如此提插3刀为宜。适用于粘连面大、粘连重的病变。如切开棘间韧带，挛缩的肌腱、韧带、关节囊等。',
    '针刀到达骨面，刀刃沿骨面或骨嵴将粘连的组织从骨面上铲开，感觉针刀下有松动感时为度。此法适用于骨质表面或者骨质边缘的软组织（肌肉起止点、韧带及筋膜的骨附着点）病变。如肩周炎喙突点、肱骨外上髁点、枕骨上下项线点等的松解。',
    '针刀刺破囊壁并穿透囊性病灶，起减压和引流等作用。此法适用于各类滑液囊病变、髌下脂肪垫损伤等疾病。'];
const imgArr = [usage1_intro,
    usage2_intro,
    usage3_intro,
    usage4_intro, usage5_intro, usage6_intro];
const img2Arr = [usage1_intro_img,
    usage2_intro_img,
    usage3_intro_img,
    usage4_intro_img, usage5_intro_img, usage6_intro_img];
const videoArr = [usage1v,
    usage2v,
    usage3v,
    usage4v, usage5v, usage6v];

class KnifeUsage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    changeContent = index => {
        this.setState({
            index: index
        })
    };

    render() {
        return (
            <div className="KnifeUsage">
                <Button style={{marginLeft: "90%", marginTop: "50px"}} type="default" href="/main">返回</Button>

                <Row>
                    <Col span={4}>
                        <header className="Usage-header">
                            <Button type="link" onClick={() => this.changeContent(0)} ghost={true}><img height="105"
                                                                                                        width="220"
                                                                                                        src={usage1}
                                                                                                        alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '92px'}} onClick={() => this.changeContent(1)}
                                    ghost={true}><img
                                height="105" width="220"
                                src={usage2}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '92px'}} onClick={() => this.changeContent(2)}
                                    ghost={true}><img
                                height="105" width="220"
                                src={usage3}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '92px'}} onClick={() => this.changeContent(3)}
                                    ghost={true}><img
                                height="105" width="220"
                                src={usage4}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '92px'}} onClick={() => this.changeContent(4)}
                                    ghost={true}><img
                                height="105" width="220"
                                src={usage5}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '92px'}} onClick={() => this.changeContent(5)}
                                    ghost={true}><img
                                height="105" width="220"
                                src={usage6}
                                alt={"login"}/></Button>
                        </header>
                    </Col>
                    <Col span={18}>
                        <div className="Usage" style={{backgroundImage: `url(${imgArr[this.state.index]})`}}>
                            <div className="Usage-Text">
                                {introArr[this.state.index]}
                            </div>
                            <img className="Usage-Image" src={img2Arr[this.state.index]}/>
                            <div className="Usage-Video">
                                <Player
                                    playsInline
                                    poster={pre}
                                    src={videoArr[this.state.index]}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default KnifeUsage;
