import React from 'react';
import './KnifeAfter.css';
import {Button, Checkbox, Menu, Radio} from "antd";
import btn_ok from "./img/btn_ok.png";
import after1_btn from "./img/after1_btn.png";
import after2_btn from "./img/after2_btn.png";

let questions = [
    {
        title: "下列哪些是针刀操作过程中可能出现的意外情况？",
        a: "A、晕针刀",
        b: "B、断针刀",
        c: "C、异物遗留",
        d: "D、周围神经损伤",
    },
    {
        title: "下列什么情况下容易发生晕针刀？",
        a: "A、饭后",
        b: "B、剧烈运动后",
        c: "C、饥饿后",
        d: "D、大病后",
    },
    {
        title: "什么体位晕针刀发生率低？",
        a: "A、正坐位",
        b: "B、俯坐位",
        c: "C、仰坐位",
        d: "D、俯卧位",
    },
    {
        title: "关于晕针刀的处理,下列哪项是错误的？",
        a: "A、立即停止操作,将针刀起出",
        b: "B、卧床休息并抬高头部",
        c: "C、注意保暖，饮适量温或糖水",
        d: "D、严重者可针刺人中、素髎、内关、足三里、灸百会、气海、关元等穴",
    },
    {
        title: "为了预防出现晕针刀现象，下列做法错误的是？",
        a: "A、对于初次接受针刀治疗和精神紧张者，应先做好解释工作",
        b: "B、患者选择舒适持久的体位，尽量采取卧位",
        c: "C、嘱患者空腹进行针刀操作",
        d: "D、针刀治疗时，要密切注意患者的整体情况，如有晕针刀征兆，立即停止针刀治疗",
    },
    {
        title: "以下哪项原因的的出血是不可避免的",
        a: "A、医者对施术部位血管分布情况不够了解",
        b: "B、患者凝血因子缺乏",
        c: "C、针刀术结束后在针刀施术部位再行拔罐",
        d: "D、操作过程中，为了减轻患者痛苦，迅速进针",
    },
    {
        title: "用针灸急救时，可以选取什么穴位？",
        a: "A、合谷",
        b: "B、水沟 ",
        c: "C、内关",
        d: "D、三阴交",
    },
    {
        title: "断针刀常见原因包括？",
        a: "A、针具质量不好，韧性较差",
        b: "B、患者精神过于紧张",
        c: "C、针刀反复多次使用",
        d: "D、发生滞针刀",
    },
    {
        title: "关于断针刀处理正确的是？",
        a: "A、可用镊子钳出露出的针刀体残端",
        b: "B、若断端很短，在体表无法触及，就地手术取出针刀",
        c: "C、嘱患者不要恐惧",
        d: "D、必要时，可借助X线定位",
    },
    {
        title: "针刀治疗时患者适当体位的选择原则不包括？",
        a: "A、有利于针刀手术操作",
        b: "B、患者舒适自然",
        c: "C、能够较长时间保持稳定",
        d: "D、简便快捷原则",
    },
    {
        title: "针刀操作中出现出血主要由哪些原因引起？",
        a: "A、患者动脉硬化，使血管弹性下降",
        b: "B、患者凝血功能障碍",
        c: "C、医者对施术部位血管分布情况不够了解",
        d: "D、医者针刀术后未及时按压止血",
    },
    {
        title: "针刀操作中出现浅表血管出血时处理正确的是？",
        a: "A、表浅血管出血时用消毒干棉球压迫止血",
        b: "B、手足、头面、后枕部等小血管丰富处，针刀松解后无血就无需压迫",
        c: "C、常规按压针孔需3~5分钟",
        d: "D、若少量出血导致皮下青紫瘀斑者，可不必特殊处理，一般可自行消退",
    },
    {
        title: "针刀操作中出现深部血肿时处理正确的是？",
        a: "A、一般较小的血肿也需特殊处理，多不能自行吸收",
        b: "B、若局部肿胀疼痛明显或仍继续加重，可先做局部热敷、外擦活血化瘀药物，等以加速瘀血的消退和吸收,24小时后局部冷敷止血或肌注止血敏",
        c: "C、较大的血肿可在B超定位下穿刺抽除，同时局部用弹力绷带加压包扎",
        d: "D、穿刺治疗无效，血肿不消或继续增大时，可切开引流并止血",
    },
    {
        title: "下列不属于针刀操作禁忌症的是？",
        a: "A、凝血机制异常者",
        b: "B、患有严重糖尿病患者，血糖不能控制者",
        c: "C、患有高脂血症患者",
        d: "D、施术部位红肿灼热皮肤感染着",
    },
    {
        title: "如何预防针刀操作中出血现象？",
        a: "A、大幅度提插",
        b: "B、严格按照进针刀方法操作",
        c: "C、进针时快速用力进入深部",
        d: "D、若施术部位在骨面，松解时刀刃不能离开骨面",
    },
    {
        title: "针刀操作中出现周围神经损伤可能是什么原因？",
        a: "A、麻醉后施术，患者避让反应消失或不明显",
        b: "B、盲目追求快针，强刺激，重手法",
        c: "C、针刀术后，用适当手法矫形",
        d: "D、解剖知识了解不全面",
    },
    {
        title: "下列哪项是针刀操作中出现正中神经损伤主要表现？",
        a: "A、桡侧3个半手指掌侧及相应指远节背面皮肤感觉障碍",
        b: "B、第1、2掌骨背侧皮肤感觉减退或消失",
        c: "C、握拳试验、夹指试验阳性",
        d: "D、前臂屈肌无力，桡侧三指不能屈曲，拇指对掌功能障碍，日久可出现大鱼际萎缩、握拳无力、拇指与小指不能对捏",
    },
    {
        title: "下列哪项是针刀操作中出现桡神经损伤主要表现？",
        a: "A、拇指尖和食指尖不能相触成“O”形",
        b: "B、第1、2掌骨背侧皮肤感觉减退或消失",
        c: "C、握拳试验、合掌分掌试验阳性",
        d: "D、手部尺侧1个半手指感觉障碍",
    },
    {
        title: "下列哪项是针刀操作中出现尺神经损伤主要表现？",
        a: "A、小指、环指指间关节屈曲，掌指关节伸直，形成“爪状”畸形",
        b: "B、肱三头肌麻痹，出现主动伸直时关节障碍",
        c: "C、手部尺侧1个半手指感觉障碍",
        d: "D、握拳试验、夹指试验阳性",
    },
    {
        title: "下列哪项是针刀操作中出现坐骨神经损伤主要表现？",
        a: "A、趾、踝关节屈伸活动障碍",
        b: "B、主动屈曲膝关节困难",
        c: "C、小腿外侧、足部皮肤疼痛或感觉障碍，肌肉麻痹，出现垂足畸形",
        d: "D、握拳试验、夹指试验阳性",
    },
    {
        title: "下列哪项是针刀操作中出现腓总神经损伤主要表现？",
        a: "A、主动屈曲膝关节困难",
        b: "B、第1、2趾骨背侧皮肤感觉减退或消失",
        c: "C、足不能主动背屈及外翻，自然状态表现为足下垂",
        d: "D、小腿前外侧、足背部皮肤感觉障碍",
    },
    {
        title: "针刀操作中出现周围神经损伤如何处理？",
        a: "A、立即停止针刀操作",
        b: "B、若患者疼痛、麻木明显，可局部先行以麻药、类固醇类药、B族维生素等配伍封闭",
        c: "C、24小时后，给予热敷、理疗、口服中药，按照神经分布区行针灸治疗",
        d: "D、24小时后，局部轻揉按摩，在医生指导下加强功能锻炼",
    },
    {
        title: "下列预防针刀操作中出现周围神经损伤错误的是？",
        a: "A、治疗时摸索进针刀",
        b: "B、针刀术后向手术部位注射药物",
        c: "C、术前检查针刀是否带钩、毛糙、卷刃",
        d: "D、操作时切忌大幅度提插",
    },
    {
        title: "针刀操作中出现创伤性气胸如何处理？",
        a: "A、立即出针刀，让患者采取半卧位休息",
        b: "B、要求患者心情平静，切勿因恐惧而翻转身体",
        c: "C、给予镇咳消炎药物，以防止肺组织因咳嗽扩大创孔，加重漏气和感染",
        d: "D、对严重病例如发现呼吸困难、发绀、休克等现象需组织抢救",
    },
    {
        title: "针刀操作中出现创伤性气胸会出现哪些情况？",
        a: "A、胸闷、 胸痛、气短、心悸",
        b: "B、呼吸困难、发绀、出冷汗、烦躁、恐惧",
        c: "C、叩诊清音，听诊肺呼吸音减弱或消失，气管可向健侧移位",
        d: "D、X线胸部透视可见肺组织被压缩现象",
    },
    {
        title: "下列预防针刀操作中出现创伤性气胸的措施正确的是？",
        a: "A、术者思想集中，为患者选好适当体位",
        b: "B、大幅度施行提插手法",
        c: "C、根据患者体型肥瘦来掌握进针深度",
        d: "D、胸部、背部施术，宜直刺或斜刺",
    },
    {
        title: "以下措施对预防晕针刀无效的是？",
        a: "A、选择舒适持久的体位",
        b: "B、对患者做好解释工作",
        c: "C、医者操作手法稳、准、轻、巧",
        d: "D、针刀术前吃饱",
    },
    {
        title: "针刀操作中出现出现内脏损伤处理措施下列哪项正确？",
        a: "A、局部冷敷，48小时后热敷",
        b: "B、定时测量血压",
        c: "C、对于休克患者应不失时机的进行抢救",
        d: "D、不必特殊处理",
    },
    {
        title: "凡有脏器组织、大的血管、粗的神经处都应注意针刀刀口线方向？",
        a: "A、注意选择体位",
        b: "B、四肢处不宜深刺",
        c: "C、患有高脂血症患者",
        d: "D、严密观察患者情况，随时采取措施",
    },
    {
        title: "下列哪项属于针刀操作过程中患者因素？",
        a: "A、针刺角度、深度",
        b: "B、体位选择",
        c: "C、恐惧、紧张心理",
        d: "D、针具消毒",
    }
];

let answerArr = [new Set(["A", "B", "D"]),
    new Set(["B", "C", "D"]),
    new Set(["D"]),
    new Set(["B"]),
    new Set(["C"]),
    new Set(["C"]),
    new Set(["A", "B", "C"]),
    new Set(["A", "B", "C", "D"]),
    new Set(["A", "B", "C", "D"]),
    new Set(["D"]),
    new Set(["A", "B", "C", "D"]),
    new Set(["A", "C", "D"]),
    new Set(["C", "D"]),
    new Set(["A", "B", "D"]),
    new Set(["B", "D"]),
    new Set(["A", "B", "D"]),
    new Set(["A", "D"]),
    new Set(["B", "C"]),
    new Set(["A", "C", "D"]),
    new Set(["A", "B", "C"]),
    new Set(["C", "D"]),
    new Set(["A", "B", "C", "D"]),
    new Set(["B"]),
    new Set(["A", "B", "C", "D"]),
    new Set(["A", "B", "D"]),
    new Set(["A", "C"]),
    new Set(["D"]),
    new Set(["B", "C"]),
    new Set(["A", "B", "D"]),
    new Set(["C"])];

class KnifeAfter extends React.Component {
    constructor(props) {
        super(props);
        this.data = new Map();
        this.state = {
            current: 0,
            value: 0
        };
    }

    componentDidMount() {

    }

    changeContent = index => {
        this.setState({
            index: index
        })
    };

    onAfterOk() {
        this.setState({
            current: 1,
        });
    }

    eqSet(as, bs) {
        if (as.size !== bs.size) return false;
        for (var a of as) if (!bs.has(a)) return false;
        return true;
    }

    onAfterOk2() {
        let mistake = 0;
        let total = 100;
        if (this.state.value !== 1) {
            total = total - 2;
            mistake += 1;
        }
        answerArr.forEach((value, index) => {
            if (this.data.get(index) === undefined || !this.eqSet(this.data.get(index), value)) {
                total = total - 2;
                mistake += 1;
            }
            console.log(total);
        });
        localStorage.setItem("score", total);
        localStorage.setItem("mistake", mistake);
        fetch("/api/result/", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                token: localStorage.getItem("token"),
                un: localStorage.getItem("un"),
                startTime: localStorage.getItem("startTime"),
                score: total,
            })
        }).then((response) => {
            response.text().then(value => {
                console.log(value);
            });
        }).catch((error) => {
            console.log(error)
        });
        window.location.href = "/KnifeScore";
    }

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    onCheckChange = (i, e) => {
        console.log('click ', i, e);
        this.data.set(i, new Set(e));
        console.log('click after ', ...this.data);
    };

    render() {
        const radioStyle = {
            display: 'block',
            whiteSpace: 'pre-wrap',
            height: '100px',
            width: '1400px',
            fontSize: '16px',
            lineHeight: '30px',
        };

        let arr = [
            <div>
                <Radio.Group className="KnifeAfter-text1" onChange={this.onChange} value={this.state.value}>
                    <div style={{marginBottom: "20px"}}>单选题（2分）</div>
                    <Radio style={radioStyle} value={1}>
                        A. 患者仰卧，医生一手握住踝关节上方，另一手托住小腿上部，在牵拉状态下，摇晃、旋转伸屈膝关节，然后用在牵引状态下的推拿手法，将内外翻和轻度屈曲畸形纠正。
                    </Radio>
                    <Radio style={radioStyle} value={2}>
                        B. 患者仰卧，患肢伸直，医生拇指和其他四指张开，抓握住髌骨，用力上下(沿肢体纵轴)滑动髌骨，这样可使关节囊、支持韧带进一步松解。
                        医生手拿住患肢踝关节上缘，令患者屈膝屈髋，另一手拇指顶住髌骨上缘，再令患肢伸直，同时拇指用力向下顶推髌骨，用力方向为直下方和斜下方。对膝关节伸屈障碍者，用过伸过屈膝关节的镇定手法，在过伸过屈位置上各停留30秒。
                    </Radio>
                    <Radio style={radioStyle} value={3}>
                        C. 患者仰卧，屈膝屈髋90°，一助手握住股骨下端，施术者双手握持踝部，两者相对牵引，医生内、外旋转小腿，在牵引下，使膝关节尽量屈曲，再缓缓伸直。
                    </Radio>
                    <Radio style={radioStyle} value={4}>
                        D. 术者握住患侧小腿上段，嘱患者尽量伸屈膝关节，在最大伸膝位和最大屈膝位时，术者分别向相同方向弹压膝关节2次。 </Radio>
                </Radio.Group>
                <Button type="link" onClick={this.onAfterOk.bind(this)} style={{marginLeft: "1200px"}} ghost={true}><img
                    height="80" width="160" src={btn_ok} alt={"login"}/></Button>
            </div>,
            <section
                style={{overflowY: "scroll", width: '90%', paddingTop: "20px", marginLeft: "100px", height: "90%"}}>
                <div style={{fontSize: "20px"}}>不定向选择题（每题2分，共60分）</div>
                {questions.map((item, index) => (
                    <Checkbox.Group style={{width: '100%', marginTop: "20px", fontSize: "16px",}}
                                    onChange={(e) => this.onCheckChange(index, e)}>
                        <div>{index + 1}、{item.title}</div>
                        <Checkbox value="A">{item.a}</Checkbox>
                        <br/>
                        <Checkbox value="B">{item.b}</Checkbox>
                        <br/>
                        <Checkbox value="C">{item.c}</Checkbox>
                        <br/>
                        <Checkbox value="D">{item.d}</Checkbox>
                    </Checkbox.Group>
                ))}
                <Button type="link" onClick={() => this.onAfterOk2()} style={{marginLeft: "1200px"}} ghost={true}><img
                    height="80" width="160" src={btn_ok} alt={"login"}/></Button>
            </section>
        ];
        return (
            <div className="KnifeAfter">
                <Button style={{marginLeft: "90%", marginTop: "50px"}} type="default" href="/main">返回</Button>

                <Menu className="KnifeAfter-header" onClick={this.handleClick} selectedKeys={[this.state.current]}
                      mode="horizontal">
                    <Menu.Item style={{paddingRight: "0px", paddingLeft: "0px"}} key={0}>
                        <img src={after1_btn}/>
                    </Menu.Item>
                    <Menu.Item style={{paddingRight: "0px", paddingLeft: "0px"}} key={1}>
                        <img src={after2_btn}/>
                    </Menu.Item>
                </Menu>
                <div className="KnifeAfter-section1">
                    {arr[this.state.current]}
                </div>
            </div>
        );
    }
}

export default KnifeAfter;
