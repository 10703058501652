import React from "react";
import "./login.css";
import Login from "./Login";
import {Button, Checkbox} from "antd";
import button_login from "./img/button_login.png";

const Qs = require('qs');

const {Tab, UserName, Password, Mobile, Captcha, Submit} = Login;


const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
};


class Welcome extends React.Component {

    componentDidMount() {
        fetch("/api/verification/" + this.props.location.search, {
            method: 'GET',
        }).then((response) => {
            if (response.status !== 200) {
                alert("身份验证失败，请重新从实验空间进入系统");
                return;
            }
            response.text().then(value => {
                localStorage.setItem("token", Qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).token);
                localStorage.setItem("startTime", Date.now().toString());
                localStorage.setItem("un", value);
                console.log(value);
            });
        }).catch((error) => {
            alert(error)
        })
    }

    login() {
        window.location.href = "/main";

    }

    render() {
        return (
            <div className="App-login">
                <header className="App-header">
                    <div>
                        <Login
                            onTabChange={this.onTabChange}
                            onSubmit={this.login}
                            ref={form => {
                                this.loginForm = form;
                            }}
                        >
                            <UserName
                                name="userName"
                                placeholder="用户名"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            />
                            <Password
                                name="password"
                                placeholder="密码"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                onPressEnter={e => {
                                    e.preventDefault();
                                    this.loginForm.validateFields(this.handleSubmit);
                                }}
                            />
                            <div>
                                <Checkbox onChange={this.changeAutoLogin}>
                                    记住我
                                </Checkbox>
                                <a style={{float: 'right'}}>
                                    忘记密码
                                </a>
                            </div>
                            <Submit style={{marginTop: '20px', marginLeft: '85px'}}>
                                登录
                            </Submit>
                            <Button type="link" href="/main" ghost={true}><img height="80" width="200"
                                                                               src={button_login}
                                                                               alt={"login"}/></Button>
                        </Login>
                    </div>
                    <h3 className="usage">本虚拟仿真实验项目适配的电脑屏幕的尺寸是21寸，请使用1600*900分辨率全屏使用</h3>
                    <h2 className="usage1">各位专家可以点击游客登陆进入实验，无须注册</h2>
                </header>
            </div>)
    }
}

export default Welcome;
