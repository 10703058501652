import React from 'react';
import needle_shank from './img/needle_shank.png';
import needle_body from './img/needle_body.png';
import needle_blade from './img/needle_blade.png';
import needle_line from './img/needle_line.png';
import './KnifeIntro.css';
import {Button} from "antd";
import {Row, Col} from 'antd';
import {DirectionLight, GLTFModel, AmbientLight} from './3d/main'


const total = require('./assets/total.glb');
const total1 = require('./assets/1.glb');
const total2 = require('./assets/2.glb');
const total3 = require('./assets/3.glb');
const total4 = require('./assets/4.glb');

const introArr = ['整体','针刀柄是针刀体尾端的扁平结构。',
    '针刀体是针刀刃和针刀柄之间的部分，是针刀刺入人体发挥作用的主要部分。',
    '针刀刃是针刀体前端的平刃，是针刀刺入人体发挥作用的关键部分。',
    '针刀的刀刃端呈线形刃口，称刀口线，其方向与针刀柄一致。'];
const modelArr = [total,
    total1,
    total2,
    total3, total4];

class KnifeIntro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    changeContent = index => {
        this.setState({
            index:index+1
        })
    };

    render() {
        return (
            <div className="Knife">
                <Button style={{marginLeft: "90%", marginTop: "50px"}} type="default" href="/main">返回</Button>
                <Row>
                    <Col span={8}>
                        <header className="Knife-header">
                            <Button type="link" onClick={() => this.changeContent(0)} ghost={true}><img height="60"
                                                                                                        width="200"
                                                                                                        src={needle_shank}
                                                                                                        alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '90px'}} onClick={() => this.changeContent(1)}
                                    ghost={true}><img
                                height="60" width="200"
                                src={needle_body}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '90px'}} onClick={() => this.changeContent(2)}
                                    ghost={true}><img
                                height="60" width="200"
                                src={needle_blade}
                                alt={"login"}/></Button>
                            <Button type="link" style={{marginTop: '90px'}} onClick={() => this.changeContent(3)}
                                    ghost={true}><img
                                height="60" width="200"
                                src={needle_line}
                                alt={"login"}/></Button>
                        </header>
                    </Col>
                    <Col span={8}>
                        <div className="Knife-Model">
                            <GLTFModel
                                src={modelArr[this.state.index]}
                                width="500" height="500"
                                scale={{x: .5, y: .5, z: .5}}
                                rotation={{x: 1.1, y: 0, z: 0}}
                                position={{x: 0, y: -0.3, z: 0}}
                                enableRotate={true}
                                onLoad={() => {
                                  console.log("onload");
                                }}
                            >
                                <AmbientLight color={0xffffff}/>
                                <DirectionLight color={0xffffff} position={{x: 100, y: 200, z: 100}}/>
                            </GLTFModel>
                        </div>

                    </Col>
                    <Col span={8} pull={1}>
                        <div className="Knife-Text">
                            {introArr[this.state.index]}
                        </div>
                        <div className="Knife-Tip" >
                            提示：将鼠标放在模型上，使用左键拖动针刀旋转，使用滚轮缩放。
                        </div>
                    </Col>
                </Row>


            </div>
        );
    }
}

export default KnifeIntro;
