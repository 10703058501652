import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Welcome from "./Welcome";
import KnifeIntro from "./KnifeIntro";
import KnifeUsage from "./KnifeUsage";
import KnifeExample from "./KnifeExample";
import KnifeExample1 from "./KnifeExample1";
import KnifeAfter from "./KnifeAfter";
import KnifeScore from "./KnifeScore";
import KnifeAnswer from "./KnifeAnswer";

ReactDOM.render( <Router>
    <div>
        <Route exact path="/" component={Welcome} />
        <Route path="/main" component={App} />
        <Route path="/knife_intro" component={KnifeIntro} />
        <Route path="/KnifeUsage" component={KnifeUsage} />
        <Route path="/KnifeExample" component={KnifeExample} />
        <Route path="/KnifeExample1" component={KnifeExample1} />
        <Route path="/KnifeAfter" component={KnifeAfter} />
        <Route path="/KnifeScore" component={KnifeScore} />
        <Route path="/KnifeAnswer" component={KnifeAnswer} />
    </div>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
