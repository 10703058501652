import React from 'react';
import button_intro from './img/button_intro.png';
import button_intro2 from './img/button_intro2.png';
import button_case from './img/button_case.png';
import './App.css';
import {Button, Checkbox} from "antd";


class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="App">
                <div className="menu">
                    <Button type="link" href="/knife_intro" ghost={true}><img height="150"
                                                                              width="500"
                                                                              src={button_intro}
                                                                              alt={"login"}/></Button>
                    <Button type="link" style={{marginTop: '90px', paddingLeft: '90px'}} href="/KnifeUsage"
                            ghost={true}><img
                        height="150" width="500"
                        src={button_intro2}
                        alt={"login"}/></Button>
                    <Button type="link" style={{marginTop: '90px'}}  href="/KnifeExample"
                            ghost={true}><img height="150" width="500"
                                              src={button_case}
                                              alt={"login"}/></Button>
                </div>
            </div>
        );
    }
}

export default App;
