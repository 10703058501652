import React from 'react';
import zhang from './img/zhang.png';
import './KnifeExample.css';
import {Button} from "antd";
import {Row, Col} from 'antd';
class KnifeUsage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    changeContent = index => {
        this.setState({
            index: index
        })
    };

    render() {
        return (
            <div className="KnifeExample">
                <Button style={{marginLeft: "90%", marginTop: "50px"}} type="default" href="/main">返回</Button>
                <Row>
                    <Col span={12}>
                        <header className="KnifeExample-header">
                            <Button type="link" href="/KnifeExample1" ghost={true}><img height="350"
                                                                                                        width="800"
                                                                                                        src={zhang} alt={"login"}/></Button>
                        </header>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default KnifeUsage;
