import React from 'react';
import './KnifeScore.css';
import {Button, Checkbox, Menu, Radio} from "antd";
import btn_ok from "./img/btn_ok.png";
import after1_btn from "./img/after1_btn.png";
import after2_btn from "./img/after2_btn.png";
import {Link} from "react-router-dom";

class KnifeScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            mistake:0
        };
    }

    componentDidMount() {
        this.setState({
            value:localStorage.getItem("score"),
            mistake:localStorage.getItem("mistake")
        })
    }


    render() {
        return (
            <div className="KnifeScore">
                <div className="KnifeScore-section">
                    <div className="KnifeScore-text1">本次得分{this.state.value}分，超越了{this.state.value}%的同学</div>

                    <div className="KnifeScore-text2">本次实验共发现{this.state.mistake}个问题</div>

                    <Link className="KnifeScore-text3" to="/KnifeAnswer">点击进入专家模式</Link>

                    <div className="KnifeScore-text4">{this.state.value}分</div>
                </div>
            </div>
        );
    }
}

export default KnifeScore;
