import React from 'react';
import './KnifeAnswer.css';
import {Button} from "antd";
import exit from "./img/exit.png";
import liduokayin from "./img/liduokayin.jpg";
import {Player} from "video-react";
import answerpre from "./img/answer.png";
import answer from './video/answer.mp4';


class KnifeAfter extends React.Component {
    constructor(props) {
        super(props);
        this.data = new Map();
        this.state = {
            current: 0,
            value: 1
        };
    }

    componentDidMount() {

    }

    changeContent = index => {
        this.setState({
            index: index
        })
    };

    onAfterOk() {
        this.setState({
            current: 1,
        });
    }

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    render() {
        return (
            <div className="KnifeAnswer">
                <div className="KnifeAnswer-section1">
                        <div className="KnifeAnswer-text1">
                            姓名：张某   性别：男   年龄：61岁  婚姻：已婚<br/>
                            住址：武汉市武昌区   身高：170cm  体重：60kg<br/>
                            体温：36.5℃  脉搏：80次/分  呼吸：19次/分  血压：130/85mmHg<br/>
                            主诉：双膝间断疼痛7年， 左膝再发加重3月。<br/>
                            <br/>
                            一、临床表现支持点：<br/>
                            膝关节粗大，关节肿胀，局部皮肤未见明显发红，行走不便，膝关节伸屈受限，下蹲及上下楼困难，常伴有腿软的现象。膝关节伸直到一定程度时引起疼痛，膝关节的伸屈过程中往往发出捻发音，有时可出现关节积液，股四头肌萎缩疼痛。<br/>
                            <br/>
                            二、诊断支持点：<br/>
                            1、就诊前一个月大多数时间有膝关节疼痛<br/>
                            2、有骨摩擦音<br/>
                            3、年龄大于40岁<br/>
                            4、有骨性膨大<br/>
                            5、X线平片显示关节间隙变窄<br/>
                            <br/>
                            三、鉴别诊断：<br/>
                            1、类风湿性关节炎：多发于二十到五十岁，急性发作，全身症状比较轻，持续时间比较长，受累关节多对称或多发，不侵犯远端指尖关节，关节早期肿胀呈梭型，晚期功能障碍及强直畸形，x线检查局部或全身骨质疏松，关节面吸收骨性愈合，强直畸形，实验室检查血沉增快，类风湿因子阳性。<br/>
                            2、强直性脊柱炎：多发于十五到三十岁男性青壮年，发病缓慢、间歇疼痛、多关节受累、脊柱活动受限、关节畸形、晨僵、x线检查骶髂关节间隙狭窄模糊、脊柱韧带钙化成竹节状改变、实验室检查血沉增快和正常类风湿因子多阴性。<br/>
                            <br/>
                            四、西医诊断：膝关节骨性关节炎<br/>
                            <br/>
                            五、中医四诊：<br/>
                            病位在膝关节，四肢不温，冬日畏寒，关节遇冷加剧，得温痛减，关节重着，结合舌脉：舌质淡，苔白腻；脉弦沉。<br/>
                            反映了寒湿闭阻，阳虚不振的病机，宜温阳散寒，化湿通络<br/>
                            <br/>
                            六、中医诊断：膝痹   寒湿闭阻证<br/>
                            <br/>
                            七、针刀术前准备：<br/>
                            1、体位：D.仰卧位屈膝<br/>
                            2、针刀治疗点定位：采用五指定位法定点，取胫侧副韧带、髌内侧支持带、髌韧带、髌外侧支持带、腓侧副韧带及髂胫束、四头肌腱及髌上囊点、鹅足点<br/>
                            <img width="1000dp" src={liduokayin}/> <br/>
                            3、消毒：针刀治疗点周围区域碘伏消毒3遍<br/>
                            4、戴无菌手套：严格遵守无菌医用手套正确佩戴要求<br/>
                            5、铺无菌洞巾：使治疗点正对洞巾中间<br/>
                            6、麻醉：用1%利多卡因局部浸润麻醉，每个治疗点注射1mL<br/>
                            7、针刀刀具：选择 B.Ⅰ型4号直型针刀<br/>
                            <br/>
                            八、针刀治疗操作<br/>
                            1、第1支针刀松解胫侧副韧带的粘连和瘢痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按四步进针刀规程进针刀，针刀经皮肤、皮下组织，当刀下有韧性感时，即到达胫侧副韧带，先纵疏横剥3刀，然后调转刀口线90°，提插切割3刀。<br/>
                            <br/>
                            2、第2支针刀松解髌内侧支持带的粘连和瘢痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按四步进针刀规程进针刀，针刀经皮肤、皮下组织，当刀下有韧性感时，即到达髌内侧支持带，先纵疏横剥3刀，然后调转刀口线90°，“十”字提插切割3刀。<br/>
                            <br/>
                            3、第3支针刀松解髌韧带的粘连和瘢痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按四步进针刀规程进针刀，针刀经皮肤、皮下组织，当刀下有韧性感时，即到达髌韧带，进针刀1cm,纵疏横剥3刀<br/>
                            <br/>
                            4、第4支针刀松解髌外侧支持带的粘连和瘢痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按四步进针刀规程进针刀，针刀经皮肤、皮下组织，当刀下有韧性感时，即到达髌外侧支持带,先纵疏横剥3刀，然后调转刀口线90°，“十”字提插切割3刀。<br/>
                            <br/>
                            5、 第5支针刀松解腓侧副韧带及髂胫束的粘连和瘢痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按四步进针刀规程进针刀，针刀经皮肤、皮下组织，当刀下有韧性感时，即到达腓侧副韧带和髂胫束，纵疏横剥3刀。<br/>
                            <br/>
                            6、第6支针刀松解股四头肌腱及髌上囊的粘连和癜痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按四步进针刀规程进针刀，针刀经皮肤、皮下组织,当刀下有韧性感时，即到达股四头肌腱，先纵疏横剥3刀，再调转刀口线90°，“+”字提插切割3刀，然后继续进针刀，当刀下有落空感时即已穿过股四头肌腱，纵疏横剥3刀，范围0. 5cm。<br/>
                            <br/>
                            7、第7支针刀松解鹅足的粘连和瘢痕<br/>
                            •刀口线与下肢纵轴方向一致，针刀体与皮肤垂直，严格按照四步进针刀规程进针刀，针刀经皮肤、皮下组织，直达骨面，纵疏横剥3刀<br/>
                            <br/>
                            8、术毕，拔出针刀，局部压迫止血3分钟后，创可贴覆盖针眼。<br/>
                            <br/>
                            九、术后康复<br/>
                            A.患者仰卧，医生一手握住踝关节上方，另一手托住小腿上部，在牵拉状态下，摇晃、旋转伸屈膝关节，然后用在牵引状态下的推拿手法，将内外翻和轻度屈曲畸形纠正。<br/>
                            <br/>
                            十、急救处理<br/>
                            1—5： ABD、BCD、D、B、C    &nbsp;&nbsp;&nbsp;&nbsp;         &nbsp;&nbsp;&nbsp;&nbsp;          6—10：C、ABC、ABCD、ABCD、D<br/>
                            11—15：ABCD、ACD、CD、ABD、BD               16—20：ABD、AD、BC、ACD、ABC<br/>
                            21—25：CD、ABCD、B、ABCD、ABD               26—30：AC、D、BC、ABD、C<br/>
                            <Player
                                playsInline
                                poster={answerpre}
                                src={answer}
                            />
                        </div>
                        <Button type="link" href="/" style={{marginLeft: "1300px"}} ghost={true}><img
                            height="80" width="160" src={exit} alt={"login"}/></Button>
                </div>
            </div>
        );
    }
}

export default KnifeAfter;
